import React from 'react';
import moment from 'moment';
import { View } from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell
} from '../../../components/PdfTable';
import { formatDollar } from './util';

function DetailTableV1({ details }) {
  return (
    <View style={{ margin: 10 }} key={`service-table-${details.invoiceNo}`}>
      <Table data={details}>
        <TableHeader>
          <TableCell width={50}>Invoice #</TableCell>
          <TableCell width={50}>Physician</TableCell>
          <TableCell width={65}>Facility Where Services Rendered</TableCell>
          <TableCell>FAC Acct #</TableCell>
          <TableCell>Patient</TableCell>
          <TableCell width={30}>DOS</TableCell>
          <TableCell width={75}>Service Description</TableCell>
          <TableCell width={35}>CPT</TableCell>
          <TableCell width={45}>Chrg</TableCell>
          <TableCell width={45}>Pmt</TableCell>
          <TableCell width={45}>Adj</TableCell>
          <TableCell width={55}>Balance</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell width={50} getContent={r => r.invoiceNo} />
          <DataTableCell width={50} getContent={r => r.physician} />
          <DataTableCell width={65} getContent={r => r.facility} />
          <DataTableCell getContent={r => r.facilityAccountNo} />
          <DataTableCell getContent={r => r.patient} />
          <DataTableCell
            width={30}
            justifyEnd
            getContent={r => moment(r.dos).format('MM/DD/YY')}
          />
          <DataTableCell width={75} getContent={r => r.serviceDescription} />
          <DataTableCell justifyEnd width={35} getContent={r => r.cpt} />
          <DataTableCell
            justifyEnd
            width={45}
            getContent={r => formatDollar(r.charge)}
          />
          <DataTableCell
            justifyEnd
            width={45}
            getContent={r => formatDollar(r.payment)}
          />
          <DataTableCell
            justifyEnd
            width={45}
            getContent={r => formatDollar(r.adjustment)}
          />
          <DataTableCell
            justifyEnd
            width={55}
            getContent={r => formatDollar(r.patientResponsibility)}
          />
        </TableBody>
      </Table>
    </View>
  );
}

export default DetailTableV1;
