import { FormControl, Select, OutlinedInput } from '@material-ui/core';
import React from 'react';

export const FormSelector = ({ value, children, style, ...props }) => {
  return (
    <FormControl
      variant="outlined"
      style={{
        ...style,
        maxWidth: '400px',
        backgroundColor: 'white'
      }}
    >
      <Select
        id="form-select"
        data-cy="form-select"
        labelId="form-select"
        label="Select"
        value={value}
        input={<OutlinedInput />}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
};
