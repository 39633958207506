import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'components/Tooltip';
import { makeStyles } from '@material-ui/core';
import { theme } from 'lib/theme';
const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: 600,
    backgroundColor: theme.palette.primary.main
  }
}));
const HelpLink = styled.div`
  color: ${theme.palette.secondary.main};
  cursor: default;
  font-weight: 400;
  &:hover {
    font-weight: 800;
  }
`;

export const FindHelp = props => {
  const classes = useStyles();
  return (
    <Tooltip
      title="Please reach out to your Client Success Team for further instructions."
      aria-label="find help"
      placement="bottom"
      classes={{ tooltip: classes.tooltip }}
    >
      <HelpLink {...props}>Need Help?</HelpLink>
    </Tooltip>
  );
};
