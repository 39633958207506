import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types/network';
import { DocumentHeader } from './useGetDocumentHeaders';

export interface DocumentDetail {
  segmentKey: string;
  segmentValue: string;
}

export const useGetDocumentDetails = (
  documentHeaderId?: DocumentHeader['documentHeaderId']
) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<DocumentDetail[], ErrorResponse>(
    [ROUTES.GetDocumentDetails, documentHeaderId],
    async () => {
      const { data } = await Axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL}?route=${ROUTES.GetDocumentDetails}`,
        data: {
          documentHeaderId
        }
      });

      return data;
    },
    {
      enabled: !!documentHeaderId
    }
  );
};
