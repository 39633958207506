import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import MyAccount from './views/MyAccount';
import MyEncounters from './views/MyEncounters';
import MyTransactionsSummary from './views/MyTransactionsSummary';
import Login from './views/Login';
import SSOLogin from './views/SSOLogin';
import { PrivateOutlet } from './components';
import Appbar from './views/Appbar';
import ForgotPassword from './views/Recover/ForgotPassword';
import ResetPassword from './views/Recover/ResetPassword';
import MyTransactionsSummaryContextProvider from 'views/MyTransactionsSummary/MyTransactionsSummaryContext';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Appbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/SSOLogin" element={<SSOLogin />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route
          path="/NoAccess"
          element={
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <b>
                Sorry, you have not yet been granted access. Please contact your
                user administrator to have your account set-up.
              </b>
            </div>
          }
        />
        <Route path="*" element={<b>404 Not Found</b>} />
        <Route element={<PrivateOutlet />}>
          <Route path="/MyAccount" element={<MyAccount />} />
          <Route path="/MyEncounters" element={<MyEncounters />} />
          <Route
            path="/MyTransactionsSummary"
            element={
              <MyTransactionsSummaryContextProvider>
                <MyTransactionsSummary />
              </MyTransactionsSummaryContextProvider>
            }
          />
        </Route>
      </Routes>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
