import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormTextField, SubmitButton } from 'components';
import { Tooltip } from 'components/Tooltip';
import { EmailField } from 'components/EmailField';
import { PhoneTextField } from 'components/PhoneTextField';
import { ZipCodeField } from 'components/ZipCodeField';
import { StateSelect } from 'components/StateSelect';
import { SexField } from 'components/SexField';
import { usePartnerUpdateRequestInsuranceInsert } from 'hooks/requests';
import { DOBField } from 'components/DOBField';
import { format } from 'date-fns';
import { PatientInfo, PatientInsuranceInfo } from 'types/patientInsurance';
import { Color } from '@material-ui/lab/Alert';
import { useAuthentication } from '../../../hooks/useAuthentication';

const ReadOnlyTooltip = ({ ...props }) => {
  return <Tooltip title="Read-Only" aria-label="find help" {...props} />;
};

export interface PatientFormProps {
  data?: PatientInsuranceInfo;
  initialValues?: Partial<PatientInfo>;
  setSnackbarState: (state: {
    message?: string;
    open?: boolean;
    severity?: Color;
  }) => void;
}

export const PatientForm: React.FC<PatientFormProps> = ({
  initialValues = {},
  data = {},
  setSnackbarState
}) => {
  const { mutateAsync: updateMutation } =
    usePartnerUpdateRequestInsuranceInsert();
  const { isLoggedInSSO } = useAuthentication();

  const handleSubmit = async (
    values: PatientInfo,
    actions: FormikHelpers<PatientInfo>
  ) => {
    try {
      await updateMutation({
        ...data,
        patientInformation: {
          ...values,
          patientHomePhone: values?.patientHomePhone.replaceAll('-', '') || '',
          patientDOB: values?.patientDOB ? new Date(values.patientDOB) : '',
          patientCellPhone: values?.patientCellPhone.replaceAll('-', '') || ''
        }
      } as unknown as PatientInsuranceInfo);
      setSnackbarState({
        message: 'Update request saved successfully',
        severity: 'success',
        open: true
      });
    } catch (err) {
      console.dir(err);
      setSnackbarState({
        message: (err as { Error: string }).Error || 'An Error has occured.',
        severity: 'error',
        open: true
      });
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        {
          guarantorFirstName: '',
          guarantorLastName: '',
          patientAddress: '',
          patientCity: '',
          patientState: '',
          patientZip: '',
          patientHomePhone: '',
          patientEmail: '',
          patientSex: '',
          patientMarriedStatus: '',
          patientEmployedStatus: '',
          patientInjuryTypeDesc: '',
          patientInjuryState: '',
          ...initialValues,
          patientDOB: initialValues.patientDOB
            ? format(new Date(initialValues.patientDOB), 'MM/dd/yyyy')
            : '',
          patientCellPhone: initialValues?.patientHomePhone ?? '' // until property is added to DB
        } as PatientInfo
      }
      onSubmit={handleSubmit}
      render={({ isSubmitting, isValidating }) => {
        const isFormSubmitting = isSubmitting || isValidating;

        return (
          <Form
            style={{
              padding: '0 24px 20px 24px'
            }}
          >
            <ReadOnlyTooltip>
              <FormTextField
                name="guarantorFirstName"
                isRequired={false}
                label="Guarantor's First Name"
                disabled
                style={{
                  margin: '0 12px 16px 0'
                }}
              />
            </ReadOnlyTooltip>
            <ReadOnlyTooltip>
              <FormTextField
                name="guarantorLastName"
                isRequired={false}
                style={{
                  margin: '0 12px 16px 0'
                }}
                label="Guarantor's Last Name"
                disabled
              />
            </ReadOnlyTooltip>
            <ReadOnlyTooltip>
              <FormTextField
                name="patientFirstName"
                isRequired={false}
                style={{
                  margin: '0 12px 16px 0'
                }}
                label="Patient's First Name"
                disabled
              />
            </ReadOnlyTooltip>
            <ReadOnlyTooltip>
              <FormTextField
                name="patientLastName"
                isRequired={false}
                style={{
                  margin: '0 12px 16px 0'
                }}
                label="Patient's Last Name"
                disabled
              />
            </ReadOnlyTooltip>

            <FormTextField
              name="patientAddress"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient Address"
            />
            <FormTextField
              name="patientCity"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient City"
            />
            <StateSelect
              name="patientState"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient State"
            />
            <ZipCodeField
              name="patientZip"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Zip"
            />
            <PhoneTextField
              name="patientHomePhone"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Home Phone"
            />
            <PhoneTextField
              name="patientCellPhone"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Cell Phone"
            />
            <DOBField
              name="patientDOB"
              isRequired
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient DOB"
            />
            <EmailField
              name="patientEmail"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient Email"
            />
            <SexField
              name="patientSex"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Patient Sex"
            />
            <FormTextField
              name="patientMarriedStatus"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Married Status"
            />
            <FormTextField
              name="patientEmployedStatus"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Employed Status"
            />
            <FormTextField
              name="patientInjuryTypeDesc"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Injury Type"
            />
            <StateSelect
              name="patientInjuryState"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Injury State"
            />
            {isLoggedInSSO() ? null : (
              <div>
                <SubmitButton
                  disabled={isFormSubmitting}
                  loading={isFormSubmitting}
                >
                  Submit Update Request
                </SubmitButton>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};
