/* eslint-disable @typescript-eslint/ban-types */
// This is the type restriction imposed by react-table

import { saveAs } from 'file-saver';
import { Column } from 'react-table';
import * as XLSX from 'xlsx';

interface ExportableColumn<TData extends object = {}> {
  Header: string;
  accessor: keyof TData | ((original: TData) => string);
}

export const exportToExcelSheet = <TData extends object = {}>({
  fileName,
  sheetName,
  columns,
  data
}: {
  fileName: string;
  sheetName: string;
  columns: Column<TData>[];
  data: TData[];
}) => {
  const exportableColumns = getExportableColumns(columns);
  const workSheet = createWorksheet(exportableColumns, data);
  const blob = createExcelFileBlob(sheetName, workSheet);
  saveAs(blob, fileName);
};

const getExportableColumns = <TData extends object = {}>(
  columns: Column<TData>[]
) =>
  columns.filter(
    x => typeof x.Header === 'string' && !!x.accessor
  ) as ExportableColumn<TData>[];

const createWorksheet = <TData extends object = {}>(
  exportableColumns: ExportableColumn<TData>[],
  data: TData[]
) => {
  const headerRow = exportableColumns.map(x => x.Header);
  const dataRows = data.map(x =>
    exportableColumns.map(({ accessor }) =>
      typeof accessor === 'function' ? accessor(x) : x[accessor]
    )
  );
  return XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
};

const createExcelFileBlob = (sheetName: string, workSheet: XLSX.WorkSheet) => {
  const excelBuffer = XLSX.write(
    {
      Sheets: { [sheetName]: workSheet },
      SheetNames: [sheetName]
    },
    { bookType: 'xlsx', type: 'array' }
  );
  return new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
};
