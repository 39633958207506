import { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuthentication } from '../../hooks/useAuthentication';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SSOLogin() {
  const query = useQuery();
  const navigate = useNavigate();
  const { setToken, setUserInfo } = useAuthentication();
  const ssoToken = query.get('token');
  const header = { headers: { Authorization: `Bearer ${ssoToken}` } };
  useEffect(() => {
    const getSSOExternalUser = async () => {
      try {
        const userInfo = await axios.get(
          `${process.env.REACT_APP_URL}/?route=SSOLogin`,
          header
        );
        const user = !!userInfo && !!userInfo.data ? userInfo.data : '';
        setToken(userInfo.data.token);
        setUserInfo(JSON.stringify(user));
        navigate('/MyEncounters');
      } catch (err) {
        navigate('/NoAccess');
      }
    };
    getSSOExternalUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
}

export default SSOLogin;
