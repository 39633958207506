import React, { useEffect, useState } from 'react';
import { FormSelector } from './FormSelector';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { InsuranceForm } from '../InsuranceForm/InsuranceForm';
import { PatientForm } from '../PatientForm/PatientForm';
import { usePartnerGetPatientInsurance } from 'hooks/requests';
import { MenuItem, Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import { PatientInsuranceInfo } from 'types/patientInsurance';

export interface PatientInsuranceDialogProps {
  invoiceNo?: number;
  srcSystemKey?: number;
  open?: boolean;
  close: () => void;
}

export const PatientInsuranceDialog: React.FC<PatientInsuranceDialogProps> = ({
  invoiceNo,
  srcSystemKey,
  open = true,
  close = () => {}
}) => {
  const {
    mutate: getPatientInformationMutation,
    data = {} as unknown as PatientInsuranceInfo
  } = usePartnerGetPatientInsurance();

  const [form, setForm] = useState(FORM_SELECTION.PATIENT);
  const [snackbarState, setSnackbarState] = useState<{
    message?: string;
    open?: boolean;
    severity?: Color;
  }>({});

  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    reason !== 'clickaway' && setSnackbarState({});
  };

  const handleError = () =>
    setSnackbarState({
      open: true,
      severity: 'error',
      message: 'An Error has occurred to load up the data.'
    });

  useEffect(() => {
    if (!(invoiceNo && srcSystemKey)) return;
    getPatientInformationMutation(
      {
        account: invoiceNo,
        srcSystemKey
      },
      { onError: handleError }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceNo, srcSystemKey]);

  useEffect(() => {
    if (open) return;
    setForm(FORM_SELECTION.PATIENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {snackbarState.message ? (
        <Snackbar
          open={snackbarState.open}
          autoHideDuration={8000}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity={snackbarState.severity || 'error'}
          >
            {snackbarState.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledDialog
        open={open}
        PaperComponent={PaperComponent}
        maxWidth="lg"
        aria-labelledby="details"
      >
        <StyledDialogTitle
          style={{
            zIndex: 20000
          }}
          id="details"
        >
          <TitleControl>
            <Typography variant="h6">{`Information Update Request for Invoice: ${invoiceNo}`}</Typography>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => close()} />
          </TitleControl>
        </StyledDialogTitle>
        <p
          style={{
            margin: '0 24px 12px 24px',
            lineHeight: 1.5
          }}
        >
          Please submit your requested changes in the sections below. NOTE: Data
          displayed could be up to 5 business days old.
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h3
            style={{
              margin: '0 0 0 24px'
            }}
          >
            Select Update Request Type:
          </h3>
          <FormSelector
            value={form}
            onChange={(e: { target: { value: string } }) =>
              setForm(e.target.value)
            }
            style={{
              margin: '6px 24px 12px 24px',
              maxWidth: '300px',
              width: '100%'
            }}
          >
            {Object.keys(FORM_SELECTION).map(formKey => {
              const formValue =
                FORM_SELECTION[formKey as keyof typeof FORM_SELECTION];
              return (
                <MenuItem
                  key={formKey}
                  value={formValue}
                  style={{ height: '36px' }}
                  data-cy={`${formKey}-form-option`}
                >
                  {formValue}
                </MenuItem>
              );
            })}
          </FormSelector>
        </div>
        <Divider
          style={{
            margin: '0px 24px 16px 24px'
          }}
        />
        <div
          style={{
            maxWidth: '1000px'
          }}
        >
          {FORM_SELECTION.PATIENT === form ? (
            <PatientForm
              initialValues={data.patientInformation || {}}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          ) : null}
          {FORM_SELECTION.PRIMARY === form ? (
            <InsuranceForm
              ordinal="primary"
              initialValues={data.primaryInsurance || {}}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          ) : null}
          {FORM_SELECTION.SECONDARY === form ? (
            <InsuranceForm
              ordinal="secondary"
              initialValues={data.secondaryInsurance || {}}
              data={data}
              setSnackbarState={setSnackbarState}
            />
          ) : null}
        </div>
      </StyledDialog>
    </>
  );
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#details" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const StyledDialogTitle = styled(DialogTitle)`
  cursor: move;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
`;
const TitleControl = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
  }
`;

const FORM_SELECTION = {
  PATIENT: 'PATIENT',
  PRIMARY: 'PRIMARY INSURANCE',
  SECONDARY: 'SECONDARY INSURANCE'
};
