import React from 'react';
import { useField } from 'formik';
import { composeValidators, requiredValidator } from 'sd-formik-validators';
import { StyledTextField } from './StyledTextField';
const validateEmail = value => {
  const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (value.trim() === '' || validEmailRegex.test(value)) {
    return undefined;
  } else {
    return 'Invalid Email';
  }
};
export const EmailField = ({
  isRequired = false,
  name,
  label,
  validate = [],
  helperText = '',
  error = '',
  ...props
}) => {
  const [field, meta] = useField({
    name,
    validate: isRequired
      ? composeValidators(requiredValidator, validateEmail, ...validate)
      : composeValidators(validateEmail, ...validate)
  });

  return (
    <StyledTextField
      label={isRequired && label ? `*${label}` : label}
      error={!!(meta.touched && meta.error) || error}
      helperText={!!(meta.touched && meta.error) ? meta.error : helperText}
      style={{
        ...props.style,
        marginBottom: !!(meta.touched && meta.error)
          ? '4px'
          : (!!props && !!props.style && props.style.marginBottom) || ''
      }}
      {...field}
      value={(field && field.value) || ''}
      {...props}
    />
  );
};
