import React, { useState, useEffect } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';
import { usePartnerGetMyEncounterCommentTypes } from './lib/usePartnerGetMyEncounterCommentTypes';

export interface CommentsPresetProps {
  comment: { encounterHeaderCommentTypeID: string };
  handlePresetChange: (value: unknown) => void;
  handleChangeComment: (value: unknown) => void;
  isSubmitting: boolean;
}

function CommentsPreset({
  comment,
  handlePresetChange,
  handleChangeComment,
  isSubmitting
}: CommentsPresetProps) {
  const { data: commentOptions = [] } = usePartnerGetMyEncounterCommentTypes();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setIsDirty(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    handleChangeComment(comment ? comment : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  return (
    <>
      <InputLabel
        id="comments-type"
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Comment Type*
      </InputLabel>
      <FormControl
        variant="outlined"
        style={{ width: '100%', marginTop: '10px', backgroundColor: 'white' }}
      >
        <Select
          onBlur={() => {
            setIsDirty(true);
          }}
          error={isDirty && !comment?.encounterHeaderCommentTypeID}
          id="comments-preset-select"
          data-cy="comments-preset"
          labelId="comments-preset"
          value={comment}
          onChange={e => handlePresetChange(e.target.value)}
          input={<OutlinedInput />}
        >
          {commentOptions.map(el => (
            <MenuItem
              key={el.encounterHeaderCommentTypeID}
              value={el as unknown as string}
              data-cy="comments-preset-options"
            >
              {el.encounterHeaderCommentType}
            </MenuItem>
          ))}
        </Select>
        {isDirty && !comment?.encounterHeaderCommentTypeID ? (
          <FormHelperText
            style={{
              color: 'red',
              paddingLeft: '14px',
              paddingRight: '14px',
              background: '#f5f5f5',
              margin: 0
            }}
          >
            Required
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}

export default CommentsPreset;
