import { useQuery } from 'react-query';
import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse } from 'types/network';

export const usePartnerGetRefreshToken = () => {
  const Axios = useAuthenticatedAxios();
  return useQuery<string, ErrorResponse>(
    ROUTES.PartnerGetRefreshToken,
    async () => {
      const { data } = await Axios({
        method: 'get',
        url: `?route=${ROUTES.PartnerGetRefreshToken}`
      });
      return data;
    },
    {
      enabled: false
    }
  );
};
