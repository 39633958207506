import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types/network';
import { DocumentHeader } from './useGetDocumentHeaders';

export interface DocumentEdm {
  byteArray: string;
  mimeType: string;
}

export const useGetDocumentEdmById = (
  documentEdmId?: DocumentHeader['documentHeaderId']
) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<DocumentEdm, ErrorResponse>(
    [ROUTES.GetDocumentEdmById, documentEdmId],
    async () => {
      const { data } = await Axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL}?route=${ROUTES.GetDocumentEdmById}`,
        data: {
          documentEdmId
        }
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!documentEdmId
    }
  );
};
