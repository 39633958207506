import React from 'react';
import { Page, Image, View, Text } from '@react-pdf/renderer';
import { formatDollar } from './util';
import { styles, boldFont } from './styled';
import visa from 'assets/visa.png';
import mastercard from 'assets/mastercard.png';
import amex from 'assets/amex.png';
import discover from 'assets/discover.png';
import { theme } from 'lib/theme';

/* stylelint-disable font-family-no-missing-generic-family-keyword */

function MailingInfo({ data }) {
  return (
    <Page size="Letter" style={{ marginTop: 10, marginBottom: 10 }}>
      <View style={[styles.view, { justifyContent: 'space-between' }]}>
        <View style={styles.section}>
          <View
            style={[
              styles.view,
              {
                flex: 1.5,
                textAlign: 'left',
                borderStyle: 'solid',
                borderRightWidth: '1',
                borderColor: 'black'
              }
            ]}
          >
            <Text
              style={[
                styles.text,
                {
                  backgroundColor: theme.palette.primary.main,
                  padding: 5,
                  marginBottom: '15px',
                  fontFamily: boldFont,
                  color: 'white'
                }
              ]}
            >
              Payment Options
            </Text>
            <Text style={[styles.text, { fontFamily: boldFont }]}>Mail:</Text>
            <Text
              style={[
                styles.text,
                { fontFamily: boldFont, marginBottom: '5px' }
              ]}
            >
              Make Checks Payable To:
            </Text>
            <Text style={styles.text}>{data.billingProvider}</Text>
            <Text style={styles.text}>{data.billingAddress1}</Text>
            <Text
              style={styles.text}
            >{`${data.billingCity}, ${data.billingState}`}</Text>
            <Text style={[styles.text, { marginBottom: '10px' }]}>
              {data.billingZipCode}
            </Text>
            <Text
              style={[
                styles.text,
                { fontFamily: boldFont, marginBottom: '5px' }
              ]}
            >
              Online:
            </Text>
            <Text style={[styles.text, { marginBottom: '10px' }]}>
              {data.paymentUri}
            </Text>
            <Text
              style={[
                styles.text,
                { fontFamily: boldFont, marginBottom: '5px' }
              ]}
            >
              Customer Service:
            </Text>
            <Text style={styles.text}>{data.paymentPhone}</Text>
          </View>
          <View style={[styles.view, { flex: 4 }]}>
            <View
              style={[
                styles.view,
                {
                  flex: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: 'black',
                  width: '90%',
                  alignSelf: 'center',
                  paddingTop: 10,
                  paddingRight: 24,
                  paddingBottom: 5
                }
              ]}
            >
              <View
                style={[
                  styles.view,
                  {
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    {
                      marginLeft: '10px',
                      marginRight: '10px'
                    }
                  ]}
                >
                  PAY BY CREDIT CARD:
                </Text>
                <View
                  style={[
                    styles.view,
                    {
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1
                    }
                  ]}
                >
                  <Image
                    src={visa}
                    style={{
                      width: 35,
                      height: 20,
                      marginRight: '10px',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'black'
                    }}
                  />
                  <Image
                    src={mastercard}
                    style={{
                      width: 35,
                      height: 20,
                      marginRight: '10px',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'black'
                    }}
                  />
                  <Image
                    src={amex}
                    style={{
                      width: 35,
                      height: 20,
                      marginRight: '10px',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'black'
                    }}
                  />
                  <Image
                    src={discover}
                    style={{
                      width: 35,
                      height: 20,
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'black'
                    }}
                  />
                </View>
              </View>
              <Text
                style={[
                  styles.text,
                  { marginLeft: '10px', marginBottom: '10px' }
                ]}
              >
                Credit Card Number ________________________________ CVV
                _________
              </Text>
              <Text
                style={[
                  styles.text,
                  { marginLeft: '10px', marginBottom: '10px' }
                ]}
              >
                Cardholder Name _______________________________________________
              </Text>
              <Text
                style={[
                  styles.text,
                  { marginLeft: '10px', marginBottom: '10px' }
                ]}
              >
                {' '}
                Exp. Date ___________ Signature
                __________________________________
              </Text>
            </View>
            <View
              style={[
                styles.view,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: 'black',
                  width: '90%',
                  alignSelf: 'center',
                  paddingTop: 10,
                  paddingBottom: 10
                }
              ]}
            >
              <View style={[styles.view, { flex: 1 }]}>
                <Text
                  style={[
                    styles.text,
                    {
                      marginLeft: '10px',
                      fontFamily: boldFont,
                      fontSize: '11px',
                      marginBottom: '10px'
                    }
                  ]}
                >
                  Due Date
                </Text>
                <Text
                  style={[
                    styles.text,
                    {
                      marginLeft: '10px',
                      fontFamily: boldFont,
                      fontSize: '11px'
                    }
                  ]}
                >
                  Upon Receipt
                </Text>
              </View>
              <View style={[styles.view, { flex: 1 }]}>
                <Text
                  style={[
                    styles.text,
                    {
                      fontFamily: boldFont,
                      fontSize: '11px',
                      marginBottom: '10px'
                    }
                  ]}
                >
                  Account Number
                </Text>
                <Text
                  style={[
                    styles.text,
                    { fontFamily: boldFont, fontSize: '11px' }
                  ]}
                >
                  {data.accountNumber}
                </Text>
              </View>
              <View style={[styles.view, { flex: 1 }]}>
                <Text
                  style={[
                    styles.text,
                    {
                      fontFamily: boldFont,
                      fontSize: '11px',
                      marginBottom: '10px'
                    }
                  ]}
                >
                  Amount Due
                </Text>
                <Text
                  style={[
                    styles.text,
                    { fontFamily: boldFont, fontSize: '11px' }
                  ]}
                >
                  {formatDollar(data.amountDue)}
                </Text>
              </View>
              <View style={[styles.view, { flex: 1 }]}>
                <Text
                  style={[
                    styles.text,
                    {
                      fontFamily: boldFont,
                      fontSize: '11px',
                      marginBottom: '10px'
                    }
                  ]}
                >
                  Amount Paid
                </Text>
                <Text style={[styles.text, { fontSize: '11px' }]}>
                  ___________
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View
            style={[
              styles.view,
              {
                paddingLeft: '30px',
                paddingBottom: '50px',
                flex: 1,
                textAlign: 'left'
              }
            ]}
          >
            <Text style={styles.text}>{data.billingProvider}</Text>
            <Text style={styles.text}>{data.billingAddress1}</Text>
            <Text style={styles.text}>{data.billingAddress2}</Text>
            <Text
              style={styles.text}
            >{`${data.billingCity}, ${data.billingState} ${data.billingZipCode}`}</Text>
          </View>
          <View
            style={[
              styles.view,
              {
                paddingLeft: '30px',
                paddingBottom: '50px',
                flex: 1,
                textAlign: 'left'
              }
            ]}
          >
            <Text style={styles.text}>{data.guarantorName}</Text>
            <Text style={styles.text}>{data.guarantorAddress1}</Text>
            <Text style={styles.text}>{data.guarantorAddress2}</Text>
            <Text
              style={styles.text}
            >{`${data.guarantorCity}, ${data.guarantorState} ${data.guarantorZipCode}`}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default MailingInfo;
