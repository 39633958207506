import React, { useState } from 'react';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { format, parseISO } from 'date-fns';

import { DraggableDialog } from 'components/DraggableDialog/DraggableDialog';
import { DocumentEdmPdfViewer } from './DocumentEdmPdfViewer';
import { RawDocumentDetailsTable } from './RawDocumentDetailsTable';
import { CircularProgress } from '@material-ui/core';
import {
  DocumentHeader,
  useGetDocumentHeaders
} from './lib/useGetDocumentHeaders';
import { BaseAlert } from 'components/BaseAlert/BaseAlert';

const StyledDialogContent = styled(DialogContent)`
  padding-bottom: 24px;
`;

const DocumentGrid = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 400px auto;
  gap: 24px;
`;

export interface InvoiceDocumentsDialogProps {
  open: boolean;
  onClose: () => void;
  invoiceNo: number;
}

export const InvoiceDocumentsDialog: React.FC<InvoiceDocumentsDialogProps> = ({
  open,
  onClose,
  invoiceNo
}) => {
  const [selectedDocument, setSelectedDocument] = useState<
    DocumentHeader | undefined
  >();

  const {
    data: documentsList,
    isFetching,
    error
  } = useGetDocumentHeaders(invoiceNo, {
    onSuccess(data) {
      setSelectedDocument(data[0]);
    }
  });

  return (
    <DraggableDialog
      alignTop
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      id="encounter-exceptions-dialog"
      title={`X12 Documents for Invoice: ${invoiceNo}`}
      actions={
        <>
          <PrintIcon onClick={() => window.print()} />
          <CloseIcon onClick={() => onClose()} />
        </>
      }
    >
      <StyledDialogContent>
        {isFetching ? (
          <CircularProgress />
        ) : error ? (
          <BaseAlert>{error.response?.data.Error}</BaseAlert>
        ) : (
          <DocumentGrid>
            {documentsList?.length ? (
              <>
                <List disablePadding>
                  {documentsList.map(doc => (
                    <ListItem
                      button
                      selected={
                        doc.documentHeaderId ===
                        selectedDocument?.documentHeaderId
                      }
                      key={doc.documentHeaderId}
                      onClick={() => setSelectedDocument(doc)}
                    >
                      <ListItemText
                        primary={doc.documentAliasName}
                        secondary={`Received on ${format(
                          parseISO(doc.createdDateTime),
                          'MM/dd/yyyy'
                        )}`}
                      />
                    </ListItem>
                  ))}
                </List>
                {selectedDocument?.showDataInRawFormat ? (
                  <RawDocumentDetailsTable documentHeader={selectedDocument} />
                ) : (
                  <DocumentEdmPdfViewer documentHeader={selectedDocument} />
                )}
              </>
            ) : (
              'No documents found'
            )}
          </DocumentGrid>
        )}
      </StyledDialogContent>
    </DraggableDialog>
  );
};
