import { EncounterException } from 'components/ExceptionsDialog/lib/useExceptions';
import { Provider } from 'components/ExceptionsDialog/lib/useProviders';
import { UpdateExceptionEncounterHeadersBody } from 'components/ExceptionsDialog/lib/useUpdateExceptionEncounterHeaders';
import { ExceptionsFormContext } from '../types';

export const splitProviderCompositeId = (id?: string): [number, boolean] => {
  const providerId = +(id?.split('-')[0] || 0);
  const isProviderAssistant = id?.split('-')[1] === 'true';
  return [providerId, isProviderAssistant];
};

export function getProviderCompositeId(value: Provider): string;
export function getProviderCompositeId(value: EncounterException): string;
export function getProviderCompositeId(
  value: EncounterException | Provider
): string {
  return 'responsibleProviderId' in value
    ? `${value.responsibleProviderId}-${value.isProviderAssistant}`
    : `${value.id}-${value.isProviderAssistant}`;
}

export const withProviderCompositeIds = (
  exceptions: EncounterException[],
  providers: Provider[]
): ExceptionsFormContext['myEncounterHeaderExceptions'] =>
  exceptions.map(x => ({
    ...x,
    isProviderAssistant: undefined,
    responsibleProviderId: undefined,
    responsibleProviderCompositeId:
      providers
        .map(getProviderCompositeId)
        .find(y => y === getProviderCompositeId(x)) ??
      defaultProviderCompositeId
  }));

export const scrubCompositeProviderIds = (
  values: ExceptionsFormContext
): UpdateExceptionEncounterHeadersBody => ({
  comment: values.comment,
  myEncounterHeaderExceptions: values.myEncounterHeaderExceptions.map(x => {
    const [responsibleProviderId, isProviderAssistant] =
      splitProviderCompositeId(x.responsibleProviderCompositeId);
    return {
      ...x,
      responsibleProviderCompositeId: undefined,
      responsibleProviderId,
      isProviderAssistant
    };
  })
});

export const defaultProviderId = 0;
export const defaultIsProviderAssistant = false;
export const defaultProviderCompositeId = `${defaultProviderId}-${defaultIsProviderAssistant}`;
