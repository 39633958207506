import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { DocumentHeader } from './lib/useGetDocumentHeaders';
import { useGetDocumentEdmById } from './lib/useGetDocumentEdmById';
import { BaseAlert } from 'components/BaseAlert/BaseAlert';

const StyledIframe = styled.iframe`
  width: 100%;
  min-height: 80vh;
`;

export interface DocumentEdmPdfViewerProps {
  documentHeader?: DocumentHeader;
}

export const DocumentEdmPdfViewer: React.FC<DocumentEdmPdfViewerProps> = ({
  documentHeader
}) => {
  const {
    data: document,
    isFetching,
    error
  } = useGetDocumentEdmById(documentHeader?.documentHeaderId);
  const [link, setLink] = useState('');

  useEffect(() => {
    if (document?.byteArray) {
      const byteCharacters = atob(document.byteArray);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: document.mimeType
      });
      setLink(URL.createObjectURL(blob));
    }
  }, [document]);

  return isFetching ? (
    <CircularProgress />
  ) : error ? (
    <BaseAlert>{error.response?.data.Error}</BaseAlert>
  ) : (
    <StyledIframe src={link} title="PDF" />
  );
};
