import styled from 'styled-components';

export const PoweredBy = styled.p`
  font-size: 9px;
  margin: 0;
`;
export const GottliebGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const GottliebLogo = styled.img`
  margin: 0;
  max-width: 80px;
  max-height: 21.6px;
`;
