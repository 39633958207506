import { theme } from 'lib/theme';
import { styled } from '@material-ui/core';
import { BaseButton, BaseButtonProps } from './BaseButton';

export type WarningButtonProps = BaseButtonProps;

export const WarningButton = styled(BaseButton)({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.warning.dark
  }
});
