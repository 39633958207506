import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { BaseTable } from 'components/BaseTable/BaseTable';
import { DocumentHeader } from './lib/useGetDocumentHeaders';
import { useGetDocumentDetails } from './lib/useGetDocumentDetails';
import { BaseAlert } from 'components/BaseAlert/BaseAlert';

export interface RawDocumentDetailsTableProps {
  documentHeader?: DocumentHeader;
}

export const RawDocumentDetailsTable: React.FC<RawDocumentDetailsTableProps> =
  ({ documentHeader }) => {
    const {
      data: documentDetails,
      isFetching,
      error
    } = useGetDocumentDetails(documentHeader?.documentHeaderId);

    return isFetching ? (
      <CircularProgress />
    ) : error ? (
      <BaseAlert>{error.response?.data.Error}</BaseAlert>
    ) : documentDetails?.length ? (
      <BaseTable className="alt-bg">
        <thead>
          <tr>
            <th scope="col">Segment Name</th>
            <th scope="col">Segment Data</th>
          </tr>
        </thead>
        <tbody>
          {documentDetails.map((doc, idx) => (
            <tr key={`doc-detail-data-${idx}`}>
              <td>{doc.segmentKey}</td>
              <td>{doc.segmentValue}</td>
            </tr>
          ))}
        </tbody>
      </BaseTable>
    ) : (
      <>No document found</>
    );
  };
