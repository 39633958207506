import React, { useState } from 'react';
import { usePartnerUpSertMfaPhoneNumber } from 'hooks/requests';
import { Form, Formik } from 'formik';
import { PhoneTextField } from 'components/PhoneTextField';
import { SubmitButton } from 'components/Buttons';
import SecureTrustCertificate from 'components/SecureTrustCertificate';
import { useAuthentication } from 'hooks/useAuthentication';

export interface MFAPhoneProps {
  gotoMFA: () => void;
}

export const MFAPhone: React.FC<MFAPhoneProps> = ({ gotoMFA }) => {
  const { mutateAsync: upsertPhoneMutation } = usePartnerUpSertMfaPhoneNumber();
  const [errorMsg, setErrorMsg] = useState('');
  const { setToken } = useAuthentication();
  return (
    <Formik
      initialValues={{
        phoneNumber: ''
      }}
      onSubmit={async (values, actions) => {
        setErrorMsg('');
        const cleanPhoneNumber = values.phoneNumber.replace(/-/g, '');
        try {
          const data = await upsertPhoneMutation({
            phoneNumber: cleanPhoneNumber
          });
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              ...JSON.parse(sessionStorage.getItem('user') || '{}'),
              phoneNumber: cleanPhoneNumber
            })
          );
          setToken(data);
          gotoMFA();
        } catch (err) {
          setErrorMsg(
            `Failed to update phone number. ${
              (err as { message: string }).message
            }`
          );
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValidating }) => {
        return (
          <Form
            style={{
              display: 'flex',
              flexFlow: 'column',
              width: '60%'
            }}
          >
            <p
              style={{
                lineHeight: 1.25,
                margin: '0 0 1em 0'
              }}
            >
              Enter your mobile phone number to receive authorization code.
            </p>
            <PhoneTextField
              isRequired={true}
              autoFocus={true}
              label="Phone"
              name="phoneNumber"
              error={Boolean(errorMsg)}
              helperText={errorMsg}
              onFieldValueChange={() => {
                setErrorMsg('');
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                width: '100%',
                padding: '15px 0'
              }}
            >
              <SubmitButton
                loading={isSubmitting || isValidating}
                disabled={isSubmitting || isValidating}
                style={{
                  margin: '12px 0'
                }}
              >
                Submit
              </SubmitButton>
              <SecureTrustCertificate style={{ alignSelf: 'center' }} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
