import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse, UseQueryFnOptions } from 'types/network';

export interface DocumentHeader {
  documentHeaderId: string;
  documentTypeId: number;
  documentAliasName: string;
  showDataInRawFormat: boolean;
  createdDateTime: string;
}

export const useGetDocumentHeaders = (
  invoiceId: number,
  options?: UseQueryFnOptions<DocumentHeader[], ErrorResponse>
) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<DocumentHeader[], ErrorResponse>(
    [ROUTES.GetDocumentHeaders, invoiceId],
    async () => {
      const { data } = await Axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL}?route=${ROUTES.GetDocumentHeaders}`,
        data: {
          invoiceId
        }
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      ...options
    }
  );
};
