export enum LOGINTYPES {
  AUTHENTICATED = 0,
  SSO = 8,
  'CHART_COORDINATOR' = 1,
  'COLLECTIONS_AGENCY' = 2,
  'AUTOMATE_PROCESS' = 3,
  'EARLY_OUT' = 4,
  'CHART_SWAP' = 5,
  'MY_ENC_ONLY_CHART_COORD' = 6,
  'EARLY_OUT_AND_COLLECTIONS' = 7,
  'THIRD_PARTY_DEBT_VENDOR' = 9,
  'COLLECTIONS_THIRD_PARTY' = 10,
  'VENTRA_INTERNAL_DEMO' = 255
}

// Company IDs for overriding SSO Submit Update Request restriction
export const SSO_OVERRIDE_COMPANIES = [
  // Memorial Hermann
  14
];
