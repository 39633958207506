import { useQuery } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse, UseQueryFnOptions } from 'types/network';
import { ROUTES } from 'constants/routes';

export const usePartnerGetMaintenanceMode = (
  options?: UseQueryFnOptions<boolean, ErrorResponse>
) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<boolean, ErrorResponse>(
    ROUTES.PartnerGetMaintenanceMode,
    async () => {
      const { data } = await Axios({
        method: 'get',
        url: `?route=${ROUTES.PartnerGetMaintenanceMode}`
      });
      return data;
    },
    options
  );
};
