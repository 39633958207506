import { useMutation, useQueryClient } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse } from 'types/network';
import { ROUTES } from 'constants/routes';
import { EncounterException } from './useExceptions';

export interface UpdateExceptionEncounterHeadersBody {
  myEncounterHeaderExceptions: EncounterException[];
  comment: string;
}

export const useUpdateExceptionEncounterHeaders = () => {
  const Axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    ErrorResponse,
    UpdateExceptionEncounterHeadersBody
  >(
    async payload => {
      const { data } = await Axios({
        method: 'put',
        url: `/?route=${ROUTES.UpdateExceptionEncounterHeaders}`,
        data: payload
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          [
            ROUTES.GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID,
            variables.myEncounterHeaderExceptions[0].encounterHeaderId
          ],
          data
        );
      }
    }
  );
};
