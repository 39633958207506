import { useMutation } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse } from 'types/network';
import { ROUTES } from 'constants/routes';
import { PatientInsuranceInfo } from 'types/patientInsurance';

export const usePartnerUpdateRequestInsuranceInsert = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation<void, ErrorResponse, PatientInsuranceInfo>(
    async payload => {
      const { data } = await Axios({
        method: 'post',
        url: `/?route=${ROUTES.PartnerUpdateRequestInsuranceInsert}`,
        data: payload
      });
      return data;
    }
  );
};
