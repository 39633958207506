import { createTheme } from '@material-ui/core/styles';
import { BrandColor } from 'constants/brandColor';

export const theme = createTheme({
  palette: {
    primary: {
      main: BrandColor.PRIMARY,
      dark: 'rgb(44, 86, 118)',
      50: '#c8dbea',
      100: '#b6d0e4',
      200: '#9dc0da',
      300: '#7dabce',
      400: '#518fbe',
      500: BrandColor.PRIMARY,
      600: '#2c5676',
      700: '#264965',
      800: '#203e55',
      900: '#1b3549'
    },
    secondary: {
      main: BrandColor.SECONDARY,
      50: '#e3eff6',
      100: '#daeaf3',
      200: '#cde3ef',
      300: '#bdd9ea',
      400: '#a6cce2',
      500: BrandColor.SECONDARY,
      600: '#61a5cc',
      700: '#3f91c1',
      800: '#357ca4',
      900: '#2d69bc'
    },
    warning: {
      main: BrandColor.WARNING,
      50: '#fceccd',
      100: '#fbe5bd',
      200: '#fadda7',
      300: '#f8d18a',
      400: '#f6c262',
      500: BrandColor.WARNING,
      600: '#eb9c0d',
      700: '#c6840b',
      800: '#a8710a',
      900: '#8f6008'
    },
    text: {
      primary: BrandColor.TEXT
    },
    background: {
      default: 'rgb(242, 242, 242)'
    }
  }
});
