import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import axios from 'axios';
import { ROUTES } from 'constants/routes';
import { Divider, TextField, Typography } from '@material-ui/core';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#void-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
    padding: 10px;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  width: 550px;
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 20px;
  }
`;

const NewComment = ({ id, newComment, placeholder, onChange }) => (
  <StyledFormControl fullWidth>
    <TextField
      id={id}
      cols="40"
      multiline
      variant="outlined"
      label="Comment (optional)"
      rows="4"
      placeholder={placeholder}
      onChange={onChange}
      value={newComment}
    />
  </StyledFormControl>
);

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

export default function VoidDialog({
  show,
  voidReasons,
  optionalComment,
  onSave,
  onClose,
  handleOptionalCommentChange,
  handleVoidReasonChange,
  selectedVoidReason,
  isIBIS,
  isMulti = undefined,
  applicationID = undefined,
  headerIDs = undefined
}) {
  const header = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
    }
  };
  const [menuReasons, setMenuReasons] = useState(null);
  const fetchReasons = async (type = '1') => {
    let voidReasons = [];
    if (type === '1' || type === '4') {
      voidReasons = await axios.get(
        `${process.env.REACT_APP_URL}/${type}/?route=${ROUTES.PartnerGetEncounterHeaderVoidReasonList}`,
        header
      );
    }
    return voidReasons;
  };
  useEffect(() => {
    // side effects
    let mounted = true;
    if (mounted) {
      if (isMulti) {
        fetchReasons(applicationID || '1').then(payload => {
          setMenuReasons(payload.data);
        });
      } else {
        setMenuReasons(voidReasons);
      }
    }
    // cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDialog
      open={show}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="void-dialog"
    >
      <DialogTitle id="void-dialog" style={{ cursor: 'move' }}>
        Void Request
        <Typography color="error">
          Voiding {headerIDs?.length ?? 1} Encounter(s)
        </Typography>
      </DialogTitle>
      <Divider />
      <StyledDialogContent>
        {/* <CommentsPreset
                    comment={commentSelected}
                    handleChange={value => setCommentSelected(value)}
                /> */}
        <InputLabel id="selected-reason" style={{ marginBottom: '5px' }}>
          Select Reason
        </InputLabel>
        <FormControl required variant="outlined" style={{ width: '100%' }}>
          <Select
            id="void-reason-select"
            style={{ backgroundColor: 'white' }}
            labelId="selected-reason"
            value={selectedVoidReason}
            onChange={handleVoidReasonChange}
            input={<OutlinedInput />}
          >
            {!!menuReasons &&
              menuReasons.map(el => (
                <MenuItem key={el.encounterHeaderVoidReasonID} value={el}>
                  {el.encounterHeaderVoidReason}
                </MenuItem>
              ))}
            {!!voidReasons &&
              voidReasons.map(el => (
                <MenuItem key={el.encounterHeaderVoidReasonID} value={el}>
                  {el.encounterHeaderVoidReason}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {!isIBIS && (
          <NewComment
            id="void-newComment"
            newComment={optionalComment}
            onChange={handleOptionalCommentChange}
          />
        )}
        <ControlsContainer>
          <Button
            id="void-save"
            variant="contained"
            color="primary"
            disabled={selectedVoidReason === null}
            onClick={() => {
              const result = window.confirm(
                `You have selected ${
                  headerIDs?.length ?? 1
                } encounter(s) to void. Are you sure you want to proceed with voiding ${
                  headerIDs?.length ?? 1
                } encounter(s)? `
              );
              if (result) onSave();
            }}
          >
            Save
          </Button>
          <Button
            id="void-close"
            style={{ marginLeft: '5px' }}
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </ControlsContainer>
      </StyledDialogContent>
    </StyledDialog>
  );
}
