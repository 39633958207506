import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExceptionsDialogActions } from '../../Actions/ExceptionsDialogActions';

export const ExceptionsDialogLoading: React.FC = () => (
  <>
    <DialogContent>
      <CircularProgress aria-label="Loading data" />
    </DialogContent>
    <ExceptionsDialogActions allDisabled />
  </>
);
