import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDF from './PdfDocument';

import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

function PaperComponent(props) {
  return (
    <Draggable handle="#details" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  // stylelint-disable-next-line selector-type-no-unknown
  tooltip: {
    fontSize: '14px'
  }
}));

const StyledDialogTitle = styled(DialogTitle)`
  cursor: move;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
`;
const TitleControl = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
  }
`;

function PatientStatement({ accountNumber, open, close }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchPatientStatement = async () => {
      const header = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
        }
      };
      let data;
      const currentData = data;
      if (accountNumber) {
        let response;
        try {
          response = await axios.post(
            `${process.env.REACT_APP_URL}/?route=PartnerGetMyTransactionSummaryPatientStatement`,
            {
              ...accountNumber
            },
            header
          );
          data = response.data;
        } catch (error) {
          alert(error);
        }
      } else {
        data = currentData;
      }
      setData(data);
      setLoading(!accountNumber);
    };
    fetchPatientStatement();
  }, [accountNumber]);
  const classes = useStyles();

  return (
    <StyledDialog
      open={open}
      PaperComponent={PaperComponent}
      maxWidth="lg"
      aria-labelledby="details"
    >
      <StyledDialogTitle id="details">
        <TitleControl>
          <Typography variant="h6">Patient Statement</Typography>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => close()} />
        </TitleControl>
        {/* <Typography style={{fontSize: "0.7rem"}} variant="h6">{data.???}</Typography> */}
      </StyledDialogTitle>
      {loading ? (
        'Loading ...'
      ) : (
        <div
          style={{
            width: '90vh',
            height: '90vh',
            margin: '0 auto',
            padding: '0 30px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 0'
            }}
          >
            <PDFDownloadLink
              document={<PDF data={data} />}
              fileName={'patient-statement.pdf'}
              style={{
                color: '#4a4a4a'
              }}
            >
              {({ loading }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <Tooltip title="Download" classes={classes}>
                    <IconButton aria-label="patient-statement-download">
                      <CloudDownloadIcon style={{ color: '#1779ba' }} />
                    </IconButton>
                  </Tooltip>
                )
              }
            </PDFDownloadLink>
          </div>
          <PDFViewer
            style={{
              width: '100%',
              height: 'calc(100% - 100px)',
              margin: '0 auto'
            }}
          >
            <PDF data={data} />
          </PDFViewer>
        </div>
      )}
    </StyledDialog>
  );
}

export default PatientStatement;
