import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types/network';

export interface CommentType {
  encounterHeaderCommentTypeID: number;
  encounterHeaderCommentType: string;
}

export const usePartnerGetMyEncounterCommentTypes = () => {
  const Axios = useAuthenticatedAxios();
  return useQuery<CommentType[], ErrorResponse>(
    [ROUTES.PartnerGetMyEncounterCommentTypes],
    async () => {
      const { data } = await Axios({
        method: 'get',
        url: `/?route=${ROUTES.PartnerGetMyEncounterCommentTypes}`
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );
};
