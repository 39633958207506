/* eslint-disable eqeqeq */
export function formatDollar(num) {
  if (!num) return '$0.00';
  var p = num.toFixed(2).split('.');
  const formattedNum =
    '$' +
    p[0]
      .split('')
      .reverse()
      .reduce(function (acc, num, i) {
        return num == '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
      }, '') +
    '.' +
    p[1];
  return num < 0 ? '-' + formattedNum : formattedNum;
}
