import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import Table from './Table';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#details" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const StyledDialogTitle = styled(DialogTitle)`
  cursor: move;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
`;
const TitleControl = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
  }
`;
//backgroundColor: highlight ? '#95bcd7': 'inherit'
const currency = num => (
  <span style={{ display: 'block', textAlign: 'right', width: '100%' }}>
    <NumberFormat
      value={num}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      decimalScale={2}
      fixedDecimalScale
    />
  </span>
);

function MyTransactionSummaryDetails({ open, close, accountNumber, facility }) {
  // state for loading
  // get  myTransactionSummyarDetails
  // accountNumber
  // 'esc' to close???
  const [loading, setLoading] = useState(true);
  const [summaryDetails, setSummaryDetails] = useState(null);
  useEffect(() => {
    const fetchMyTransactionSummaryDetails = async () => {
      const header = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
        }
      };
      let data;
      if (accountNumber) {
        let response;
        try {
          response = await axios.post(
            `${process.env.REACT_APP_URL}/?route=PartnerGetMyTransactionSummaryDetails`,
            {
              ...accountNumber
            },
            header
          );
          data = response.data;
        } catch (error) {
          alert(error);
        }
      } else {
        data = null;
      }
      setSummaryDetails(data);
      setLoading(!data);
    };
    fetchMyTransactionSummaryDetails();
  }, [accountNumber]);
  const info = summaryDetails ? summaryDetails.patientAccountInfo : {};
  const { dos, dob, account, lastName, firstName, sex, statementAccount } =
    info;
  const {
    employer,
    employerAddress,
    employerCity,
    employerState,
    employerZip
  } = info;
  const { charge, pmt, adj, balance, pmtIns, pmtPat, collectionBal } = info;
  return (
    <StyledDialog
      open={open}
      PaperComponent={PaperComponent}
      maxWidth="lg"
      aria-labelledby="details"
    >
      <StyledDialogTitle id="details">
        <TitleControl>
          <Typography variant="h6" style={{ width: '100%' }}>
            Transaction Details
            <CloseIcon
              style={{ cursor: 'pointer', float: 'right' }}
              onClick={() => close()}
            />
            <PrintIcon
              style={{
                cursor: 'pointer',
                float: 'right',
                marginRight: '2.188rem'
              }}
              onClick={() => window.print()}
            />
          </Typography>
        </TitleControl>
        {/* font-size: 0.7rem; */}
        <Typography style={{ fontSize: '0.7rem' }} variant="h6">
          {facility}
        </Typography>
      </StyledDialogTitle>
      {loading ? (
        'loading...'
      ) : (
        <div style={{ padding: '10px' }}>
          {/* PATIENT INFO TABLE */}
          <Table
            columns={[
              {
                Header: 'Patient Info',
                columns: [
                  {
                    Header: 'DOS',
                    id: 'DOS',
                    accessor: ({ dos }) => dos && moment(dos).format('MM/DD/YY')
                  },
                  {
                    Header: 'Statement Account',
                    accessor: 'statementAccount'
                  }, // TODO: ????
                  {
                    Header: 'Invoice',
                    accessor: 'account'
                  },
                  {
                    Header: 'Patient',
                    id: 'firstName',
                    accessor: ({ lastName, firstName }) =>
                      `${lastName}, ${firstName}`
                  },
                  {
                    Header: 'DOB',
                    id: 'DOB',
                    accessor: ({ dob }) => dob && moment(dob).format('MM/DD/YY')
                  },
                  {
                    Header: 'Sex',
                    accessor: 'sex'
                  }
                ]
              }
            ]}
            data={[
              { dos, dob, account, lastName, firstName, sex, statementAccount }
            ]}
          />

          {/* EMPLOYMENT INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Employment Info',
                columns: [
                  {
                    Header: 'Employer',
                    accessor: 'employer'
                  },
                  {
                    Header: 'Employer Address',
                    accessor: 'employerAddress'
                  },
                  {
                    Header: 'City',
                    accessor: 'employerCity'
                  },
                  {
                    Header: 'State',
                    accessor: 'employerState'
                  },
                  {
                    Header: 'Zip',
                    accessor: 'employerZip'
                  },
                  {
                    Header: 'Phone',
                    accessor: 'employerPhone'
                  }
                ]
              }
            ]}
            data={
              !employer &&
              !employerAddress &&
              !employerCity &&
              !employerState &&
              !employerZip
                ? []
                : [
                    {
                      employer,
                      employerAddress,
                      employerCity,
                      employerState,
                      employerZip
                    }
                  ]
            }
          />

          {/* ACCOUNT INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Account Info',
                columns: [
                  {
                    Header: 'Charge',
                    id: 'charge',
                    accessor: ({ charge }) => currency(charge)
                  },
                  {
                    Header: 'Adjustment',
                    id: 'adj',
                    accessor: ({ adj }) => currency(adj)
                  },
                  {
                    Header: 'Insurance Payments',
                    id: 'pmtIns',
                    accessor: ({ pmtIns }) => currency(pmtIns)
                  },
                  {
                    Header: 'Patient Payments',
                    id: 'pmtPat',
                    accessor: ({ pmtPat }) => currency(pmtPat)
                  },
                  {
                    Header: 'Balance',
                    id: 'balance',
                    accessor: ({ balance }) => currency(balance)
                  },
                  {
                    Header: 'Collection Balance',
                    id: 'collectionBal',
                    accessor: ({ collectionBal }) => currency(collectionBal)
                  }
                ]
              }
            ]}
            data={[
              { charge, pmt, adj, balance, pmtIns, pmtPat, collectionBal }
            ]}
          />

          {/* CHARGES INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Charges',
                columns: [
                  {
                    Header: 'No',
                    accessor: 'no'
                  },
                  {
                    Header: 'Date',
                    id: 'date',
                    accessor: ({ date }) =>
                      date && moment(date).format('MM/DD/YYYY')
                  },
                  {
                    Header: 'Description',
                    accessor: 'description'
                  },
                  {
                    Header: 'CPT',
                    accessor: 'cpt'
                  },
                  {
                    Header: 'Charge',
                    id: 'charges',
                    accessor: ({ charges }) => currency(charges)
                  },
                  {
                    Header: 'Ins. Payments',
                    id: 'insPmt',
                    accessor: ({ insPmt }) => currency(insPmt)
                  },
                  {
                    Header: 'Adjustments',
                    id: 'adj',
                    accessor: ({ adj }) => currency(adj)
                  },
                  {
                    Header: 'Pat. Payments',
                    id: 'patPmt',
                    accessor: ({ patPmt }) => currency(patPmt)
                  },
                  {
                    Header: 'Balance',
                    id: 'bal',
                    accessor: ({ bal }) => currency(bal)
                  }
                ]
              }
            ]}
            data={
              summaryDetails?.charges?.sort((chargeA, chargeB) => {
                return chargeA.no - chargeB.no;
              }) || []
            }
          />

          {/* PROVIDERS INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Providers',
                columns: [
                  {
                    Header: 'CPT',
                    accessor: 'cpt'
                  },
                  {
                    Header: 'RVU',
                    accessor: 'totalRVU'
                  },
                  {
                    Header: 'Dr.',
                    id: 'doctorName',
                    accessor: ({ docFName, docLName }) =>
                      `${docLName}, ${docFName}`
                  },
                  {
                    Header: 'APP',
                    id: 'appName',
                    accessor: ({ appFName, appLName }) =>
                      `${appLName}, ${appFName}`
                  },
                  {
                    Header: 'Crediting Provider',
                    id: 'provider',
                    accessor: ({ creditProviderFName, creditProviderLName }) =>
                      `${creditProviderLName}, ${creditProviderFName}`
                  }
                ]
              }
            ]}
            data={summaryDetails.providers}
          />

          {/* TRANSACTIONS INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Transactions',
                columns: [
                  {
                    Header: 'Date',
                    id: 'date',
                    accessor: ({ date }) =>
                      date && moment(date).format('MM/DD/YYYY')
                  },
                  {
                    Header: 'Post Date',
                    id: 'postDt',
                    accessor: ({ postDt }) =>
                      postDt && moment(postDt).format('MM/DD/YYYY')
                  },
                  {
                    Header: 'Transaction Amount',
                    id: 'tranAmt',
                    accessor: ({ tranAmt }) => currency(tranAmt)
                  },
                  {
                    Header: 'Plan Name',
                    accessor: 'planName'
                  },
                  {
                    Header: 'Comments',
                    accessor: 'comments'
                  }
                ]
              }
            ]}
            data={summaryDetails.transactions}
          />

          {/* BILLING HISTORY INFO TABLE */}
          <Table
            style={{ marginTop: '20px' }}
            columns={[
              {
                Header: 'Billing History',
                columns: [
                  {
                    Header: 'Action Date',
                    id: 'actionDate',
                    accessor: ({ actionDate }) =>
                      actionDate && moment(actionDate).format('MM/DD/YYYY')
                  },
                  {
                    Header: 'Description',
                    accessor: 'description'
                  },
                  {
                    Header: 'Amount',
                    id: 'amount',
                    accessor: ({ amount }) => currency(amount)
                  },
                  {
                    Header: 'Additional Info',
                    accessor: 'planName'
                  }
                ]
              }
            ]}
            data={summaryDetails.billingHistory}
          />
        </div>
      )}
    </StyledDialog>
  );
}

export default MyTransactionSummaryDetails;
