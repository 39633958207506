import Axios, { AxiosRequestConfig } from 'axios';
import { useAuthentication } from 'hooks/useAuthentication';

export const useAuthenticatedAxios = () => {
  const { getToken } = useAuthentication();
  return async (config: AxiosRequestConfig) =>
    await Axios({
      ...config,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...config.headers
      }
    });
};
