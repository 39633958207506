import { EncounterException } from 'components/ExceptionsDialog/lib/useExceptions';
import { UpdateExceptionEncounterHeadersBody } from 'components/ExceptionsDialog/lib/useUpdateExceptionEncounterHeaders';

// because MenuItem doesn't support object values, we have to do manual serialization for composite ids
// https://github.com/mui-org/material-ui/issues/30066
export interface EncounterExceptionWithProviderCompositeId
  extends Omit<
    EncounterException,
    'responsibleProviderId' | 'isProviderAssistant'
  > {
  responsibleProviderCompositeId: string;
}

export interface ExceptionsFormContext
  extends Omit<
    UpdateExceptionEncounterHeadersBody,
    'myEncounterHeaderExceptions'
  > {
  myEncounterHeaderExceptions: EncounterExceptionWithProviderCompositeId[];
  submitType: number;
}

export enum SubmitType {
  'save',
  'authorize'
}
