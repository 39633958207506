import { useMutation } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse } from 'types/network';
import { ROUTES } from 'constants/routes';

export interface ClearAllExceptionEncounterHeaderExceptionsMultiBody {
  encounterHeaderIds: number[];
  comment?: string;
}

export const useClearAllExceptionEncounterHeaderExceptionsMulti = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation<
    unknown,
    ErrorResponse,
    ClearAllExceptionEncounterHeaderExceptionsMultiBody
  >(async payload => {
    const { data } = await Axios({
      method: 'put',
      url: `/?route=${ROUTES.ClearAllExceptionEncounterHeaderExceptionsMulti}`,
      data: {
        comment: payload.comment,
        // the backend has a misleading name for what this field actually is
        // so I renamed it in the api of this hook
        encounterHeaderId: payload.encounterHeaderIds
      }
    });
    return data;
  });
};
