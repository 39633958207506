import { StyleSheet } from '@react-pdf/renderer';

export const boldFont = 'Helvetica-Bold';

export const styles = StyleSheet.create({
  text: {
    fontSize: '10px',
    /* stylelint-disable */
    fontFamily: 'Helvetica',
    marginBottom: '0px',
    marginLeft: '0px',
    marginTop: '0px',
    marginRight: '0px'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '10px',
    padding: '5px'
  },
  view: {
    flex: 1
  }
});
