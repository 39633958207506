import { useMutation, useQueryClient } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ErrorResponse } from 'types/network';
import { ROUTES } from 'constants/routes';

export interface ClearAllExceptionEncounterHeaderExceptionsBody {
  encounterHeaderId: number;
  comment: string;
}

export const useClearAllExceptionEncounterHeaderExceptions = () => {
  const Axios = useAuthenticatedAxios();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    ErrorResponse,
    ClearAllExceptionEncounterHeaderExceptionsBody
  >(
    async payload => {
      const { data } = await Axios({
        method: 'put',
        url: `/?route=${ROUTES.ClearAllExceptionEncounterHeaderExceptions}`,
        data: payload
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          [
            ROUTES.GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID,
            variables.encounterHeaderId
          ],
          data
        );
      }
    }
  );
};
