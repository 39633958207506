import React from 'react';
import EnhancedTable from './EnhancedTable';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Assignment from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { theme } from 'lib/theme';
import { BrandColor } from 'constants/brandColor';
import moment from 'moment';

const StyledTooltip = styled(Tooltip)`
  font-size: 14px;
`;

const RowActionsWrapper = styled.span`
  white-space: nowrap;
`;

const RowActions = ({
  rowId,
  handleComments,
  handleVoid,
  handleOpenExceptions,
  dateOfService,
  partnerPortalAllowExceptionManagement,
  status
}) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  return (
    <RowActionsWrapper>
      <StyledTooltip title="Comment">
        <IconButton
          aria-label="comment"
          onClick={() => handleComments(rowId, dateOfService)}
        >
          <InsertCommentIcon style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </StyledTooltip>
      {status === 'Pre-Coding Authorization' &&
        partnerPortalAllowExceptionManagement && (
          <StyledTooltip title="Open Exceptions">
            <IconButton
              aria-label="open exceptions"
              onClick={() => handleOpenExceptions(rowId)}
            >
              <Assignment />
            </IconButton>
          </StyledTooltip>
        )}
      {!user.readOnly ? (
        <StyledTooltip title="Void">
          <IconButton
            aria-label="void"
            onClick={() => handleVoid(rowId, dateOfService)}
          >
            <DeleteForeverIcon style={{ color: BrandColor.DANGER }} />
          </IconButton>
        </StyledTooltip>
      ) : null}
    </RowActionsWrapper>
  );
};

const MuiEnhancedTable = ({
  initial = undefined,
  status,
  tableData,
  handleComments,
  handleVoid,
  openRecord,
  notSearched,
  handleOpenExceptions,
  refetch
}) => {
  const COLUMNS = [
    {
      Header: '',
      id: 'encounterHeaderID',
      accessor: ({
        encounterHeaderID,
        facilityID,
        dateOfService,
        applicationID,
        partnerPortalAllowExceptionManagement
      }) => (
        <RowActions
          rowId={{
            encounterHeaderId: encounterHeaderID,
            applicationID,
            facilityID
          }}
          handleComments={handleComments}
          handleVoid={handleVoid}
          handleOpenExceptions={handleOpenExceptions}
          dateOfService={dateOfService}
          partnerPortalAllowExceptionManagement={
            partnerPortalAllowExceptionManagement
          }
          status={status}
        />
      )
    },
    {
      Header: 'Facility',
      accessor: 'facilityName'
    },
    {
      Header: 'DOS',
      id: 'dateOfService',
      accessor: 'dateOfService',
      sortType: 'dateString'
    },
    {
      Header: 'Account',
      accessor: 'facilityAccountNumber'
    },
    {
      Header: 'MRN',
      accessor: 'mrn'
    },
    {
      Header: 'Patient',
      accessor: 'patientName'
    },
    {
      Header: 'Patient DOB',
      sortType: 'dateString',
      accessor: ({ patientDOB }) =>
        moment(patientDOB) > moment('01/01/1901') ? patientDOB : ''
    },
    {
      Header: 'Patient Gender',
      accessor: 'patientGender',
      width: 50
    },
    {
      Header: 'Attending Physician',
      accessor: 'attendingProvider'
    },
    {
      Header: 'APP',
      accessor: 'providerMidLevelName'
    },
    {
      Header: 'Date Held',
      id: 'dateHeld',
      accessor: 'dateHeld',
      sortType: 'dateString'
    },
    {
      Header: 'Responsible Provider',
      accessor: 'responsibleProvider'
    },
    {
      Header: 'Insurance',
      accessor: 'insurance'
    },
    {
      Header: 'Incomplete Reason',
      accessor: 'incompleteReason'
    },
    {
      Header: 'Last Comment',
      accessor: 'lastComment'
    }
    /* See Task #18700 for why this is commented */
    // {
    //   Header: 'Action Owner',
    //   accessor: 'actionOwner'
    // },
    // {
    //   Header: 'Next Check Date',
    //   id: 'nextCheckDate',
    //   accessor: 'nextCheckDate'
    // }
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => COLUMNS, [status]);
  const data = React.useMemo(() => tableData, [tableData]);
  // // We need to keep the table from resetting the pageIndex when we
  // // Update data. So we can keep track of that flag with a ref.

  // // When our cell renderer calls updateMyData, we'll use
  // // the rowIndex, columnId and new value to update the
  // // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true)
  //   setData(old =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value,
  //         }
  //       }
  //       return row
  //     })
  //   )
  // }

  return (
    <EnhancedTable
      columns={columns}
      data={data || []}
      initial={initial}
      openRecord={openRecord}
      notSearched={notSearched}
      refetch={refetch}
    />
  );
};

export default MuiEnhancedTable;
