import React from 'react';
import { useField } from 'formik';
import InputMask from 'react-input-mask';
import { composeValidators, requiredValidator } from 'sd-formik-validators';
import { StyledTextField } from './StyledTextField';
const validatePhone = value => {
  const validPhoneRegex = /(^(\d{3})-(\d{3})-(\d{4})$|^(\d){10}$)/;
  if (
    !value ||
    value.trim() === '___-___-____' ||
    validPhoneRegex.test(value)
  ) {
    return undefined;
  } else {
    return 'Invalid Phone Number';
  }
};
export const PhoneTextField = ({
  isRequired = false,
  name,
  label,
  validate = [],
  helperText = '',
  error = false,
  onFieldValueChange = () => {},
  ...props
}) => {
  const [field, meta] = useField({
    name,
    validate: isRequired
      ? composeValidators(requiredValidator, validatePhone, ...validate)
      : composeValidators(validatePhone, ...validate)
  });

  return (
    <InputMask
      mask={'999-999-9999'}
      {...field}
      onChange={e => {
        field.onChange(e);
        onFieldValueChange(e);
      }}
    >
      <StyledTextField
        label={isRequired && label ? `*${label}` : label}
        error={!!(meta.touched && meta.error) || error}
        helperText={!!(meta.touched && meta.error) ? meta.error : helperText}
        style={{
          ...props.style,
          marginBottom: !!(meta.touched && meta.error)
            ? '4px'
            : (!!props && !!props.style && props.style.marginBottom) || ''
        }}
        {...props}
      />
    </InputMask>
  );
};
