import { useMutation } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ROUTES } from 'constants/routes';
import { ErrorResponse } from 'types/network';
import { PatientInsuranceInfo } from 'types/patientInsurance';

export const usePartnerGetPatientInsurance = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation<
    PatientInsuranceInfo,
    ErrorResponse,
    { account: number; srcSystemKey: number }
  >(async ({ account, srcSystemKey }) => {
    const { data } = await Axios({
      method: 'post',
      url: `/?route=${ROUTES.PartnerGetPatientInsurance}`,
      data: {
        account,
        srcSystemKey
      }
    });
    return data;
  });
};
