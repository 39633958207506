import { createContext, useContext, useState } from 'react';

const MyTransactionsSummaryContext = createContext({});

export function useMyTransactionsSummaryContext() {
  return useContext(MyTransactionsSummaryContext);
}

function MyTransactionsSummaryContextProvider(props) {
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [facility, setFacility] = useState([]);
  const [advancedFilter, setAdvancedFilter] = useState('-- Select --');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [search, setSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [encounters, setEncounters] = useState([]);
  const [providerSearch, setProviderSearch] = useState(null);
  const [notSearched, setNotSearched] = useState(true);

  const providerValue = {
    facilityOptions,
    setFacilityOptions,
    facility,
    setFacility,
    advancedFilter,
    setAdvancedFilter,
    value1,
    setValue1,
    value2,
    setValue2,
    search,
    setSearch,
    isLoading,
    setIsLoading,
    encounters,
    setEncounters,
    providerSearch,
    setProviderSearch,
    notSearched,
    setNotSearched
  };

  return (
    <MyTransactionsSummaryContext.Provider value={providerValue}>
      {props.children}
    </MyTransactionsSummaryContext.Provider>
  );
}

export default MyTransactionsSummaryContextProvider;
