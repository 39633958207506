import { theme } from 'lib/theme';
import styled from 'styled-components';

export const BaseTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #0003;
  th {
    text-align: left;
    background-color: ${theme.palette.primary.dark};
    color: white;
    padding: 8px 5px;
    line-height: 1rem;
    border: 1px solid #0003;
  }
  tr {
    background-color: #fff;
    &.active-row {
      background-color: rgb(232, 240, 254) !important;
      font-weight: bold;
    }
  }
  &.alt-bg {
    tbody tr:nth-child(odd) {
      background-color: #eee;
    }

    tbody tr:nth-child(even) {
      background-color: #fff;
    }
  }

  tbody td {
    line-height: 1.4;
    padding: 8px 5px;
    border: 1px solid #0003;
  }
`;
