import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export interface BaseButtonProps extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

export const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  loading = false,
  disabled = false,
  ...props
}) => (
  <Button
    type="submit"
    color="primary"
    variant="contained"
    disabled={disabled || loading}
    {...props}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {children}
      {loading && (
        <CircularProgress
          size={20}
          style={{
            marginLeft: '8px'
          }}
        />
      )}
    </div>
  </Button>
);
