import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import CommentsPreset from 'components/Comments/CommentsPreset';
import { SubmitButton } from 'components/Buttons';
import { DraggableDialog } from 'components/DraggableDialog/DraggableDialog';

const StyledDialogContent = styled(DialogContent)`
  width: 550px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  ${p => (p.isInternal ? 'align-self: flex-end;' : '')}
`;

const Bubble = styled.div`
  padding: 5px;
  background-color: ${p => (p.isInternal ? '#2ea0d3' : '#3ad13a')};
  max-width: 300px;
  border-radius: 5px;
  color: white;
  margin-bottom: 3px;
  margin-top: 5px;
  ${p => (p.isInternal ? 'align-self: flex-end;' : '')}
`;

const Info = styled.div`
  display: block;
  font-size: 12px;
  text-align: ${p => (p.isInternal ? 'right' : 'left')};
`;

function CommentBox({ comment, user, time, isInternal }) {
  return (
    <Box isInternal={isInternal}>
      <Info isInternal={isInternal}>
        {user} {time}
      </Info>
      <Bubble isInternal={isInternal}>{comment}</Bubble>
    </Box>
  );
}

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 15px;
  }
`;

const CHARACTER_LIMIT = 255;
const isNewCommentValid = value => value.length > CHARACTER_LIMIT;
const NewComment = ({ newComment, placeholder, onChange }) => (
  <StyledFormControl fullWidth>
    <TextField
      id="comment-newComment"
      label="Comment (optional)"
      multiline
      rows={5}
      variant="outlined"
      placeholder={placeholder}
      onChange={onChange}
      value={newComment}
      error={isNewCommentValid(newComment)}
      helperText={`${newComment.length}/${CHARACTER_LIMIT}`}
    />
  </StyledFormControl>
);

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CommentsContainer = styled(Paper)`
  overflow-y: scroll;
  height: max-content;
  max-height: calc(100vh - 500px);
  padding: 10px;
  border: 1px solid black;
`;

export default function Comments({
  show,
  dateOfService,
  comments,
  newComment,
  isSubmitting = false,
  onSubmit,
  onChange,
  onClose,
  onChangePreset,
  outsidePreset
}) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const commentsContainer = useRef(null);
  const [selectedPreset, setSelectedPreset] = useState('');
  useLayoutEffect(() => {
    if (commentsContainer && commentsContainer.current) {
      commentsContainer.current.scrollTop =
        commentsContainer.current.scrollHeight;
    }
  }, [comments]);
  useEffect(() => {
    if (!outsidePreset.encounterHeaderCommentTypeID) {
      setSelectedPreset('');
    }
  }, [outsidePreset.encounterHeaderCommentTypeID]);
  return (
    <DraggableDialog
      open={show}
      onClose={onClose}
      id="comment-dialog"
      title={`Comments for Encounter: ${dateOfService}`}
    >
      <StyledDialogContent>
        <CommentsContainer
          id="comment-commentBox"
          ref={commentsContainer}
          style={{
            marginBottom: '12px'
          }}
        >
          {comments.length > 0 ? (
            comments.map(
              ({
                comment,
                createdDateTime,
                isInternalUser,
                createdByUserName
              }) => (
                <CommentBox
                  key={`comment-${createdDateTime}`}
                  user={createdByUserName}
                  time={`${moment(createdDateTime).format('MM/DD/YY')} ${moment(
                    createdDateTime
                  ).format('LT')} EST`}
                  comment={comment}
                  isInternal={isInternalUser}
                />
              )
            )
          ) : (
            <div>No comments found</div>
          )}
        </CommentsContainer>
        {!user.readOnly ? (
          <CommentsPreset
            comment={selectedPreset}
            handlePresetChange={value => setSelectedPreset(value)}
            handleChangeComment={value => onChangePreset(value)}
            isSubmitting={isSubmitting}
          />
        ) : null}
        {!user.readOnly ? (
          <NewComment
            placeholder="Additional Comments ..."
            newComment={newComment}
            onChange={onChange}
          />
        ) : null}
        <ControlsContainer>
          {!user.readOnly ? (
            <SubmitButton
              id="comment-submit"
              variant="contained"
              color="primary"
              disabled={!selectedPreset || isNewCommentValid(newComment)}
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Submit
            </SubmitButton>
          ) : null}
          <Button
            disabled={isSubmitting}
            id="comment-close"
            style={{ marginLeft: '5px' }}
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            Close
          </Button>
        </ControlsContainer>
      </StyledDialogContent>
    </DraggableDialog>
  );
}
