import React from 'react';
import TextField from '@material-ui/core/TextField';

export const StyledTextField = props => {
  return (
    <TextField
      variant="outlined"
      InputLabelProps={{
        style: {
          height: 2
        }
      }}
      margin="none"
      inputProps={{
        style: {
          height: 2,
          padding: '22px 16px 26px 16px',
          backgroundColor: 'white',
          borderRadius: '5px'
        }
      }}
      autoComplete="off"
      {...props}
    />
  );
};
