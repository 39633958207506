import ReactInputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

export const DateField = ({
  value,
  setValue,
  label,
  id,
  error,
  helperText
}) => {
  return (
    <ReactInputMask
      id={id}
      alwaysShowMask
      mask="99/99/9999"
      value={value}
      onChange={e => setValue(e.target.value)}
    >
      <TextField
        variant="outlined"
        required
        error={error && error}
        helperText={error && helperText}
        label={label}
        InputProps={{
          style: {
            backgroundColor: 'white'
          }
        }}
        inputlabelprops={{
          shrink: true
        }}
      />
    </ReactInputMask>
  );
};
