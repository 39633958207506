import React from 'react';
import { Tooltip as MaterialTooltip, withStyles } from '@material-ui/core';
// https://material-ui.com/api/tooltip/
// title *	node Tooltip title. Zero-length titles string are never displayed.
const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#1779ba',
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
    border: '2px solid #415464'
  }
}))(MaterialTooltip);
export const Tooltip = props => {
  return (
    <CustomTooltip placement="top" arrow {...props} title={props.title || ''}>
      <span>{props.children}</span>
    </CustomTooltip>
  );
};
