import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types/network';

export interface EncounterException {
  encounterHeaderId: number;
  exceptionEncounterHeaderTypeId: number;
  isActive: boolean;
  uiLabel: string;
  responsibleProviderId: number;
  isProviderAssistant: boolean;
}

export const useExceptions = (encounterHeaderId: number) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<EncounterException[], ErrorResponse>(
    [
      ROUTES.GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID,
      encounterHeaderId
    ],
    async () => {
      const { data } = await Axios({
        method: 'get',
        url: `/${encounterHeaderId}?route=${ROUTES.GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID}`
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );
};
