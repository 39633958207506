import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fontsource/roboto';
import './style.css';
import Axios from 'axios';

import { styled } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'lib/theme';

Axios.defaults.baseURL = process.env.REACT_APP_URL;
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      document.location.href = '/';
    }
    return Promise.reject(error);
  }
);

const AppContainer = styled('div')({
  outline: `4px solid ${process.env.REACT_APP_ENV_COLOR}`,
  outlineOffset: '-4px',
  height: '100%',
  minHeight: '100vh'
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <AppContainer>
        <App />
      </AppContainer>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
