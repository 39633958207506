import React from 'react';
import { GottliebGroup, PoweredBy, GottliebLogo } from './styled';

function PoweredByGottlieb({ style }) {
  return (
    <GottliebGroup
      style={{
        ...style
      }}
    >
      <PoweredBy>Powered By</PoweredBy>
      <GottliebLogo src={''} />
    </GottliebGroup>
  );
}

export default PoweredByGottlieb;
