import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import { EncounterException } from '../../../lib/useExceptions';
import { FastField, FieldProps, useFormikContext } from 'formik';
import { Provider } from 'components/ExceptionsDialog/lib/useProviders';
import {
  EncounterExceptionWithProviderCompositeId,
  ExceptionsFormContext
} from '../types';
import { defaultProviderCompositeId } from '../lib/utils';

const StyledTable = styled(Table)`
  th,
  td {
    border: 1px solid black;
  }
  th {
    background-color: rgb(65, 84, 100);
    color: white;
    font-weight: bold;
  }
  tr:nth-child(odd) {
    td {
      background-color: #ccc;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #f5f5f5;
    }
  }
`;

const StyledSelect = styled(Select)`
  && {
    min-width: 200px;
  }
`;

export interface SelectedExceptionsProps {
  providers: Provider[];
}

export const SelectedExceptions: React.FC<SelectedExceptionsProps> = ({
  providers
}) => {
  const {
    values: { myEncounterHeaderExceptions }
  } = useFormikContext<ExceptionsFormContext>();
  return (
    <StyledTable size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Exception</TableCell>
          <TableCell id="provider-label">Provider</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {myEncounterHeaderExceptions.map((ex, i) => (
          <TableRow
            key={ex.uiLabel}
            data-testid={ex.exceptionEncounterHeaderTypeId}
          >
            <TableCell padding="checkbox">
              <FastField name={`myEncounterHeaderExceptions.${i}.isActive`}>
                {({ field }: FieldProps<EncounterException[]>) => (
                  <Checkbox
                    checked={ex.isActive}
                    inputProps={{ 'aria-label': 'select exception' }}
                    {...field}
                  />
                )}
              </FastField>
            </TableCell>
            <TableCell>{ex.uiLabel}</TableCell>
            <TableCell>
              <FastField
                name={`myEncounterHeaderExceptions.${i}.responsibleProviderCompositeId`}
              >
                {({
                  field
                }: FieldProps<
                  EncounterExceptionWithProviderCompositeId['responsibleProviderCompositeId'],
                  ExceptionsFormContext
                >) => (
                  <FormControl fullWidth>
                    <StyledSelect labelId="provider-label" {...field}>
                      <MenuItem
                        key={defaultProviderCompositeId}
                        value={defaultProviderCompositeId}
                      >
                        None
                      </MenuItem>
                      {providers.map(provider => (
                        <MenuItem
                          key={`${provider.id}-${provider.isProviderAssistant}`}
                          value={`${provider.id}-${provider.isProviderAssistant}`}
                        >
                          {provider.value}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                )}
              </FastField>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
