import React from 'react';
import { useExceptions } from '../lib/useExceptions';
import { useProviders } from '../lib/useProviders';
import { ExceptionsDialogError } from './Error/ExceptionsDialogError';
import { ExceptionsForm } from './Form/ExceptionsForm';
import { ExceptionsDialogLoading } from './Loading/ExceptionsDialogLoading';

export interface ExceptionsDialogContentProps {
  encounterHeaderId: number;
  facilityId: number;
}

export const ExceptionsDialogContent: React.FC<ExceptionsDialogContentProps> =
  ({ encounterHeaderId, facilityId }) => {
    const {
      isFetchedAfterMount: isFetchedAfterMountExceptions,
      data: exceptions,
      error: exceptionsError
    } = useExceptions(encounterHeaderId);
    const {
      isFetchedAfterMount: isFetchedAfterMountProviders,
      data: providers,
      error: providersError
    } = useProviders(facilityId);

    return !isFetchedAfterMountExceptions || !isFetchedAfterMountProviders ? (
      <ExceptionsDialogLoading />
    ) : exceptionsError || providersError ? (
      <ExceptionsDialogError>
        {(exceptionsError || providersError)?.response?.data.Error}
      </ExceptionsDialogError>
    ) : !exceptions || !exceptions.length || !providers || !providers.length ? (
      <ExceptionsDialogError>
        No data returned from the backend
      </ExceptionsDialogError>
    ) : (
      <ExceptionsForm exceptions={exceptions} providers={providers} />
    );
  };
