import { createContext, useContext } from 'react';

export interface ExceptionsDialogContextType {
  handleClose: () => void;
}

export const ExceptionsDialogContext = createContext({
  handleClose: () => {}
});

export const useExceptionsDialogContext = () =>
  useContext(ExceptionsDialogContext);
