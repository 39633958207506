import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import DigitsField from 'components/DigitsField';
import { SubmitButton } from 'components/Buttons';
import { useNavigate } from 'react-router';
import { usePartnerGetMfaAuthentication } from 'hooks/requests/usePartnerGetMfaAuthentication';
import { usePartnerSendMfaTextToPhoneNumber } from 'hooks/requests/usePartnerSendMfaTextToPhoneNumber';
import { Button } from '@material-ui/core';
import SecureTrustCertificate from 'components/SecureTrustCertificate';
import { useAuthentication } from 'hooks/useAuthentication';
import { FindHelp } from '../FindHelp';
import { useSendMfaTextToEmail } from 'hooks/requests';
import { User } from 'types/user';

export interface MFAProps {
  user: User;
  setErrorMessage: (msg: string) => void;
  setOpenSnackBar: (open: boolean) => void;
}

export const MFA: React.FC<MFAProps> = ({
  user,
  setErrorMessage,
  setOpenSnackBar
}) => {
  const { setToken } = useAuthentication();
  const { mutateAsync: mfaAuthenticationMutation } =
    usePartnerGetMfaAuthentication();
  const { mutate: sendMfaTextToEmail } = useSendMfaTextToEmail();
  const { mutate: sendMFATextMutation } = usePartnerSendMfaTextToPhoneNumber();
  const [refetchTimeout, setRefetchTimeout] = useState(user.resendTime || 10);
  const [refetchErrorMsg, setRefetchErrorMsg] = useState('');
  const [invalidCodeErrorMsg, setInvalidCodeErrorMsg] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (user.isEmailMFA) {
      sendMfaTextToEmail(undefined, {
        onError: err => {
          setOpenSnackBar(true);
          setErrorMessage(
            err?.response?.data?.Error
              ? err?.response?.data?.Error
              : 'Failed to send code to email.'
          );
        }
      });
    } else if (user.requireMFA) {
      sendMFATextMutation(undefined, {
        onError: err => {
          setOpenSnackBar(true);
          setErrorMessage(
            err?.response?.data?.Error
              ? err?.response?.data?.Error
              : 'Failed to send code to phone.'
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (refetchTimeout <= 0) return;
    const countdown = setInterval(() => {
      setRefetchTimeout(prevState => prevState - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [refetchTimeout]);
  return (
    <Formik
      initialValues={{
        mfaToken: ''
      }}
      onSubmit={async (values, actions) => {
        setInvalidCodeErrorMsg('');
        try {
          const data = await mfaAuthenticationMutation(
            {
              mfaToken: values.mfaToken.trim()
            },
            {
              onError: err => {
                setInvalidCodeErrorMsg(
                  err?.response?.data?.Error
                    ? err?.response?.data?.Error
                    : 'Code Invalid'
                );
              }
            }
          );
          setToken(data || '');
          if (!user.showMyEncounters && user.showMyTransactions) {
            navigate('/MyTransactionsSummary');
          } else if (user.showMyEncounters) {
            navigate('/MyEncounters');
          }
          // eslint-disable-next-line no-empty
        } catch (err) {
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValidating }) => {
        return (
          <Form
            style={{
              display: 'flex',
              flexFlow: 'column',
              width: '60%'
            }}
          >
            <p
              style={{
                lineHeight: 1.25,
                margin: '0 0 1em 0'
              }}
            >
              {user.codeDisplayMessage || ''}
            </p>
            <DigitsField
              isRequired={true}
              autoFocus={true}
              label="Code"
              name="mfaToken"
              helperText={invalidCodeErrorMsg}
              error={Boolean(invalidCodeErrorMsg)}
              onFieldValueChange={() => {
                setInvalidCodeErrorMsg('');
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '15px 0'
              }}
            >
              <SubmitButton
                loading={isSubmitting || isValidating}
                disabled={isSubmitting || isValidating}
                style={{
                  margin: '0 0 16px 0'
                }}
              >
                Submit
              </SubmitButton>
              <Button
                style={{
                  marginBottom: '12px'
                }}
                variant="outlined"
                disabled={Boolean(refetchTimeout)}
                onClick={() => {
                  if (user.isEmailMFA) {
                    sendMfaTextToEmail(undefined, {
                      onSuccess: () => {
                        setRefetchTimeout(user.resendTime || 10);
                      },
                      onError: err => {
                        setRefetchTimeout(2);
                        setRefetchErrorMsg('Failed to send code');
                        if (err?.response?.data?.Error) {
                          setOpenSnackBar(true);
                          setErrorMessage(err.response.data.Error);
                        }
                      }
                    });
                  } else {
                    sendMFATextMutation(undefined, {
                      onSuccess: () => {
                        setRefetchTimeout(user.resendTime || 10);
                      },
                      onError: err => {
                        setRefetchTimeout(2);
                        setRefetchErrorMsg('Failed to send code');
                        if (err?.response?.data?.Error) {
                          setOpenSnackBar(true);
                          setErrorMessage(err.response.data.Error);
                        }
                      }
                    });
                  }
                }}
              >
                Re-send Code
              </Button>
              {refetchErrorMsg && (
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'red',
                    marginBottom: '12px'
                  }}
                >
                  {refetchErrorMsg}
                </span>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '4px 0 0 0'
                }}
              >
                <FindHelp />
              </div>
              <SecureTrustCertificate
                style={{ alignSelf: 'center', marginTop: '12px' }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
