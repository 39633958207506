import React from 'react';
import moment from 'moment';
import { Page, Font, Text, View } from '@react-pdf/renderer';
import DetailTable from './DetailTable';
import { formatDollar } from './util';
import { styles, boldFont } from './styled';
import { theme } from 'lib/theme';

const hyphenationCallback = word => {
  const middle = 10;
  const parts =
    word.length < 12 ? [word] : [word.substr(0, middle), word.substr(middle)];
  return parts;
};

Font.registerHyphenationCallback(hyphenationCallback);

function PatientStatment({ data }) {
  return (
    <Page size="Letter" style={{ marginBottom: 10 }}>
      <View
        style={[
          styles.section,
          {
            alignItems: 'flex-end',
            borderStyle: 'solid',
            borderBottomWidth: '1',
            borderColor: 'black'
          }
        ]}
      >
        <View style={[styles.view, { flex: 1.5, textAlign: 'left' }]}>
          <Text style={styles.text}>{data.billingProvider}</Text>
          <Text style={styles.text}>{data.billingAddress1}</Text>
          <Text
            style={styles.text}
          >{`${data.billingCity}, ${data.billingState}`}</Text>
          <Text style={styles.text}>{data.billingZipCode}</Text>
        </View>
        <View style={styles.view}>
          <Text
            style={[styles.text, { fontFamily: boldFont, marginBottom: '5px' }]}
          >
            Tax ID
          </Text>
          <Text style={styles.text}>{data.taxID}</Text>
        </View>
        <View style={styles.view}>
          <Text
            style={[styles.text, { fontFamily: boldFont, marginBottom: '5px' }]}
          >
            Account Number
          </Text>
          <Text style={styles.text}>{data.accountNumber}</Text>
        </View>
        <View style={styles.view}>
          <Text
            style={[styles.text, { fontFamily: boldFont, marginBottom: '5px' }]}
          >
            Printed Date
          </Text>
          <Text style={styles.text}>{moment().format('MM/DD/YYYY')}</Text>
        </View>
        <View style={styles.view}>
          <Text
            style={[styles.text, { fontFamily: boldFont, marginBottom: '5px' }]}
          >
            Guarantor Name
          </Text>
          <Text style={styles.text}>{data.guarantorName}</Text>
        </View>
      </View>
      <View
        style={[
          styles.section,
          { backgroundColor: theme.palette.primary.main, color: 'white' }
        ]}
      >
        <Text style={[styles.text, { fontSize: '11px' }]}>Service Details</Text>
      </View>
      {data.serviceDetails.length && (
        <DetailTable
          key={`service-detail-tabled-${data.invoiceNo}`}
          details={data.serviceDetails}
        />
      )}
      <View
        style={[
          styles.section,
          {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'black',
            marginBottom: 4
          }
        ]}
      >
        <Text style={[styles.text, { fontFamily: boldFont }]}>
          Total Balance Due:
        </Text>
        <Text style={[styles.text, { fontFamily: boldFont }]}>
          {formatDollar(data.amountDue)}
        </Text>
      </View>
      <View
        style={[
          styles.section,
          {
            marginTop: 0
          }
        ]}
      >
        <Text style={styles.text}>
          Disclaimer: Pending insurance payments may adjust the patient
          responsibility and the Total Balance Due.
        </Text>
      </View>
    </Page>
  );
}

export default PatientStatment;
