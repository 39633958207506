import { BaseButton, BaseButtonProps } from './BaseButton';
import styled from 'styled-components';

export type SubmitButtonProps = BaseButtonProps;

export const SubmitButton = styled(BaseButton)`
  && {
    color: white;

    &:disabled {
      color: rgba(255, 255, 255, 0.26);
    }
  }
`;
