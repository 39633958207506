import styled from 'styled-components';
import moment from 'moment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useMyTransactionsSummaryContext } from './MyTransactionsSummaryContext';
import { isFuture, parse } from 'date-fns';
import { BrandColor } from 'constants/brandColor';
import { SubmitButton } from 'components';
import { WarningButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import { Help } from '@material-ui/icons';
import { DateField } from 'components';
import ReactInputMask from 'react-input-mask';
import { ProviderAutoCompleteField } from './ProviderAutoCompleteField';
import { useAuthentication } from 'hooks/useAuthentication';
import { LOGINTYPES } from 'constants/login';
import { useEffect, useState } from 'react';

function MyEncountersSearchForm() {
  const {
    facilityOptions,
    facility: selectedFacilities,
    setFacility,
    advancedFilter,
    setAdvancedFilter,
    value1,
    value2,
    setValue1,
    setValue2,
    setSearch,
    isLoading,
    setIsLoading,
    setNotSearched,
    providerSearch,
    setProviderSearch
  } = useMyTransactionsSummaryContext();
  const authContext = useAuthentication();

  const dobInFuture =
    advancedFilter === 'Date Of Birth' &&
    isFuture(parse(value1, 'MM/dd/yyyy', new Date()));

  const disabled =
    isLoading ||
    (selectedFacilities && selectedFacilities.length === 0) ||
    advancedFilter === '-- Select --' ||
    (advancedFilter !== FilterOptions.respProvider && value1 === '') ||
    (advancedFilter === 'Date Of Birth' &&
      moment(value1).format('MM/DD/yyyy') === 'Invalid date') ||
    (advancedFilter === FilterOptions.respProvider && !providerSearch) ||
    dobInFuture;
  const userTypeID = authContext.getUserInfo().externalUserTypeID;
  const [adjustedFilterOptions, setAdjustedFilterOptions] = useState(
    advancedFilterOptions
  );

  useEffect(() => {
    setAdjustedFilterOptions(prev => {
      if (prev.includes(FilterOptions.respProvider)) return prev;
      if (userTypeID === LOGINTYPES.CHART_COORDINATOR)
        return [...prev, FilterOptions.respProvider];
      return prev;
    });
  }, [userTypeID]);

  return (
    <div
      onKeyPress={ev => {
        if (!disabled && ev.key === 'Enter') {
          setIsLoading(true);
          setSearch(true);
          ev.preventDefault();
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            alignItems: 'center'
          }}
        >
          <FacilitiesField required variant="outlined">
            <LabelWithTooltip
              id="facilities"
              label={`Facilities (${selectedFacilities.length}/${facilityOptions.length})`}
              toolTipText="Facilities assigned to a user"
            />
            <Select
              id="myTransactions-facilities"
              style={{ backgroundColor: 'white' }}
              MenuProps={{ autoFocus: false }}
              data-cy="facilities"
              labelId="facilities"
              multiple
              value={selectedFacilities}
              onChange={e => {
                setFacility(e.target.value);
                if (e.target.value.includes('Select all')) {
                  setFacility(
                    selectedFacilities.length === facilityOptions.length
                      ? []
                      : facilityOptions
                  );
                }
              }}
              input={<OutlinedInput />}
              renderValue={selected =>
                selected.map(el => `${el.value} ${el.label}`).join(', ')
              }
            >
              <MenuItem value="Select all">
                <StyledCheckbox
                  checked={selectedFacilities.length === facilityOptions.length}
                />
                <ListItemText primary={'Select All'} />
              </MenuItem>

              {facilityOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option}
                  data-cy="facilities-options"
                >
                  <StyledCheckbox
                    checked={
                      selectedFacilities
                        .map(el => el.value)
                        .indexOf(option.value) > -1
                    }
                  />
                  <ListItemText primary={`${option.value} ${option.label}`} />
                </MenuItem>
              ))}
            </Select>
          </FacilitiesField>

          <FormControl required variant="outlined" style={{ width: '220px' }}>
            <LabelWithTooltip
              id="advanced-filter"
              label="Search Type"
              toolTipText="Ways a user can search"
            />
            <Select
              id="myTransactions-searchType"
              style={{ backgroundColor: 'white' }}
              data-cy="advanced-filter"
              labelId="advanced-filter"
              value={advancedFilter}
              onChange={e => {
                if (e === '') {
                  setValue1('');
                  setValue2('');
                }
                setAdvancedFilter(e.target.value);
                if (e.target.value === 'Date Of Service Range') {
                  const val1 = moment()
                    .subtract(3, 'months')
                    .format('MM/DD/yyyy');
                  const val2 = moment().format('MM/DD/yyyy');
                  setValue1(val1);
                  setValue2(val2);
                } else {
                  setValue1('');
                  setValue2('');
                }
                if (
                  e.target.value !== FilterOptions.respProvider &&
                  !!providerSearch
                ) {
                  setProviderSearch(null);
                }
              }}
              input={<OutlinedInput />}
            >
              {adjustedFilterOptions.map(value => (
                <MenuItem
                  key={value}
                  value={value}
                  style={{ height: '36px' }}
                  data-cy="advanced-filter-options"
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!advancedFilter &&
            advancedFilter !== '-- Select --' &&
            advancedFilter !== FilterOptions.dosRange &&
            advancedFilter !== FilterOptions.dob &&
            advancedFilter !== FilterOptions.respProvider && (
              <TextField
                id="myTransactions-value"
                variant="outlined"
                style={{
                  backgroundColor: 'white'
                }}
                data-cy="value1"
                required
                label="Value"
                value={value1}
                onChange={e => setValue1(e.target.value)}
              />
            )}

          {!!advancedFilter && advancedFilter === FilterOptions.dob && (
            <ReactInputMask
              id="myTransactions-date"
              alwaysShowMask
              mask="99/99/9999"
              value={value1}
              onChange={e => setValue1(e.target.value)}
            >
              <TextField
                variant="outlined"
                required
                error={dobInFuture}
                helperText={dobInFuture && 'Date of birth cannot be in future'}
                label="Date"
                InputProps={{
                  style: {
                    backgroundColor: 'white'
                  }
                }}
                inputlabelprops={{
                  shrink: true
                }}
              />
            </ReactInputMask>
          )}

          {/* DOS RANGE INPUTS________ */}
          {!!advancedFilter && advancedFilter === FilterOptions.dosRange && (
            <>
              <DateField
                value={value1}
                setValue={setValue1}
                label="Start"
                id="myTransactions-startDate"
              />
              <DateField
                value={value2}
                setValue={setValue2}
                label="End"
                id="myTransactions-endDate"
              />
            </>
          )}

          {!!advancedFilter &&
            advancedFilter === FilterOptions.respProvider && (
              <ProviderAutoCompleteField />
            )}
        </div>

        <div style={{ display: 'flex' }}>
          <SubmitButton
            id="myTransactions-search"
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', width: '100px' }}
            disabled={disabled}
            onClick={() => {
              setIsLoading(true);
              setSearch(true);
            }}
          >
            Search
          </SubmitButton>
          <WarningButton
            variant="contained"
            id="myTransactions-clear"
            style={{ marginLeft: '10px', marginTop: '10px', width: '90px' }}
            disabled={isLoading}
            onClick={() => {
              setFacility([]);
              setAdvancedFilter('-- Select --');
              setValue1('');
              setValue2('');
              setNotSearched(true);
              setProviderSearch(null);
            }}
          >
            Clear
          </WarningButton>
        </div>
      </div>
    </div>
  );
}

export default MyEncountersSearchForm;

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${BrandColor.ACCENT} !important;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  pointer-events: auto;
  &.MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
  }
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: linear-gradient(#f5f5f5 50%, white 50%);
    padding: 0 5px;
    display: flex;
    align-items: center;
    transform: translate(14px, -8px) scale(0.75);
  }
`;
const LabelWithTooltip = ({ id, label, toolTipText }) => (
  <StyledInputLabel id={id}>
    {label}
    <Tooltip
      arrow
      title={toolTipText}
      placement="top"
      style={{ paddingLeft: '4px', order: 999 }}
    >
      <Help fontSize="small" />
    </Tooltip>
  </StyledInputLabel>
);
const FacilitiesField = styled(FormControl)`
  width: 285px;
`;

export const FilterOptions = {
  dosRange: 'Date Of Service Range',
  accNumber: 'Account Number',
  patientLName: 'Patient Last Name',
  dob: 'Date Of Birth',
  invoiceNum: 'Invoice Number',
  statementAcc: 'Statement Account',
  respProvider: 'Provider'
};
const advancedFilterOptions = [
  '-- Select --',
  FilterOptions.dosRange,
  FilterOptions.accNumber,
  FilterOptions.patientLName,
  FilterOptions.dob,
  FilterOptions.invoiceNum,
  FilterOptions.statementAcc
];
