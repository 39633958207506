import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styled from 'styled-components';

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
`;
const StyledSearch = styled(OutlinedInput)`
  background: white;
`;

const GlobalFilter = ({ globalFilter, setGlobalFilter, gotoPage }) => (
  <StyledSearch
    value={globalFilter || ''}
    margin="dense"
    onChange={e => {
      setGlobalFilter(e.target.value ? e.target.value.trimLeft() : undefined);
      if (gotoPage) gotoPage(0);
    }}
    placeholder="Filter ..."
    startAdornment={<SearchIcon />}
    endAdornment={
      <StyledClearIcon onClick={() => setGlobalFilter(undefined)} />
    }
    inputProps={{ 'aria-label': 'search' }}
  />
);

export default GlobalFilter;
