import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ventraLogo from '../assets/ventra-logo-light.png';
import { theme } from 'lib/theme';
import { useAuthentication } from 'hooks/useAuthentication';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { BrandColor } from 'constants/brandColor';
import { Box } from '@material-ui/core';

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${BrandColor.ACCENT}; /* ventra orange */
  }
  margin-left: 25px;
  margin-right: 25px;
  flex: 1 0 auto;
`;

const Img = styled.img`
  width: 250px;
`;
export interface AppbarProps {
  style?: CSSProperties; // Need to be able to override the display:none when there are no routes
}

function Appbar(props: AppbarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getUserInfo, logoutNavigate, isLoggedIn } = useAuthentication();
  const user = getUserInfo();
  const [routes, setRoutes] = useState<string[]>([]);

  useEffect(() => {
    const routes = [];
    if (user?.token) {
      if (user.showMyEncounters) {
        routes.push('/MyEncounters');
      }
      if (user.showMyTransactions) {
        routes.push('/MyTransactionsSummary');
      }
      if (user.showMyAccount) {
        routes.push('/MyAccount');
      }
      setRoutes(routes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token]);
  const value = routes.indexOf(location.pathname);

  const showAppBar =
    value >= 0 ||
    location.pathname === '/ForgotPassword' ||
    location.pathname === '/ResetPassword';

  const displayTabs = !(
    location.pathname === '/ForgotPassword' ||
    location.pathname === '/ResetPassword'
  ); // Don't display tabs when on the forgot password page
  const loginButtonOverride =
    location.pathname === '/ForgotPassword' ||
    location.pathname === '/ResetPassword'; // Force displaying login rather than logout, even if user has valid token

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: theme.palette.primary.dark,
        display: showAppBar ? 'flex' : 'none',
        padding: '1em',
        ...props.style
      }}
    >
      <Toolbar style={{ paddingLeft: '0px' }}>
        <Img src={ventraLogo} />
        <Typography variant="h4" style={{ fontWeight: 800, marginLeft: 50 }}>
          Partner Portal
        </Typography>
        {displayTabs && (
          <StyledTabs value={value || 0}>
            {/* <Tab label="My Dashboard" disabled/> */}
            {user && user.showMyEncounters && (
              <Tab
                label="My Encounters"
                onClick={() => navigate('/MyEncounters')}
              />
            )}
            {user && user.showMyTransactions && (
              <Tab
                label="My Transactions"
                onClick={() => navigate('/MyTransactionsSummary')}
              />
            )}
            {user && user.showMyAccount && (
              <Tab label="My Account" onClick={() => navigate('/MyAccount')} />
            )}
            {/* <Tab label="My Users" disabled/> */}
          </StyledTabs>
        )}
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" flexDirection="row">
          {isLoggedIn() && !loginButtonOverride ? (
            <div style={{ display: 'flex' }}>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'right',
                  paddingRight: '15px',
                  fontSize: '1rem',
                  paddingTop: '7px'
                }}
              >
                {!!user &&
                  !!user.externalUsersName &&
                  `Logged in as: ${user.externalUsersName}`}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={() => logoutNavigate(navigate)}
              >
                Log Out
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
              >
                Login
              </Button>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
