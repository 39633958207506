import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FormTextField, SubmitButton } from '../../components';
import { VerticalTabs, TabPanel } from '../../components/VerticalTabs';
import { UpdatePhoneForm } from './UpdatePhoneForm';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import SessionTimeout from 'components/SessionTimeout';
import { useAuthentication } from 'hooks/useAuthentication';
import { Tab } from '@material-ui/core';
import { ErrorResponse } from 'types/network';

const Layout = styled.div`
  max-width: 2048px;
  margin: 0 auto;
  padding: 0 30px;
`;

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      style={{ zIndex: 1500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const URL = `${process.env.REACT_APP_URL}/?route=PartnerUpSertExternalUserPasswordReset`;
//
function MyAccount() {
  const navigate = useNavigate();
  const { getDecodeToken, getUserInfo } = useAuthentication();
  const decodedToken = getDecodeToken();
  const isMFA = Boolean(Number(decodedToken.mfa));
  const user = getUserInfo();
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const [tabIndex, setTabIndex] = React.useState(0);
  const snackbarSuccess = (message: string) => {
    setIsSuccessful(true);
    setOpenSnackBar(true);
    setStatusMessage(message);
  };
  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    setIsSuccessful(false);
    setStatusMessage('');
  };
  const MyAccountSchema = Yup.object().shape({
    email: Yup.string()
      .oneOf([user.email, null], "Email doesn't match")
      .required('Required'),
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain more than 8 characters including: one uppercase, one lowercase, one number and one special case character.'
      )
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required')
  });
  if (user.usesSingleSignOnDisallowsPortalPasswordReset) {
    navigate('/MyEncounters');
  }
  return (
    <Layout>
      <SessionTimeout />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
      >
        {openSnackbar ? (
          <Alert
            onClose={handleCloseSnackBar}
            severity={isSuccessful ? 'success' : 'error'}
          >
            {statusMessage}
          </Alert>
        ) : undefined}
      </Snackbar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1 style={{ marginRight: '30px' }}>My Account</h1>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          style={{ fontSize: '16px' }}
          href={`https://mgablobstorageprodcdn.blob.core.windows.net/${(
            process.env.REACT_APP_NODE_ENV || ''
          ).toLowerCase()}-cdn/Partner.Gottlieb.com.UserGuide.MyAccount.pdf?id=${Math.random()
            .toString(36)
            .slice(2)}`}
          target="_blank"
        >
          User Guide
        </a>
      </div>
      <div
        style={{
          display: 'flex'
        }}
      >
        <VerticalTabs
          value={tabIndex}
          onChange={(_: unknown, newTabIndex: number) => {
            setTabIndex(newTabIndex);
          }}
        >
          <Tab icon={<LockIcon />} label="Password" />
          {isMFA ? <Tab icon={<PhoneIcon />} label="Phone" /> : null}
        </VerticalTabs>
        <TabPanel
          value={tabIndex}
          index={0}
          style={{
            margin: '0 auto',
            padding: '0 140px 0 0'
          }}
        >
          <Formik
            onSubmit={async (values, { resetForm }) => {
              const { newPassword, currentPassword } = values;
              const config = {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
                }
              };
              try {
                await axios.post(
                  URL,
                  {
                    userName: user.email,
                    password: newPassword,
                    oldPassword: currentPassword
                  },
                  config
                );
                setIsSuccessful(true);
                setStatusMessage('Password has been updated.');
                setOpenSnackBar(true);
                resetForm();
              } catch (err) {
                setIsSuccessful(false);
                setStatusMessage(
                  (err as ErrorResponse).response?.data?.Error || ''
                );
                setOpenSnackBar(true);
                resetForm();
              }
            }}
            initialValues={{
              email: '',
              currentPassword: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validationSchema={MyAccountSchema}
            render={({ isValidating, isSubmitting }) => {
              return (
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '300px',
                    marginTop: '15px'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ textAlign: 'center' }}>Update Password</h2>
                    <FormTextField
                      style={{ height: '76px' }}
                      idx="myAccount-email"
                      name="email"
                      label="Email"
                      isRequired={true}
                    />
                    <FormTextField
                      style={{ height: '76px' }}
                      idx="myAccount-current-password"
                      name="currentPassword"
                      label="Current Password"
                      isRequired={true}
                      type="password"
                      autoComplete="new-password"
                    />
                    <FormTextField
                      style={{ minHeight: '76px' }}
                      idx="myAccount-new-password"
                      name="newPassword"
                      label="New Password"
                      isRequired={true}
                      type="password"
                    />
                    <FormTextField
                      style={{ height: '76px' }}
                      idx="myAccount-confirm-password"
                      name="confirmPassword"
                      label="Confirm Password"
                      isRequired={true}
                      type="password"
                    />
                    <SubmitButton
                      id="myAccount-submit"
                      loading={!!(isValidating || isSubmitting)}
                      disabled={!!(isValidating || isSubmitting)}
                    >
                      Submit
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={1}
          style={{
            margin: '0 auto',
            paddingRight: '140px'
          }}
        >
          {isMFA ? <UpdatePhoneForm snackbarSuccess={snackbarSuccess} /> : null}
        </TabPanel>
      </div>
    </Layout>
  );
}

export default MyAccount;
