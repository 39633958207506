/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Navigate } from 'react-router-dom';
import MyEncountersSearchContext from './MyEncountersSearchContext';
import MyEncountersSearchForm from './MyEncountersSearchForm';
import Comments from 'components/Comments/Comments';
import VoidDialog from './VoidDialog';
import MyEncountersTable from './MuiEnhancedTable';
import { ROUTES } from 'constants/routes';
import SessionTimeout from 'components/SessionTimeout';
import { ExceptionsDialog } from 'components/ExceptionsDialog/ExceptionsDialog';
import { useAuthentication } from 'hooks/useAuthentication';
import { ErrorResponse } from 'types/network';
import { ActionOwnerType } from 'types/EncounterSearch';
import { Divider } from '@material-ui/core';
import { theme } from 'lib/theme';

const MyEncounters = () => {
  const [encounters, setEncounters] = useState<any>([]);
  const [facilityOptions, setFacilityOptions] = useState<any>([]);
  const [facility, setFacility] = React.useState([]);
  const [status, setStatus] = React.useState('On Hold');
  const [statusList, setStatusList] = React.useState([]);
  const [advancedFilter, setAdvancedFilter] = React.useState('-- Select --');
  const [value1, setValue1] = React.useState('');
  const [value2, setValue2] = React.useState('');
  const [openComment, setOpenComment] = React.useState(null);
  const [openCommentDOS, setOpenCommentDOS] = React.useState<any>(null);
  const [comments, setComments] = React.useState<any>([]);
  const [search, setSearch] = React.useState(false);
  const [notSearched, setNotSearched] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentStatus, setCurrentStatus] = React.useState(status);
  const [submitComment, setSubmitComment] = React.useState(false);
  const [newComment, setNewComment] = React.useState('');
  const [refetch, setRefetch] = React.useState(false);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [selectedPreset, setSelectedPreset] = React.useState<any>({});
  const [openVoidDialog, setOpenVoidDialog] = React.useState<any>(null);
  const [openExceptions, setOpenExceptions] = React.useState<any>(null);
  const [optionalComment, setOptionalComment] = React.useState('');
  const [selectedVoidReason, setSelectedVoidReason] = React.useState<any>(null);
  const [saveVoid, setSaveVoid] = React.useState(false);
  const [voidReasonHRZN, setVoidReasonsHRZN] = React.useState([]);
  const [voidReasonsIBIS, setVoidReasonsIBIS] = React.useState([]);
  const [actionOwner, setActionOwner] = React.useState<ActionOwnerType>('All');
  const { getUserInfo } = useAuthentication();
  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  useEffect(() => {
    const fetchOptions = async () => {
      const user = getUserInfo();
      const facilityIDs = user.facilities.map(
        ({
          facilityID,
          facilityName
        }: {
          facilityID: unknown;
          facilityName: unknown;
        }) => ({
          value: facilityID,
          label: facilityName
        })
      );
      const header = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
        }
      };
      const statusList = await axios.post(
        `${process.env.REACT_APP_URL}/?route=${ROUTES.PartnerGetStatusList}`,
        facilityIDs.map((x: { value: unknown }) => x.value),
        header
      );
      const voidReasons_IBIS = await axios.get(
        `${process.env.REACT_APP_URL}/4/?route=${ROUTES.PartnerGetEncounterHeaderVoidReasonList}`,
        header
      );
      const voidReasons_HRZN = await axios.get(
        `${process.env.REACT_APP_URL}/1/?route=${ROUTES.PartnerGetEncounterHeaderVoidReasonList}`,
        header
      );
      setVoidReasonsHRZN(voidReasons_HRZN.data);
      setVoidReasonsIBIS(voidReasons_IBIS.data);
      setStatusList(
        statusList.data.map((el: { description: string }) => el.description)
      );
      setFacilityOptions(facilityIDs);
    };
    fetchOptions();
  }, [getUserInfo]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        facilityIds: facility.map((el: { value: unknown }) => el.value),
        statusFilter: mapStatusFilter(status),
        key: mapAdvancedFilter(advancedFilter),
        value1: value1
          ? advancedFilter === 'Date Of Service Range'
            ? moment(value1).format('MM-DD-YYYY')
            : value1
          : '',
        value2: value2
          ? advancedFilter === 'Date Of Service Range'
            ? moment(value2).format('MM-DD-YYYY')
            : value2
          : '',
        actionOwner: mapActionOwner(actionOwner)
      };
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
        }
      };
      const URL = `${process.env.REACT_APP_URL}/?route=PartnerGetMyEncounterMasterView`;
      const isFetching = search || refetch;
      let result = encounters;
      try {
        result = isFetching ? await axios.post(URL, payload, config) : result;
        if (isFetching) {
          setOpenSnackBar(false);
          setMessage('');
        }
      } catch (error) {
        const errorMsg = (error as ErrorResponse)?.response?.data?.Error;
        if (errorMsg) {
          setOpenSnackBar(true);
        }
        setMessage(errorMsg ? errorMsg : message);
      }

      setEncounters(isFetching ? result?.data || [] : result);
      setCurrentStatus(isFetching ? status : currentStatus);
      setNotSearched(isFetching ? false : notSearched);
      setSearch(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, refetch]);

  useEffect(() => {
    setIsLoading(false);
  }, [encounters]);

  useEffect(() => {
    const fetchComments = async (payload: any) => {
      const triggerFetch =
        (!!payload && refetch) ||
        (comments.length === 0 &&
          !!payload &&
          Object.keys(payload).length === 3);
      const result = triggerFetch
        ? await axios.post(
            `${process.env.REACT_APP_URL}/?route=${ROUTES.PartnerGetMyEncounterComments}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
              }
            }
          )
        : comments;
      setComments(triggerFetch ? result.data : comments);
      setRefetch(false);
    };
    fetchComments(openComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openComment, refetch]);

  useEffect(() => {
    const voidRecord = async () => {
      if (saveVoid) {
        const payload = {
          encounterHeaderIdentifier: openVoidDialog,
          encounterHeaderVoidReasonID: selectedVoidReason
            ? selectedVoidReason.encounterHeaderVoidReasonID
            : '',
          comment: optionalComment
        };
        try {
          await axios.post(
            `${process.env.REACT_APP_URL}/?route=${ROUTES.PartnerUpSertEncounterHeaderStatusVoidUpdate}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
              }
            }
          );
          setIsSuccess(true);
          setMessage('Record voided.');
          setOptionalComment('');
          setSelectedVoidReason(null);
          setOpenVoidDialog(null);
          setRefetch(true);
        } catch (err: AxiosError | unknown) {
          const errMsg = axios.isAxiosError(err)
            ? err?.response?.data?.Error
            : 'There is an error.';
          setIsSuccess(false);
          setMessage(errMsg);
        } finally {
          setOpenSnackBar(true);
          setSaveVoid(false);
        }
      }
    };
    voidRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveVoid]);

  useEffect(() => {
    const saveComments = async () => {
      const user = getUserInfo();
      const payload = {
        encounterHeaderIDList: [openComment],
        encounterHeaderCommentTypeID:
          selectedPreset.encounterHeaderCommentTypeID,
        externalUserID: user && user.externalUserID, // populate
        internalUserID: 0,
        isPrivate: false,
        internalUserName: '',
        externalUserName: user && user.externalUserName, // populate
        comment: (newComment || '').trim()
          ? `${
              selectedPreset?.encounterHeaderCommentType
            }: ${newComment.trim()}`
          : selectedPreset.encounterHeaderCommentType, // populate
        createdDateTime: new Date(),
        isInternalUser: false
      };
      let hasErr = true;
      let errRes: AxiosError | undefined;
      try {
        if (submitComment) {
          await axios.post(
            `${process.env.REACT_APP_URL}/?route=${ROUTES.PartnerUpSertEncounterComment}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
              }
            }
          );
        }
        hasErr = false;
        // eslint-disable-next-line no-empty
      } catch (err: AxiosError | unknown) {
        if (axios.isAxiosError(err)) errRes = err;
      }
      setIsSuccess(submitComment ? !hasErr : isSuccess);
      setMessage(
        submitComment
          ? hasErr
            ? errRes
              ? (errRes.response?.data['Error'] as string)
              : 'There is an error.'
            : 'Comment saved.'
          : message
      );
      setOpenSnackBar(submitComment ? true : openSnackbar);
      setNewComment(submitComment ? '' : newComment);
      setRefetch(submitComment ? true : refetch);
      setSubmitComment(false);
      setSelectedPreset({});
    };
    saveComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitComment]);
  const user = getUserInfo();

  if (!user.showMyEncounters) {
    return <Navigate to="/MyTransactionsSummary" />;
  }

  return (
    <Layout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          <h1 style={{ marginRight: '30px' }}>My Encounters</h1>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: theme.palette.primary.main
            }}
          >
            Please select Search Type criteria and click Search
          </p>
        </div>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          style={{ fontSize: '16px' }}
          href={`https://mgablobstorageprodcdn.blob.core.windows.net/${(
            process.env.REACT_APP_NODE_ENV || ''
          ).toLowerCase()}-cdn/Partner.Gottlieb.com.UserGuide.MyEncounters.pdf?id=${Math.random()
            .toString(36)
            .slice(2)}`}
          target="_blank"
        >
          User Guide
        </a>
      </div>
      <MyEncountersSearchContext.Provider
        value={
          {
            facilityOptions,
            setFacilityOptions,
            facility,
            setFacility,
            status,
            setStatus,
            statusList,
            advancedFilter,
            setAdvancedFilter,
            value1,
            setValue1,
            value2,
            setValue2,
            search,
            setSearch,
            isLoading,
            setIsLoading,
            setNotSearched,
            refetch,
            setRefetch,
            actionOwner,
            setActionOwner
          } as any
        }
      >
        <MyEncountersSearchForm />
        <Divider
          variant="middle"
          style={{ marginTop: '2em', marginBottom: '1em' }}
        />
      </MyEncountersSearchContext.Provider>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px'
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: notSearched ? 'none' : 'block' }}>
          <MyEncountersSearchContext.Provider
            value={
              {
                refetch,
                setRefetch,
                setEncounters,
                status
              } as any
            }
          >
            <MyEncountersTable
              status={currentStatus}
              tableData={
                encounters?.map((encounter: any) => {
                  return {
                    ...encounter,
                    dateOfService: encounter.dateOfService
                      ? moment(encounter.dateOfService).format('MM/DD/YY')
                      : '',
                    dateHeld: encounter.dateHeld
                      ? moment(encounter.dateHeld).format('MM/DD/YY')
                      : '',
                    nextCheckDate: encounter.nextCheckDate
                      ? moment(encounter.nextCheckDate).format('MM/DD/YY')
                      : '',
                    patientDOB: encounter.patientDOB
                      ? moment(encounter.patientDOB).format('MM/DD/YYYY')
                      : ''
                  };
                }) || []
              }
              handleComments={(rowId: any, dateOfService: any) => {
                setOpenComment(rowId);
                setOpenCommentDOS(moment(dateOfService).format('MM/DD/YYYY'));
              }}
              handleVoid={(rowId: any) => {
                setOpenVoidDialog(rowId);
              }}
              handleOpenExceptions={(rowId: any) => setOpenExceptions(rowId)}
              openRecord={openComment || openVoidDialog || openExceptions}
              notSearched={notSearched}
              refetch={() => setRefetch(true)}
            />
            <Comments
              outsidePreset={selectedPreset}
              show={!!openComment}
              comments={comments}
              dateOfService={openCommentDOS}
              isSubmitting={submitComment}
              onSubmit={() => setSubmitComment(true)}
              newComment={newComment}
              onChange={(e: { target: { value: string } }) =>
                setNewComment(e.target.value)
              }
              onChangePreset={(value: any) => {
                if (value.encounterHeaderCommentTypeID) {
                  setSelectedPreset(value);
                }
              }}
              onClose={() => {
                setOpenComment(null);
                setRefetch(false);
                setComments([]);
              }}
            />
            {!!openExceptions && (
              <ExceptionsDialog
                open={!!openExceptions}
                handleClose={() => setOpenExceptions(null)}
                encounterHeaderId={openExceptions.encounterHeaderId}
                facilityId={openExceptions.facilityID}
              />
            )}
            <VoidDialog
              show={!!openVoidDialog}
              voidReasons={
                openVoidDialog
                  ? openVoidDialog.applicationID === 1
                    ? voidReasonHRZN
                    : voidReasonsIBIS
                  : []
              }
              isIBIS={!openVoidDialog || openVoidDialog.applicationID === 4}
              selectedVoidReason={selectedVoidReason}
              handleVoidReasonChange={(e: { target: { value: unknown } }) =>
                setSelectedVoidReason(e.target.value)
              }
              optionalComment={optionalComment}
              handleOptionalCommentChange={(e: { target: { value: string } }) =>
                setOptionalComment(e.target.value)
              }
              onSave={() => setSaveVoid(true)}
              onClose={() => {
                setOpenVoidDialog(null);
                setOptionalComment('');
                setSelectedVoidReason(null);
                setRefetch(false);
              }}
            />
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackBar}
            >
              {openSnackbar ? (
                <Alert
                  onClose={handleCloseSnackBar}
                  severity={isSuccess ? 'success' : 'error'}
                >
                  {message}
                </Alert>
              ) : undefined}
            </Snackbar>
          </MyEncountersSearchContext.Provider>
        </div>
      )}
      <SessionTimeout />
    </Layout>
  );
};

const mapAdvancedFilter = (advancedFilter: string) => {
  switch (advancedFilter) {
    case 'Date Of Birth':
      return 'PatientDOB';
    case 'Patient Last Name':
      return 'LastName';
    case 'Account Number':
      return 'FacilityAccountNumber';
    case 'Date Of Service Range':
      return 'DateOfServiceRange';
    // case 'Responsible Provider':
    //   return 'ResponsibleProvider'
    default:
      return '';
  }
};

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      style={{ zIndex: 1500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const Layout = styled.div`
  max-width: 2048px;
  margin: 0 auto;
  padding: 0 30px;
`;

const mapActionOwner = (actionOwner: string): number => {
  actionOwner = actionOwner.toLowerCase();
  switch (actionOwner) {
    case 'all':
      return 0;
    case 'client':
      return 1;
    case 'ventra':
      return 2;
    default:
      return -1;
  }
};

const mapStatusFilter = (statusFilter: string): string => {
  switch (statusFilter) {
    case 'On Hold':
      return 'OnHold';
    case 'Pre-Coding Authorization':
      return 'PreCodingAuthorization';
    default:
      return statusFilter;
  }
};

export default MyEncounters;
