import React, { useRef } from 'react';
import styled from 'styled-components';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Typography from '@material-ui/core/Typography';

const PaperComponent: React.FC<PaperProps> = props => {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  cursor: move;
  display: flex;
  justify-content: space-between;

  && {
    padding-bottom: 0;
  }
`;

const StyledDialog = styled(Dialog)<{ $alignTop: boolean }>`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
  }

  .MuiDialog-scrollPaper {
    ${props => props.$alignTop && 'align-items: flex-start'}
  }
`;

const TitleActions = styled.span`
  display: flex;
  gap: 15px;

  > * {
    cursor: pointer;
  }
`;

export interface DraggableDialogProps extends DialogProps {
  id: string;
  title: string;
  alignTop?: boolean;
  actions?: React.ReactNode;
}

export const DraggableDialog: React.FC<DraggableDialogProps> = ({
  children,
  id,
  title,
  actions,
  alignTop = false,
  ...props
}) => (
  <StyledDialog
    PaperComponent={PaperComponent}
    aria-labelledby={`${id}-title`}
    $alignTop={alignTop}
    {...props}
  >
    <StyledDialogTitle disableTypography>
      <Typography id={`${id}-title`} variant="h5" component="h2">
        {title}
      </Typography>
      {actions && <TitleActions>{actions}</TitleActions>}
    </StyledDialogTitle>
    {children}
  </StyledDialog>
);
