import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import { requiredValidator } from 'sd-formik-validators';

function FormTextField({
  idx = '',
  isRequired,
  maxCharacters = 500,
  name,
  onChange = undefined,
  label = '',
  alphaOnly = false,
  ...props
}) {
  const [field, meta, helpers] = useField({
    name,
    validate: isRequired ? requiredValidator : undefined
  });

  return (
    <TextField
      id={idx}
      {...field}
      value={(field && field.value) || ''}
      onChange={e => {
        if (alphaOnly)
          e.target.value = e.target.value.toUpperCase().replace(/[^A-Z]/gi, '');
        if (maxCharacters && e.target.value.length > maxCharacters) {
          return;
        } else if (onChange) {
          onChange(e, {
            field,
            meta,
            helpers
          });
        } else {
          field.onChange(e);
        }
      }}
      variant="outlined"
      margin="none"
      error={!!(meta.touched && meta.error)}
      helperText={!!(meta.touched && meta.error) ? meta.error : ''}
      InputLabelProps={{
        style: {
          height: 2
        }
      }}
      inputProps={{
        style: {
          height: 2,
          padding: '22px 16px 26px 16px',
          backgroundColor: 'white',
          borderRadius: '5px'
        }
      }}
      autoComplete="off"
      label={isRequired && label ? `*${label}` : label}
      style={{
        ...props.style,
        marginBottom: !!(meta.touched && meta.error)
          ? '4px'
          : (!!props && !!props.style && props.style.marginBottom) || ''
      }}
      {...props}
    />
  );
}

export default FormTextField;
