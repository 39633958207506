import React from 'react';
import { Document } from '@react-pdf/renderer';
import PatientStatement from './PatientStatement';
import MailingInfo from './MailingInfo';

function PDF({ data }) {
  if (!data || !data.serviceDetails.length) return 'No Data';
  return (
    <Document>
      <PatientStatement data={data} />
      <MailingInfo data={data} />
    </Document>
  );
}

export default PDF;
