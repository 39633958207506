import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
  CircularProgress,
  Box
} from '@material-ui/core';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { ROUTES } from 'constants/routes';
import { useEffect } from 'react';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useMyTransactionsSummaryContext } from './MyTransactionsSummaryContext';
import _ from 'lodash';

export function ProviderAutoCompleteField() {
  const {
    data: providers,
    mutate: getProviders,
    isLoading
  } = useProviderSearch();
  const {
    facility: selectedFacilities,
    setProviderSearch,
    providerSearch,
    setValue1
  } = useMyTransactionsSummaryContext();

  useEffect(() => {
    if (!selectedFacilities) return;
    getProviders(selectedFacilities.map(facility => facility.value));
  }, [selectedFacilities, getProviders]);

  return (
    <FormControl
      variant="outlined"
      style={{ width: 220 }}
      disabled={isLoading}
      required
    >
      <StyledInputLabel>Provider</StyledInputLabel>
      <Select
        id="provider-field"
        data-cy="provider-field"
        labelId="provider-field"
        style={{ backgroundColor: 'white' }}
        input={<OutlinedInput />}
        defaultValue={'default'}
        value={providerSearch?.providerAK}
        endAdornment={
          isLoading ? (
            <Box sx={{ display: 'flex', mr: 2 }}>
              <CircularProgress size={'1.2rem'} />
            </Box>
          ) : null
        }
        onChange={e => {
          setValue1('');
          setProviderSearch(
            _.pick(
              providers.find(item => item.providerAK === e.target.value),
              ['providerAK', 'srcSystemSK']
            )
          );
        }}
      >
        <MenuItem
          value={'default'}
          style={{ height: '36px' }}
          data-cy={'option-id-0'}
          disabled
        >
          --Select Provider--
        </MenuItem>
        {providers
          ?.sort((a, b) => a.providerFormattedName - b.providerFormattedName)
          .map(option => {
            return (
              <MenuItem
                key={option.providerAK}
                value={option.providerAK}
                style={{ height: '36px' }}
                data-cy={`option-id-${option.id}`}
              >
                {option.providerFormattedName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

/*
{
    "facilityAK": 20,
    "providerFormattedName": "GARRISON MD, G",
    "providerAK": 1,
    "srcSystemSK": 1
}
*/

const useProviderSearch = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation(ROUTES.PartnerGetProviderList, async payload => {
    if (!payload) return [];
    let data = [];
    try {
      const result = (
        await Axios({
          method: 'post',
          data: {
            facilityIds: payload
          },
          url: `/?route=${ROUTES.PartnerGetProviderList}`
        })
      ).data;
      if (result && result?.length) data = result;
    } catch (error) {
      console.log(error);
    }
    return data;
  });
};

const StyledInputLabel = styled(InputLabel)`
  pointer-events: auto;
  &.MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
  }
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: linear-gradient(#f5f5f5 50%, white 50%);
    padding: 0 5px;
    display: flex;
    align-items: center;
    transform: translate(14px, -8px) scale(0.75);
  }
`;
