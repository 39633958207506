import React from 'react';
import { useField } from 'formik';
import { composeValidators, requiredValidator } from 'sd-formik-validators';
import { StyledTextField } from './StyledTextField';
const DigitsField = ({
  isRequired = false,
  name,
  label,
  validate = [],
  helperText = '',
  error = false,
  onFieldValueChange,
  ...props
}) => {
  const [field, meta] = useField({
    name,
    validate: isRequired
      ? composeValidators(requiredValidator, ...validate)
      : composeValidators(...validate)
  });
  return (
    <StyledTextField
      {...field}
      onChange={e => {
        if (/^[0-9]*$/.test(e.target.value)) {
          field.onChange(e);
          if (onFieldValueChange) {
            onFieldValueChange(e);
          }
        }
      }}
      label={isRequired && label ? `*${label}` : label}
      error={!!(meta.touched && meta.error) || error}
      helperText={!!(meta.touched && meta.error) ? meta.error : helperText}
      {...props}
      style={{
        ...props.style,
        marginBottom: !!(meta.touched && meta.error)
          ? '4px'
          : (!!props && !!props.style && props.style.marginBottom) || ''
      }}
    />
  );
};
export default DigitsField;
