import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Navigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FormTextField, SubmitButton } from '../../components';
import { ErrorResponse } from 'types/network';
import { Paper, Typography } from '@material-ui/core';

const Layout = styled.div`
  max-width: 2048px;
  margin: 0 auto;
  padding: 0 30px;
`;

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      style={{ zIndex: 1500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const URL = `${process.env.REACT_APP_URL}/?route=PartnerUpSertExternalUserPasswordResetRequestFulfillment`;

function ResetPassword() {
  const query = useQuery();
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    setIsSuccessful(false);
    setStatusMessage('');
  };
  const MyAccountSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    newPassword: Yup.string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain more than 8 characters including: one uppercase, one lowercase, one number and one special case character.'
      )
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required')
  });

  function _checkIfNeedRedirect() {
    const redirectURL = query.get('forwardUrl');
    if (!redirectURL) return;
    window.location.href = redirectURL;
  }

  const token = query.get('token');
  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        {openSnackbar ? (
          <Alert
            onClose={handleCloseSnackBar}
            severity={isSuccessful ? 'success' : 'error'}
          >
            {statusMessage}
          </Alert>
        ) : undefined}
      </Snackbar>
      <Paper
        elevation={2}
        style={{
          maxWidth: '400px',
          margin: 'auto',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          padding: '40px'
        }}
      >
        <Typography variant="h4">Password Reset</Typography>
        <br />
        <Typography color="textSecondary" variant="body2">
          Please enter your email and new password.
        </Typography>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            const { email, newPassword } = values;
            try {
              await axios.post(URL, {
                email: email,
                uniqueIdentifier: token,
                password: newPassword
              });
              setIsSuccessful(true);
              setStatusMessage('Password has been updated.');
              setOpenSnackBar(true);
              resetForm();
              _checkIfNeedRedirect();
            } catch (err) {
              setIsSuccessful(false);
              setStatusMessage(
                (err as ErrorResponse).response?.data?.Error || ''
              );
              setOpenSnackBar(true);
              resetForm();
            }
          }}
          initialValues={{
            email: '',
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={MyAccountSchema}
          render={({ isValidating, isSubmitting }) => {
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormTextField
                    style={{ height: '76px' }}
                    idx="resetPassword-email"
                    name="email"
                    label="Email"
                    isRequired={true}
                  />
                  <FormTextField
                    style={{ minHeight: '76px' }}
                    idx="resetPassword-new-password"
                    name="newPassword"
                    label="New Password"
                    isRequired={true}
                    type="password"
                    autoComplete="new-password"
                  />
                  <FormTextField
                    style={{ height: '76px' }}
                    idx="resetPassword-confirm-password"
                    name="confirmPassword"
                    label="Confirm Password"
                    isRequired={true}
                    type="password"
                    autoComplete="new-password"
                  />
                  <SubmitButton
                    size="large"
                    id="resetPassword-submit"
                    loading={!!(isValidating || isSubmitting)}
                    disabled={!!(isValidating || isSubmitting)}
                  >
                    Submit
                  </SubmitButton>
                </div>
              </Form>
            );
          }}
        />
      </Paper>
    </Layout>
  );
}

export default ResetPassword;
