import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FormTextField, SubmitButton } from '../../components';
import { ErrorResponse } from 'types/network';
import { Paper, Typography } from '@material-ui/core';

const Layout = styled.div`
  max-width: 2048px;
  margin: 0 auto;
  padding: 0 30px;
`;

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      style={{ zIndex: 1500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const URL = `${process.env.REACT_APP_URL}/?route=PartnerUpSertExternalUserPasswordResetRequest`;

function ForgotPassword() {
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    setIsSuccessful(false);
    setStatusMessage('');
  };
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    lastName: Yup.string().required('Required')
  });
  return (
    <Layout>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        {openSnackbar ? (
          <Alert
            onClose={handleCloseSnackBar}
            severity={isSuccessful ? 'success' : 'error'}
          >
            {statusMessage}
          </Alert>
        ) : undefined}
      </Snackbar>
      <Paper
        elevation={2}
        style={{
          maxWidth: '400px',
          margin: 'auto',
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          padding: '40px'
        }}
      >
        <Typography variant="h4">
          Partner Portal <br /> Password Recovery
        </Typography>
        <br />
        <Typography color="textSecondary" variant="body2">
          Please enter your email and last name. An email will be sent to you to
          reset your password.
        </Typography>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            const { email, lastName } = values;
            try {
              await axios.post(URL, { email: email, lastName: lastName });
              setIsSuccessful(true);
              setStatusMessage('Check your inbox for a password reset email.');
              setOpenSnackBar(true);
              resetForm();
            } catch (err) {
              setIsSuccessful(false);
              setStatusMessage(
                (err as ErrorResponse).response?.data?.Error || ''
              );
              setOpenSnackBar(true);
              resetForm();
            }
          }}
          initialValues={{
            email: '',
            lastName: ''
          }}
          validationSchema={ForgotPasswordSchema}
          render={({ isValidating, isSubmitting }) => {
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormTextField
                    style={{ height: '76px' }}
                    idx="forgotPassword-email"
                    name="email"
                    label="Email"
                    isRequired={true}
                  />
                  <FormTextField
                    style={{ height: '76px' }}
                    idx="forgotPassword-lastName"
                    name="lastName"
                    label="Last Name"
                    isRequired={true}
                  />
                  <SubmitButton
                    size="large"
                    id="myAccount-submit"
                    loading={!!(isValidating || isSubmitting)}
                    disabled={!!(isValidating || isSubmitting)}
                  >
                    Submit
                  </SubmitButton>
                </div>
              </Form>
            );
          }}
        />
      </Paper>
    </Layout>
  );
}

export default ForgotPassword;
