import React from 'react';
import { Form, Formik } from 'formik';
import { FormTextField, SubmitButton } from 'components';
import { ZipCodeField } from 'components/ZipCodeField';
import { usePartnerUpdateRequestInsuranceInsert } from 'hooks/requests';
import { StateSelect } from 'components/StateSelect';
import { SexField } from 'components/SexField';
import { DOBField } from 'components/DOBField';
import { InsuredRelationshipToPatientField } from 'components/InsuredRelationshipToPatientField';
import { format } from 'date-fns/esm';
import { InsuranceInfo, PatientInsuranceInfo } from 'types/patientInsurance';
import { Color } from '@material-ui/lab/Alert';
import { useAuthentication } from '../../../hooks/useAuthentication';

export interface InsuranceFormProps {
  ordinal?: string;
  data?: PatientInsuranceInfo;
  initialValues?: Partial<InsuranceInfo>;
  setSnackbarState: (state: {
    message?: string;
    open?: boolean;
    severity?: Color;
  }) => void;
}

export const InsuranceForm: React.FC<InsuranceFormProps> = ({
  ordinal = 'primary',
  data = {},
  initialValues = {},
  setSnackbarState
}) => {
  const ids = {
    primary: 4,
    secondary: 5,
    tertiary: 6
  };
  const id = ids[ordinal as keyof typeof ids];
  const { mutateAsync: updateMutation } =
    usePartnerUpdateRequestInsuranceInsert();
  const { isLoggedInSSO } = useAuthentication();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: data.id,
        planName: '',
        groupID: '',
        policyID: '',
        companyAddress: '',
        companyCity: '',
        companyState: '',
        companyZip: '',
        insuredRelationshipToPatient: '',
        insuredFirstName: '',
        insuredLastName: '',
        insuredSex: '',
        insuredAddress: '',
        insuredCity: '',
        insuredState: '',
        insuredZip: '',
        employerName: '',
        ...initialValues,
        insuredDOB: initialValues.insuredDOB
          ? format(new Date(initialValues.insuredDOB), 'MM/dd/yyyy')
          : ''
      }}
      onSubmit={async (values, actions) => {
        try {
          await updateMutation({
            ...data,
            reqAction: id,
            [`${ordinal}Insurance`]: {
              ...values,
              insuredDOB: values?.insuredDOB ? new Date(values.insuredDOB) : ''
            }
          } as unknown as PatientInsuranceInfo);

          setSnackbarState({
            message: 'Update request saved successfully',
            severity: 'success',
            open: true
          });
        } catch (err) {
          setSnackbarState({
            message:
              (err as { Error: string }).Error || 'An Error has occured.',
            severity: 'error',
            open: true
          });
        }
        actions.setSubmitting(false);
      }}
      render={({ isSubmitting, isValidating }) => {
        const isFormSubmitting = isSubmitting || isValidating;

        return (
          <Form
            style={{
              padding: '0 24px 20px 24px'
            }}
          >
            <FormTextField
              name="planName"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Plan Name"
            />
            <FormTextField
              name="groupID"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Group ID"
            />
            <FormTextField
              name="policyID"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Policy ID"
            />
            <FormTextField
              name="companyAddress"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insurance Address"
            />
            <FormTextField
              name="companyCity"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insurance City"
            />
            <StateSelect
              name="companyState"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insurance State"
            />
            <ZipCodeField
              name="companyZip"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insurance Zip"
            />
            <InsuredRelationshipToPatientField
              name="insuredRelationshipToPatient"
              style={{
                margin: '0 12px 16px 0'
              }}
            />

            <FormTextField
              name="insuredFirstName"
              isRequired
              alphaOnly
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured First Name"
            />
            <FormTextField
              name="insuredLastName"
              isRequired
              alphaOnly
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured Last Name"
            />
            <SexField
              name="insuredSex"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured Sex"
            />
            <FormTextField
              name="insuredAddress"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured Address"
            />
            <FormTextField
              name="insuredCity"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured City"
            />
            <StateSelect
              name="insuredState"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured State"
            />
            <ZipCodeField
              name="insuredZip"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured Zip"
            />
            <DOBField
              name="insuredDOB"
              isRequired
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Insured DOB"
            />
            <FormTextField
              name="employerName"
              isRequired={false}
              style={{
                margin: '0 12px 16px 0'
              }}
              label="Employer Name"
            />
            {isLoggedInSSO() ? null : (
              <div>
                <SubmitButton
                  disabled={isFormSubmitting}
                  loading={isFormSubmitting}
                >
                  Submit Update Request
                </SubmitButton>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};
