import * as React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { getDefaultBorderIncludes, transformToArray } from './Utils';
import { theme } from 'lib/theme';

/**
 * Whether to include borders or not.
 * Depending on the context some toggles will not have any effect.
 */
export interface TableBorder {
  /**
   * Include the top border. Default true.
   */
  includeTopBorder?: boolean;

  /**
   * Include the right border. Default true.
   */
  includeRightBorder?: boolean;

  /**
   * Include the bottom border. Default true.
   */
  includeBottomBorder?: boolean;

  /**
   * Include the left border. Default true.
   */
  includeLeftBorder?: boolean;
}

export interface TableCellProps extends TableBorder {
  /**
   * The weighting of a cell based on the flex layout style.
   * This value is between 0..1, if not specified 1 is assumed, this will take up the remaining available space.
   */
  weighting?: number;

  width?: number;

  /**
   * Extra styling to apply. These will override existing style with the same key.
   */
  style?: Style | Style[];

  /**
   * How to align the text
   */
  textAlign?: 'left' | 'center' | 'right';

  /**
   * Whether this is a header cell or not. If not defined it will be false.
   */
  isHeader?: boolean;

  /**
   * The font-size to apply to the cell.
   */
  fontSize?: number | string;

  greyBackground: boolean;
  justifyEnd?: boolean;
}

/**
 * This component displays the associated content of it's children.
 */
export class TableCell extends React.PureComponent<TableCellProps> {
  render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let content: any;

    if (typeof this.props.children === 'string') {
      content = <Text>{this.props.children}</Text>;
    } else if (typeof this.props.children === 'number') {
      content = <Text>{this.props.children.toString()}</Text>;
    } else {
      content = this.props.children;
    }

    const { includeRightBorder } = getDefaultBorderIncludes(this.props);
    const widthStyle: Style =
      this.props.width && this.props.width > 0
        ? {
            width: this.props.width
          }
        : {
            flex: this.props.weighting ?? 1
          };
    const defaultStyle: Style = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: this.props.isHeader
        ? 'center'
        : this.props.justifyEnd
        ? 'flex-end'
        : 'flex-start',
      fontSize: this.props.fontSize ?? 6,
      borderRight: includeRightBorder ? '1pt solid black' : '0',
      fontFamily: this.props.isHeader ? 'Helvetica-Bold' : 'Helvetica',
      padding: 5,
      backgroundColor: this.props.isHeader
        ? theme.palette.primary.dark
        : this.props.greyBackground
        ? '#CCCCCC'
        : '#f5f5f5',
      color: this.props.isHeader ? 'white' : 'black',
      ...widthStyle
    };

    const mergedStyles: Style[] = [
      defaultStyle,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...transformToArray(this.props.style)
    ];

    return (
      <View style={mergedStyles} wrap={true}>
        {content}
      </View>
    );
  }
}
