import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { LoginContainer, LoginPanel } from './styled';
import { useNavigate } from 'react-router-dom';
import {
  FormTextField,
  SubmitButton,
  SecureTrustCertificate
} from 'components';
import { useAuthentication } from '../../hooks/useAuthentication';
import backgroundImage from '../../assets/background.jpeg';
import ventraLogo from '../../assets/ventra-logo.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { MFAPhone } from './MFAPhone/MFAPhone';
import { MFA } from './MFA/MFA';
import { FindHelp } from './FindHelp';
import { usePartnerGetMaintenanceMode } from 'hooks/requests';
import { ROUTES } from 'constants/routes';
import { ErrorResponse } from 'types/network';
import { User } from 'types/user';
import { theme } from 'lib/theme';

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      style={{ zIndex: 1500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

const Img = styled.img`
  width: 275px;
  margin-bottom: 12px;
`;

const URL = `${process.env.REACT_APP_URL}/?route=PartnerGetExternalUser`;
enum StepTypes {
  LOGIN = 'LOGIN',
  MFA_PHONE = 'MFA_PHONE',
  MFA = 'MFA'
}
interface LoginState {
  step: StepTypes;
}
const loginReducer = (state: LoginState, action: { type: StepTypes }) => {
  switch (action.type) {
    case StepTypes.LOGIN:
      return {
        ...state,
        step: StepTypes.LOGIN
      };
    case StepTypes.MFA_PHONE:
      return {
        ...state,
        step: StepTypes.MFA_PHONE
      };
    case StepTypes.MFA:
      return {
        ...state,
        step: StepTypes.MFA
      };
    default:
      throw new Error(`${action.type} is not a valid action for loginReducer.`);
  }
};
//
function Login() {
  const navigate = useNavigate();
  const { setToken, setUserInfo } = useAuthentication();
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [user, setUser] = useState({} as unknown as User);
  const { data: inMaintenance = false, refetch } = usePartnerGetMaintenanceMode(
    {
      onError: err => {
        setOpenSnackBar(true);
        setErrorMessage(
          err.response?.data?.Error || ROUTES.PartnerGetMaintenanceMode
        );
      }
    }
  );
  const handleCloseSnackBar = (_: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const [state, dispatch] = useReducer(loginReducer, {
    step: StepTypes.LOGIN
  });
  const gotoMFAPhone = () => dispatch({ type: StepTypes.MFA_PHONE });
  const gotoMFA = () => dispatch({ type: StepTypes.MFA });
  return (
    <LoginContainer src={backgroundImage}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <div>
        <div className="working" />
        <div className="ReverseWorking" />
        <LoginPanel>
          <Img
            src={ventraLogo}
            style={{
              marginTop: inMaintenance ? 0 : '40px'
            }}
          />
          {inMaintenance ? (
            <h2
              style={{
                textAlign: 'center'
              }}
            >
              The Partner Portal is undergoing routine maintenance. Please try
              again later.
            </h2>
          ) : null}
          {state.step === StepTypes.LOGIN && !inMaintenance ? (
            <Formik
              onSubmit={async (values, actions) => {
                const { username, password } = values;
                try {
                  const userInfo = await axios.post(URL, {
                    userName: username,
                    password
                  });
                  const user =
                    !!userInfo && !!userInfo.data ? userInfo.data : '';
                  setUser(user);
                  setToken(userInfo.data.token);
                  setUserInfo(JSON.stringify(user));
                  const validPhoneRegex = /^(\d{10})$/;
                  if (user.requireMFA && user.isEmailMFA) {
                    gotoMFA();
                  } else if (
                    user.requireMFA &&
                    !validPhoneRegex.test(user.phoneNumber)
                  ) {
                    gotoMFAPhone();
                  } else if (user.requireMFA) {
                    gotoMFA();
                  } else if (
                    !user.showMyEncounters &&
                    user.showMyTransactions
                  ) {
                    navigate('/MyTransactionsSummary');
                  } else {
                    navigate('/MyEncounters');
                  }
                } catch (err) {
                  setErrorMessage(
                    (err as ErrorResponse).response?.data.Error ||
                      'oops! an error occurred'
                  );
                  setOpenSnackBar(true);
                  refetch();
                }
                actions.setSubmitting(false);
              }}
              initialValues={{
                username: '',
                password: ''
              }}
              render={({ isValidating, isSubmitting }) => {
                return (
                  <Form
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: '180px',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginTop: '12px'
                      }}
                    >
                      <FormTextField
                        idx="login-username"
                        name="username"
                        label="Username"
                        isRequired={true}
                      />
                      <FormTextField
                        idx="login-password"
                        name="password"
                        label="Password"
                        isRequired={true}
                        type="password"
                      />
                      <SubmitButton
                        id="login-submit"
                        loading={!!(isValidating || isSubmitting)}
                        disabled={!!(isValidating || isSubmitting)}
                      >
                        Login
                      </SubmitButton>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        padding: '15px 0'
                      }}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        style={{
                          textDecoration: 'underline',
                          marginBottom: '10px',
                          cursor: 'pointer',
                          color: theme.palette.primary.main
                        }}
                        onClick={() => navigate('/ForgotPassword')}
                      >
                        Forgot Password
                      </a>
                      <FindHelp />

                      <SecureTrustCertificate
                        style={{
                          marginTop: '12px'
                        }}
                      />
                    </div>
                  </Form>
                );
              }}
            />
          ) : null}
          {state.step === StepTypes.MFA_PHONE ? (
            <MFAPhone gotoMFA={gotoMFA} />
          ) : null}
          {state.step === StepTypes.MFA ? (
            <MFA
              user={user}
              setErrorMessage={setErrorMessage}
              setOpenSnackBar={setOpenSnackBar}
            />
          ) : null}
        </LoginPanel>
      </div>
    </LoginContainer>
  );
}

export default Login;
