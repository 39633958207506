import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Create } from '@material-ui/icons';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React from 'react';
import NumberFormat from 'react-number-format';
import EnhancedTable from './EnhancedTable';
import { theme } from 'lib/theme';
import { Column } from 'react-table';

const useStyles = makeStyles(() => ({
  // stylelint-disable-next-line selector-type-no-unknown
  tooltip: {
    fontSize: '14px'
  }
}));

const RowActions = ({
  rowId,
  invoiceNo,
  setOpenSummary,
  setOpenPatientStatement,
  showPatientStatement,
  setOpenPatientInsurance,
  hospitalAccountNumber,
  setOpenInvoice,
  setShowInvoice,
  showMyTransactionsX12DocumentLink
}) => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip title="Details" classes={classes}>
        <IconButton
          style={{
            padding: '5px',
            marginTop: '5px',
            marginBottom: '5px'
          }}
          aria-label="details"
          onClick={() => setOpenSummary(rowId)}
        >
          <FindInPageIcon style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Update Information" classes={classes}>
        <IconButton
          style={{
            padding: '5px',
            marginTop: '5px',
            marginBottom: '5px'
          }}
          aria-label="update-patient-insurance"
          onClick={() =>
            setOpenPatientInsurance({
              ...rowId,
              invoiceNo,
              hospitalAccountNumber
            })
          }
        >
          <Create style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>

      {showPatientStatement && (
        <Tooltip title="Patient Statement" classes={classes}>
          <IconButton
            style={{
              padding: '5px',
              marginTop: '5px',
              marginBottom: '5px'
            }}
            aria-label="patient-statement"
            onClick={() =>
              setOpenPatientStatement({ ...rowId, account: invoiceNo })
            }
          >
            <ReceiptIcon style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      )}
      {!!showMyTransactionsX12DocumentLink && (
        <Tooltip title="Documents" classes={classes}>
          <IconButton
            style={{
              padding: '5px',
              marginTop: '5px',
              marginBottom: '5px'
            }}
            onClick={() => {
              setShowInvoice(invoiceNo);
              setOpenInvoice(true);
            }}
          >
            <FileCopyIcon style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

const MuiEnhancedTable = ({
  initial,
  tableData,
  notSearched,
  openSummary,
  openPatientStatement,
  setOpenSummary,
  setOpenPatientStatement,
  openPatientInsurance,
  setOpenPatientInsurance,
  setOpenInvoice,
  setShowInvoice
}) => {
  // Last Name, First Name - Label should be 'Patient'
  // DOS
  // DOB
  // Charges
  // Patient Payment
  // Insurance Co Payment
  // Adjustment
  // Balance
  // Collection Balance
  // PolicyID
  const user = JSON.parse(sessionStorage.getItem('user'));

  /**@type Column<any>[]*/
  const COLUMNS = [
    {
      Header: () => <div />,
      id: 'ID',
      accessor: ({
        invoiceNo,
        srcSystemKey,
        facilityName,
        hospitalAccountNumber
      }) => (
        <RowActions
          rowId={{ account: invoiceNo, srcSystemKey, facility: facilityName }}
          setOpenSummary={setOpenSummary}
          setOpenPatientStatement={setOpenPatientStatement}
          setOpenPatientInsurance={setOpenPatientInsurance}
          showPatientStatement={user.showPatientStatement}
          invoiceNo={invoiceNo}
          hospitalAccountNumber={hospitalAccountNumber}
          setOpenInvoice={setOpenInvoice}
          setShowInvoice={setShowInvoice}
          showMyTransactionsX12DocumentLink={
            user.showMyTransactionsX12DocumentLink
          }
        />
      )
    },
    {
      Header: 'Facility',
      accessor: 'facilityName'
    },
    {
      Header: 'DOS',
      id: 'dos',
      accessor: 'dos',
      sortType: 'dateString'
    },
    {
      Header: 'Account',
      accessor: 'hospitalAccountNumber'
    },
    {
      Header: 'MRN',
      accessor: 'mrn'
    },
    {
      Header: 'Statement Account',
      accessor: 'statementAccount'
    },
    {
      Header: 'Invoice',
      accessor: 'invoiceNo'
    },
    {
      Header: 'Patient',
      accessor: 'name'
    },
    {
      Header: 'DOB',
      id: 'dob',
      accessor: 'dob',
      sortType: 'dateString'
    },
    {
      Header: 'Charges',
      id: 'charge',
      accessor: 'charge',
      Cell: ({
        row: {
          original: { charge }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={charge}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    },
    {
      Header: 'Adj',
      id: 'adj',
      accessor: 'adj',
      Cell: ({
        row: {
          original: { adj }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={adj}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    },
    {
      Header: 'Ins Pmt',
      id: 'pmtInsCo',
      accessor: 'pmtInsCo',
      Cell: ({
        row: {
          original: { pmtInsCo }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={pmtInsCo}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    },
    {
      Header: 'Pat Pmt',
      id: 'pmtPat',
      accessor: 'pmtPat',
      Cell: ({
        row: {
          original: { pmtPat }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={pmtPat}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    },
    {
      Header: 'Bal',
      id: 'balance',
      accessor: 'balance',
      Cell: ({
        row: {
          original: { balance }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={balance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    },
    {
      Header: 'Coll Bal',
      id: 'colBal',
      accessor: 'colBal',
      Cell: ({
        row: {
          original: { colBal }
        }
      }) => (
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormat
            value={colBal}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )
    }
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => COLUMNS, []);
  const data = React.useMemo(() => tableData, [tableData]);
  // // We need to keep the table from resetting the pageIndex when we
  // // Update data. So we can keep track of that flag with a ref.

  // // When our cell renderer calls updateMyData, we'll use
  // // the rowIndex, columnId and new value to update the
  // // original data
  // const updateMyData = (rowIndex, columnId, value) => {
  //   // We also turn on the flag to not reset the page
  //   setSkipPageReset(true)
  //   setData(old =>
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return {
  //           ...old[rowIndex],
  //           [columnId]: value,
  //         }
  //       }
  //       return row
  //     })
  //   )
  // }

  return (
    <EnhancedTable
      columns={columns}
      data={
        data?.map(record => {
          return {
            ...record,
            dos: record?.ados || '',
            name: `${record.lastName}, ${record.firstName}`
          };
        }) || []
      }
      initial={initial}
      openRecord={openSummary}
      openStatement={openPatientStatement}
      openPatientInsurance={openPatientInsurance}
      notSearched={notSearched}
    />
  );
};

export default MuiEnhancedTable;
