import React, { useMemo } from 'react';
import styled from 'styled-components';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TableToolbar from './TableToolbar';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import is from 'is_js';
import { compareAsc, format } from 'date-fns';
import { exportToExcelSheet } from 'lib/excelExport';
import { theme } from 'lib/theme';
import { BrandColor } from 'constants/brandColor';

const EnhancedTable = ({
  columns,
  data,
  openRecord,
  openStatement,
  notSearched,
  openPatientInsurance
}) => {
  const memoizedColumns = useMemo(() => {
    return columns.map(column => {
      return {
        ...column,
        sortType: column.sortType
          ? dateKeywordsRegex.test(column.accessor || column.id)
            ? sortDate
            : 'alphanumeric'
          : 'alphanumeric'
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, data]);

  const sortTypes = useMemo(() => createSortTypes(), []);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      initialState: { pageSize: 25 },
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      sortTypes: sortTypes
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  const SelectedActions = ({ disableExport }) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          disabled={disableExport}
          variant="outlined"
          color="primary"
          style={{ width: '90px' }}
          onClick={() =>
            exportToExcelSheet({
              fileName: `MyTransactionsSummary-${format(
                new Date(),
                'dd.MM.yyyy'
              )}.xlsx`,
              sheetName: 'My Transactions Summary',
              columns,
              data: data.map(el => ({
                ...el,
                ados: el.ados ? new Date(el.ados) : '',
                dob: el.dob ? new Date(el.dob) : ''
              }))
            })
          }
        >
          Export
        </Button>
      </div>
    );
  };
  const user = JSON.parse(sessionStorage.getItem('user'));
  const isIE = is.ie('>=10');
  const checkSelected = row => {
    if (
      row.original.invoiceNo === (openRecord && openRecord.account) &&
      row.original.srcSystemKey === (openRecord && openRecord.srcSystemKey)
    )
      return true;
    if (
      row.original.invoiceNo === (openStatement && openStatement.account) &&
      row.original.srcSystemKey ===
        (openStatement && openStatement.srcSystemKey)
    )
      return true;
    if (row.original.invoiceNo === openPatientInsurance?.invoiceNo) return true;
    return false;
  };

  // Render the UI for your table
  return (
    <TableContainer>
      <TableToolbar
        notSearched={notSearched}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        total={data.length}
        SelectedActions={props =>
          SelectedActions({
            ...props,
            data
          })
        }
      />
      <TableWrapper>
        <StyledTable
          {...getTableProps()}
          isIE={isIE}
          setMinHeight={page.length > 5}
          style={
            user.showPatientStatement
              ? {}
              : {
                  gridTemplateColumns:
                    '60px 1fr 60px repeat(5, 1fr) 60px repeat(6, 1fr)'
                }
          }
        >
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...(column.id === 'selection' || column.id === 'ID'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    title=""
                  >
                    {column.id !== 'selection' && column.id !== 'ID' ? (
                      // TODO: Extract to a new file
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <span>{column.render('Header')}</span>
                        <span
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '30px'
                          }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              fontSize: '30px',
                              position: 'relative',
                              top: '-5px',
                              color:
                                column.isSorted && column.isSortedDesc
                                  ? BrandColor.ACCENT
                                  : 'white'
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              fontSize: '30px',
                              position: 'relative',
                              top: '-25px',
                              color:
                                column.isSorted && !column.isSortedDesc
                                  ? BrandColor.ACCENT
                                  : 'white'
                            }}
                          />
                        </span>
                      </div>
                    ) : (
                      column.render('Header')
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {(data.length === 0 || page.length === 0) && (
              <tr>
                <td
                  align="center"
                  style={{
                    padding: '10px',
                    fontSize: '14px',
                    width: '100%',
                    gridColumn: '1/17'
                  }}
                  colSpan="15"
                >
                  {data.length === 0
                    ? 'Please Search for Records'
                    : 'Please refine your filter'}
                </td>
              </tr>
            )}
            {page.map(row => {
              prepareRow(row);
              return (
                // TODO: open details page onClick
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        style={{
                          ...(checkSelected(row)
                            ? { backgroundColor: '#95bcd7' }
                            : {})
                        }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, { label: 'All', value: data.length }]}
            colSpan={16}
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

const createSortTypes = () => ({
  dateString: (a, b, id) =>
    compareAsc(new Date(a.original[id]), new Date(b.original[id]))
});

export default EnhancedTable;

const StyledTable = styled(MaUTable)`
  border-collapse: separate;

  th,
  td {
    border: 1px solid #0003;
  }

  .MuiTableCell-root {
    padding: 5px;
  }
  .MuiTableCell-head {
    line-height: 1rem;
  }
  th {
    background-color: ${theme.palette.primary.dark};
    color: white;
    font-weight: bold;
    font-size: 11px;
    position: sticky;
    top: 0;
    z-index: 3;
  }
  td {
    font-size: 11px;
  }
  tr:nth-child(odd) {
    td {
      background-color: #ddd;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #f5f5f5;
    }
  }
`;

const TableWrapper = styled.div`
  border-radius: 5px;
  max-height: calc(100vh - 340px);
  overflow: auto;
`;

function sortDate(rowA, rowB, id) {
  if (!rowA?.original) return 0;
  return compareAsc(new Date(rowA.original[id]), new Date(rowB.original[id]));
}
const dateKeywordsRegex = /(date|dos|dob|ados|expirationDate)/gi;
