import React from 'react';
import { Form, Formik } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { CommentBox } from 'components';
import { BaseButton, SubmitButton } from 'components/Buttons';
import { DraggableDialog } from 'components/DraggableDialog/DraggableDialog';
import {
  ClearAllExceptionEncounterHeaderExceptionsMultiBody,
  useClearAllExceptionEncounterHeaderExceptionsMulti
} from './lib/useClearAllExceptionEncounterHeaderExceptionsMulti';
import { useEncounterSearchContextUtils } from 'lib/encounterSearchContextUtils';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
  margin-right: 5px;
`;

const InfoSection = styled.section`
  display: flex;
`;

export type BatchAuthorizeFormContext =
  ClearAllExceptionEncounterHeaderExceptionsMultiBody;

export interface BatchAuthorizeDialogProps {
  open: boolean;
  handleClose: () => void;
  encounterHeaderIds: number[];
}

export const BatchAuthorizeDialog: React.FC<BatchAuthorizeDialogProps> = ({
  open,
  handleClose,
  encounterHeaderIds
}) => {
  const { mutateAsync: batchAuthorize } =
    useClearAllExceptionEncounterHeaderExceptionsMulti();
  const { removeEncounterHeadersFromSearch } = useEncounterSearchContextUtils();

  return (
    <DraggableDialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      id="batch-authorize-dialog"
      title="Batch Authorize"
    >
      <Formik<BatchAuthorizeFormContext>
        initialValues={{
          encounterHeaderIds
        }}
        onSubmit={async values => {
          await batchAuthorize(values);
          removeEncounterHeadersFromSearch(encounterHeaderIds);
          handleClose();
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogContent>
              <InfoSection>
                <StyledChip
                  variant="outlined"
                  size="small"
                  color="primary"
                  aria-labelledby="encounters-selected"
                  label={encounterHeaderIds.length}
                />
                <Typography id="encounters-selected">
                  Encounters selected
                </Typography>
              </InfoSection>
              <CommentBox />
            </DialogContent>
            <DialogActions>
              <BaseButton
                type="button"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Close
              </BaseButton>
              <SubmitButton
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              >
                Authorize for coding
              </SubmitButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DraggableDialog>
  );
};
