import { DialogContent } from '@material-ui/core';
import { Provider } from 'components/ExceptionsDialog/lib/useProviders';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { ExceptionsDialogActions } from '../../Actions/ExceptionsDialogActions';
import { EncounterException } from '../../lib/useExceptions';
import { SelectedExceptions } from './SelectedExceptions/SelectedExceptions';
import { ExceptionsFormContext, SubmitType } from './types';
import { useExceptionsDialogContext } from 'components/ExceptionsDialog/lib/exceptionsDialogContext';
import { CommentBox } from 'components';
import { useUpdateExceptionEncounterHeaders } from 'components/ExceptionsDialog/lib/useUpdateExceptionEncounterHeaders';
import { useClearAllExceptionEncounterHeaderExceptions } from 'components/ExceptionsDialog/lib/useClearAllExceptionEncounterHeaderExceptions';
import {
  scrubCompositeProviderIds,
  withProviderCompositeIds
} from './lib/utils';
import { useEncounterSearchContextUtils } from 'lib/encounterSearchContextUtils';

export interface ExceptionsFormProps {
  exceptions: EncounterException[];
  providers: Provider[];
}

export const ExceptionsForm: React.FC<ExceptionsFormProps> = ({
  exceptions,
  providers
}) => {
  const { handleClose } = useExceptionsDialogContext();
  const { removeEncounterHeaderFromSearch } = useEncounterSearchContextUtils();
  const { mutateAsync: save } = useUpdateExceptionEncounterHeaders();
  const { mutateAsync: clear } =
    useClearAllExceptionEncounterHeaderExceptions();

  const initialEncounterHeaderExceptions = useMemo(
    () => withProviderCompositeIds(exceptions, providers),
    [exceptions, providers]
  );

  return (
    <Formik<ExceptionsFormContext>
      initialValues={{
        myEncounterHeaderExceptions: initialEncounterHeaderExceptions,
        comment: '',
        submitType: -1
      }}
      onSubmit={async values => {
        const scrubbedValues = scrubCompositeProviderIds(values);
        await save(scrubbedValues);
        const submittedEncounterHeaderId =
          scrubbedValues.myEncounterHeaderExceptions[0].encounterHeaderId;
        if (values.submitType === SubmitType.authorize) {
          await clear({
            encounterHeaderId: submittedEncounterHeaderId,
            comment: scrubbedValues.comment
          });
        }
        removeEncounterHeaderFromSearch(submittedEncounterHeaderId);
        handleClose();
      }}
    >
      {({ isSubmitting, isValid, setFieldValue, values }) => (
        <Form>
          <DialogContent>
            <SelectedExceptions providers={providers} />
            <CommentBox />
          </DialogContent>
          <ExceptionsDialogActions
            loading={isSubmitting}
            allDisabled={!isValid}
            saveDisabled={
              !values.myEncounterHeaderExceptions.some(x => x.isActive)
            }
            authorizedDisabled={values.myEncounterHeaderExceptions.some(
              x => x.isActive
            )}
            save={() => {
              setFieldValue('submitType', SubmitType.save);
            }}
            authorize={() => {
              setFieldValue('submitType', SubmitType.authorize);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
