import React from 'react';
import styled from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useTable } from 'react-table';
import { theme } from 'lib/theme';

const StyledTable = styled(MaUTable)`
  .MuiTableCell-root {
    padding: 5px;
  }
  .MuiTableCell-head {
    line-height: 1rem;
  }
  tr:nth-child(odd) {
    .highlight {
      background-color: #95bcd7;
    }
    td {
      background-color: #ccc;
    }
  }
  tr:nth-child(even) {
    .highlight {
      background-color: #95bcd7;
    }
    td {
      background-color: #f5f5f5;
    }
  }
  thead {
    min-height: 35px;
    /* stylelint-disable-next-line no-descending-specificity */
    tr {
      th {
        background-color: ${theme.palette.secondary.dark};
        z-index: 3;
        color: white;
        padding: 5px;
        vertical-align: top;
        overflow-wrap: break-words;
        font-weight: bold;
        text-align: center;
        border: 1px solid black;
        .MuiButtonBase-root {
          color: white;
        }
      }
    }
    tr:nth-child(1) {
      th {
        text-align: left;
        padding-left: 15px;
        background-color: ${theme.palette.primary.dark};
      }
    }
  }
  tbody {
    /* stylelint-disable-next-line no-descending-specificity */
    tr {
      /* stylelint-disable-next-line no-descending-specificity */
      td {
        padding: 5px;
        border: 1px solid black;
      }
    }
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  // Render the UI for your table
  return (
    <StyledTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell style={{ textAlign: 'center' }} colSpan="100%">
              No Record
            </TableCell>
          </TableRow>
        )}
        {rows.map(row => {
          prepareRow(row);
          return (
            <TableRow style={{ minHeight: '35px' }} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell
                    className={
                      cell.column.Header === 'Balance' ? 'highlight' : ''
                    }
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}

function MuiTable({ style, columns, data }) {
  const tcolumns = React.useMemo(() => columns, [columns]);

  const tdata = React.useMemo(() => data, [data]);

  return (
    <div style={style}>
      <CssBaseline />
      <Table columns={tcolumns} data={tdata} />
    </div>
  );
}

export default MuiTable;
