import { ROUTES } from 'constants/routes';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { useQuery } from 'react-query';
import { ErrorResponse } from 'types/network';

export interface Provider {
  id: number;
  value: string;
  isProviderAssistant: boolean;
}

export const useProviders = (facilityId: number) => {
  const Axios = useAuthenticatedAxios();
  return useQuery<Provider[], ErrorResponse>(
    [ROUTES.GetPartnerPortalProvidersByFacilityId, facilityId],
    async () => {
      const { data } = await Axios({
        method: 'get',
        url: `/${facilityId}?route=${ROUTES.GetPartnerPortalProvidersByFacilityId}`
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );
};
