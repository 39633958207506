import React from 'react';

import clsx from 'clsx';
import GlobalFilter from 'components/GlobalFilter';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '14px',
    padding: '0'
  },
  // stylelint-disable-next-line property-no-unknown
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1',
    maxWidth: '515px',
    marginRight: '10px',
    fontSize: '14px'
  },
  filter: {
    height: '45px',
    fontSize: '14px'
  },
  // stylelint-disable-next-line selector-type-no-unknown
  actions: {
    height: '45px'
  }
}));

const StyledToolbar = styled(Toolbar)`
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    total,
    SelectedActions,
    notSearched
  } = props;
  return (
    <StyledToolbar className={clsx(classes.root)}>
      {notSearched ? (
        <Typography
          style={{ color: '#1779ba' }}
          className={classes.title}
          variant="h6"
          id="tableTitle"
        >
          Enter your search parameters and click Search to return data by
          default.
        </Typography>
      ) : numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Selected {numSelected}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {total} records found from your search criteria.
        </Typography>
      )}
      <div style={{ display: 'flex', gap: '10px' }}>
        <GlobalFilter
          className={classes.filter}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <SelectedActions
          className={classes.actions}
          disableActions={numSelected < 1}
          disableExport={total < 1}
        />
      </div>
    </StyledToolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired
};

export default TableToolbar;
