import React from 'react';
import { DraggableDialog } from 'components/DraggableDialog/DraggableDialog';
import { ExceptionsDialogContent } from './Content/ExceptionsDialogContent';
import { ExceptionsDialogContext } from './lib/exceptionsDialogContext';

export interface ExceptionsDialogProps {
  encounterHeaderId: number;
  facilityId: number;
  open: boolean;
  handleClose: () => void;
}

export const ExceptionsDialog: React.FC<ExceptionsDialogProps> = ({
  encounterHeaderId,
  facilityId,
  open,
  handleClose
}) => (
  <DraggableDialog
    maxWidth={false}
    open={open}
    onClose={handleClose}
    id="encounter-exceptions-dialog"
    title="Encounter Exceptions"
  >
    <ExceptionsDialogContext.Provider value={{ handleClose }}>
      <ExceptionsDialogContent
        encounterHeaderId={encounterHeaderId}
        facilityId={facilityId}
      />
    </ExceptionsDialogContext.Provider>
  </DraggableDialog>
);
