import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMyTransactionsSummaryContext } from './MyTransactionsSummaryContext';
import MyTransactionsSummaryForm, {
  FilterOptions
} from './MyTransactionsSummaryForm';
import MyTransactionsSummaryTable from './MuiEnhancedTable';
import MyTransactionSummaryDetails from './MyTransactionSummaryDetails';
import PatientStatement from './PatientStatement';
import { InvoiceDocumentsDialog } from 'components/InvoiceDocumentsDialog/InvoiceDocumentsDialog';
import { PartnerGetMyTransactionSummary } from './constants';
import SessionTimeout from 'components/SessionTimeout';
import { PatientInsuranceDialog } from './PatientInsuranceDialog/PatientInsuranceDialog';
import { Snackbar, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { theme } from 'lib/theme';

const Layout = styled.div`
  max-width: 2048px;
  margin: 0 auto;
  padding: 0 30px;
`;

function MyTransactionsSummary() {
  const {
    encounters,
    setEncounters,
    setFacilityOptions,
    facility,
    advancedFilter,
    value1,
    value2,
    search,
    setSearch,
    isLoading,
    setIsLoading,
    providerSearch,
    notSearched,
    setNotSearched
  } = useMyTransactionsSummaryContext();
  const [
    showMyTransactionsX12DocumentLink,
    setShowMyTransactionsX12DocumentLink
  ] = useState(false);
  const [openSummary, setOpenSummary] = useState(null);
  const [openPatientStatement, setOpenPatientStatement] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openPatientInsurance, setOpenPatientInsurance] = useState({});
  const [message, setMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      const user = JSON.parse(await sessionStorage.getItem('user'));
      const facilityIDs = user.facilities.map(
        ({ facilityID, facilityName }) => ({
          value: facilityID,
          label: facilityName
        })
      );
      setFacilityOptions(facilityIDs);

      user.showMyTransactionsX12DocumentLink = true;

      setShowMyTransactionsX12DocumentLink(
        user.showMyTransactionsX12DocumentLink
      );
    };
    fetchOptions();
  }, []);

  const formatValue1 = (value, filterValue) => {
    if (!value || !filterValue) return '';
    if (filterValue === FilterOptions.dob)
      return moment(value).format('MM-DD-YYYY');
    if (filterValue === FilterOptions.dosRange) {
      return moment(value).format('MM-DD-YYYY');
    }
    return value;
  };

  const formatValue2 = (value, filterValue) => {
    if (!value || !filterValue) return '';
    if (filterValue === FilterOptions.dosRange) {
      return moment(value).format('MM-DD-YYYY');
    }
    return value;
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        facilityIds: facility.map(el => el.value),
        key: mapAdvancedFilter(advancedFilter),
        value1: formatValue1(value1, advancedFilter),
        value2: formatValue2(value2, advancedFilter),
        providerList: providerSearch ? [providerSearch] : []
      };
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
        }
      };
      const URL = `${process.env.REACT_APP_URL}/?route=${PartnerGetMyTransactionSummary}`;
      const isFetching = search;
      try {
        const result = isFetching
          ? await axios.post(URL, payload, config)
          : encounters;
        setEncounters(isFetching ? result.data : result);
        setNotSearched(isFetching ? false : notSearched);
        setSearch(false);
      } catch (err) {
        setMessage(err ? err?.response?.data.Error : message);
        if (err?.response?.data?.Error) {
          setOpenSnackBar(true);
        }
        setIsLoading(false);
        setSearch(false);
        setNotSearched(false);
        setEncounters([]);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setIsLoading(false);
  }, [encounters]);

  const handleCloseSnackBar = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  return (
    <Layout>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
      >
        {openSnackBar && (
          <Alert
            style={{ zIndex: '1500' }}
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackBar}
            severity={'error'}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          <h1 style={{ marginRight: '30px' }}>My Transactions</h1>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: theme.palette.primary.main
            }}
          >
            Please select Search Type criteria and click Search
          </p>
        </div>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          style={{ fontSize: '16px' }}
          href={`https://mgablobstorageprodcdn.blob.core.windows.net/${process.env.REACT_APP_NODE_ENV.toLowerCase()}-cdn/Partner.Gottlieb.com.UserGuide.MyTransactions.pdf?id=${Math.random()
            .toString(36)
            .slice(2)}`}
          target="_blank"
        >
          User Guide
        </a>
      </div>
      <>
        <MyTransactionsSummaryForm />

        <Divider
          variant="middle"
          style={{ marginTop: '2em', marginBottom: '1em' }}
        />
      </>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px'
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: notSearched ? 'none' : 'block' }}>
          <MyTransactionsSummaryTable
            tableData={
              encounters?.map(encounter => {
                return {
                  ...encounter,
                  ados: encounter.ados
                    ? moment(encounter.ados).format('MM/DD/YY')
                    : '',
                  dob: encounter.dob
                    ? moment(encounter.dob).format('MM/DD/YY')
                    : ''
                };
              }) || []
            }
            openPatientInsurance={openPatientInsurance}
            setOpenPatientInsurance={setOpenPatientInsurance}
            notSearched={notSearched}
            openSummary={openSummary}
            openPatientStatement={openPatientStatement}
            showMyTransactionsX12DocumentLink={
              showMyTransactionsX12DocumentLink
            }
            openInvoice={openInvoice}
            showInvoice={showInvoice}
            setOpenSummary={setOpenSummary}
            setOpenPatientStatement={setOpenPatientStatement}
            setOpenInvoice={setOpenInvoice}
            setShowInvoice={setShowInvoice}
            setShowMyTransactionsX12DocumentLink={
              setShowMyTransactionsX12DocumentLink
            }
          />
        </div>
      )}

      {!!showMyTransactionsX12DocumentLink && !!showInvoice ? (
        <InvoiceDocumentsDialog
          open={!!openInvoice}
          invoiceNo={showInvoice}
          onClose={() => setOpenInvoice(false)}
        ></InvoiceDocumentsDialog>
      ) : null}

      <MyTransactionSummaryDetails
        open={!!openSummary}
        accountNumber={
          openSummary
            ? {
                account: openSummary.account,
                srcSystemKey: openSummary.srcSystemKey
              }
            : null
        }
        facility={openSummary ? openSummary.facility : null}
        close={() => setOpenSummary(null)}
      />
      <PatientStatement
        open={!!openPatientStatement}
        accountNumber={
          openPatientStatement
            ? {
                account: openPatientStatement.account,
                srcSystemKey: openPatientStatement.srcSystemKey
              }
            : null
        }
        close={() => setOpenPatientStatement(null)}
      />
      <PatientInsuranceDialog
        {...openPatientInsurance}
        open={Boolean(openPatientInsurance.account)}
        close={() => setOpenPatientInsurance({})}
      />
      <SessionTimeout />
    </Layout>
  );
}

export default MyTransactionsSummary;

const mapAdvancedFilter = advancedFilter => {
  switch (advancedFilter) {
    case 'Date Of Birth':
      return 'PatientDOB';
    case 'Patient Last Name':
      return 'LastName';
    case 'Account Number':
      return 'FacilityAccountNumber';
    case 'Statement Account':
      return 'statementAccount';
    case 'Invoice Number':
      return 'invoiceNumber';
    case 'Provider':
      return 'Provider';
    case 'Date Of Service Range':
      return 'DateOfService';
    default:
      return '';
  }
};
