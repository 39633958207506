import React from 'react';
import { useField } from 'formik';
import InputMask from 'react-input-mask';
import { composeValidators, requiredValidator } from 'sd-formik-validators';
import { StyledTextField } from '../StyledTextField';
import { isExists, isFuture } from 'date-fns';

const validateDOB = (value: string) => {
  const validDOBRegex = /\d{2}\/\d{2}\/\d{4}/;
  const [dobMonth, dobDay, dobYear] = value.split('/');
  const dobDate: Date = new Date(
    Number(dobYear),
    Number(dobMonth) - 1,
    Number(dobDay)
  );
  const nowDate: Date = new Date();
  const failed = 'Invalid DOB';
  const goodValue = undefined;

  // date should not return an error if not filled out
  if (['', '__/__/____'].includes(value.trim())) return goodValue;
  // date should pass regex test to be valid date format
  if (!validDOBRegex.test(value)) return failed;
  // date should return error if is future
  if (isFuture(dobDate)) return 'Date of birth cannot be in future';
  // date should exist in our reality
  if (!isExists(Number(dobYear), Number(dobMonth) - 1, Number(dobDay)))
    return failed;
  // date should be less than 126 into the past - people arent that old
  if (Math.abs(nowDate.getFullYear() - Number(dobYear)) >= 126) return failed;
  // default return undefined if date is good
  return goodValue;
};

export type DOBFieldProps = {
  isRequired?: boolean;
  name: string;
  label: string;
  validate?: Array<unknown>;
  helperText?: string;
  error?: string;
  onFieldValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
};

export const DOBField: React.FC<DOBFieldProps> = ({
  isRequired = false,
  name,
  label,
  validate = [],
  helperText = '',
  error = '',
  onFieldValueChange = () => {},
  ...props
}) => {
  const [field, meta] = useField({
    name,
    validate: isRequired
      ? composeValidators(requiredValidator, validateDOB, ...validate)
      : composeValidators(validateDOB, ...validate)
  });

  return (
    <InputMask
      mask={'99/99/9999'}
      {...field}
      onChange={e => {
        field.onChange(e);
        onFieldValueChange(e);
      }}
    >
      <StyledTextField
        label={isRequired && label ? `*${label}` : label}
        error={Boolean((meta.touched && meta.error) || error)}
        helperText={meta.touched && meta.error ? meta.error : helperText}
        style={{
          ...props.style,
          marginBottom: !!(meta.touched && meta.error)
            ? '4px'
            : (!!props && !!props.style && props.style.marginBottom) || ''
        }}
        {...props}
      />
    </InputMask>
  );
};
