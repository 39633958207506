import { useMutation } from 'react-query';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ROUTES } from 'constants/routes';
import { ErrorResponse } from 'types/network';

export const useSendMfaTextToEmail = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation<void, ErrorResponse>(async () => {
    const { data } = await Axios({
      method: 'post',
      url: `/?route=${ROUTES.SendMfaTextToEmail}`
    });
    return data;
  });
};
