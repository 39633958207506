import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FastField, FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 20px;
  }
`;

export const DEFAULT_CHARACTER_LIMIT = 255;

export const CommentBox: React.FC = () => (
  <FastField
    name="comment"
    validate={(value?: string) =>
      (value?.length || 0) > DEFAULT_CHARACTER_LIMIT
    }
  >
    {({ field, meta: { error } }: FieldProps<string | undefined>) => (
      <StyledFormControl fullWidth>
        <TextField
          id="comment-text-field"
          label="Comment (optional)"
          multiline
          rows={4}
          variant="outlined"
          {...field}
          error={!!error}
          helperText={`${field.value?.length || 0}/${DEFAULT_CHARACTER_LIMIT}`}
        />
      </StyledFormControl>
    )}
  </FastField>
);
