import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { BrandColor } from 'constants/brandColor';

export function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{
            padding: 0
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f5',
    display: 'flex'
  },
  // stylelint-disable-next-line selector-type-no-unknown
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  // stylelint-disable-next-line selector-type-no-unknown
  indicator: {
    backgroundColor: BrandColor.ACCENT // olive color
  }
}));

export const VerticalTabs = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <Tabs
        orientation="vertical"
        variant="standard"
        classes={{
          tabs: classes.tabs,
          indicator: classes.indicator
        }}
        {...props}
      >
        {children}
      </Tabs>
    </div>
  );
};
