import React from 'react';
import { useField } from 'formik';
import { composeValidators, requiredValidator } from 'sd-formik-validators';
import { StyledTextField } from './StyledTextField';
const validateSex = () => {
  return undefined;
};
export const SexField = ({
  isRequired = false,
  name,
  label,
  validate = [],
  helperText = '',
  error = '',
  onFieldValueChange = () => {},
  ...props
}) => {
  const [field, meta] = useField({
    name,
    validate: isRequired
      ? composeValidators(requiredValidator, validateSex, ...validate)
      : composeValidators(validateSex, ...validate)
  });

  return (
    <StyledTextField
      {...field}
      value={(field && field.value) || ''}
      onChange={e => {
        field.onChange(e);
        onFieldValueChange(e);
      }}
      label={isRequired && label ? `*${label}` : label}
      error={!!(meta.touched && meta.error) || error}
      helperText={!!(meta.touched && meta.error) ? meta.error : helperText}
      style={{
        ...props.style,
        marginBottom: !!(meta.touched && meta.error)
          ? '4px'
          : (!!props && !!props.style && props.style.marginBottom) || ''
      }}
      {...props}
    />
  );
};
