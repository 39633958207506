import React from 'react';

function SecureTrustCertificate({ style }) {
  return (
    <img
      id="trustwaveSealImage"
      src="https://sealserver.trustwave.com/seal_image.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert"
      alt="This site is protected by Trustwave's Trusted Commerce program"
      style={{
        cursor: 'pointer',
        width: '60px',
        height: '40px',
        border: 0,
        ...style
      }}
      onClick={() => {
        window.open(
          'https://sealserver.trustwave.com/cert.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert',
          'c_TW',
          'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'
        );
        return false;
      }}
      onContextMenu={e => {
        alert(
          'Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'
        );
        e.preventDefault();
        return false;
      }}
      title="This site is protected by Trustwave's Trusted Commerce program"
    />
  );
}

export default SecureTrustCertificate;
