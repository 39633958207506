import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import CommentsPreset from 'components/Comments/CommentsPreset';
import { ROUTES } from 'constants/routes';
import { SubmitButton } from 'components';
import { FormControl, TextField } from '@material-ui/core';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper {
    background-color: #f5f5f5;
    border: 6px solid #95bcd7;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 20px;
  }
`;

const NewComment = ({ id, newComment, placeholder, onChange }) => (
  <StyledFormControl fullWidth>
    <TextField
      id={id}
      cols="40"
      multiline
      variant="outlined"
      label="Additional Comments..."
      rows="4"
      placeholder={placeholder}
      onChange={onChange}
      value={newComment}
    />
  </StyledFormControl>
);

export default function MultiCommentDialog({
  open,
  total,
  headerIDs,
  handleClose,
  refetch
}) {
  const [, setComment] = React.useState(''); // NOTE: componet type id
  const [newComment, setNewComment] = React.useState('');
  const [selectedPreset, setSelectedPreset] = React.useState(null);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const handleCloseSnackBar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (isSuccess) {
      handleClose();
    }
    setOpenSnackBar(false);
  };
  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ cursor: 'move' }}>
          Multi Comment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{total} Encounters Selected</DialogContentText>
          <CommentsPreset
            comment={selectedPreset}
            handleChange={value => setComment(value)}
            handlePresetChange={value => setSelectedPreset(value)}
            handleChangeComment={value => {
              if (value.encounterHeaderCommentTypeID) {
                setSelectedPreset(value);
              }
            }}
          />
          <NewComment
            id="multiComment-newComment"
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <SubmitButton
            id="multiComment-submit"
            onClick={async () => {
              const comment = newComment.trim()
                ? `${
                    selectedPreset?.encounterHeaderCommentType
                  }: ${newComment.trim()}`
                : selectedPreset?.encounterHeaderCommentType || '';
              const payload = {
                encounterHeaderIDList: headerIDs,
                encounterHeaderCommentTypeID:
                  selectedPreset && selectedPreset.encounterHeaderCommentTypeID,
                comment: comment,
                externalUserID: user && user.externalUserID,
                internalUserID: 0,
                isPrivate: false,
                internalUserName: '',
                externalUserName: user && user.externalUserName,
                createdDateTime: new Date(),
                isInternalUser: false
              };
              setLoading(true);
              try {
                await axios.post(
                  `${process.env.REACT_APP_URL}/?route=${ROUTES.PartnerUpSertEncounterComment}`,
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${sessionStorage.getItem(
                        'usertoken'
                      )}`
                    }
                  }
                );
                setIsSuccess(true);
                setMessage('Multi comments saved.');
                setOpenSnackBar(true);
                setComment('');
                setNewComment('');
                refetch();
              } catch (err) {
                const errMsg = err?.response?.data?.Error;
                setIsSuccess(false);
                setMessage(errMsg ?? 'There is an error.');
                setOpenSnackBar(true);
                setComment('');
                setNewComment('');
              } finally {
                setLoading(false);
              }
            }}
            disabled={
              !selectedPreset || !selectedPreset?.encounterHeaderCommentTypeID
            }
            loading={loading}
            variant="contained"
            color="primary"
          >
            Submit
          </SubmitButton>
          <Button
            id="multiComment-cancel"
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
      </StyledDialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        {openSnackbar && (
          <Alert
            onClose={handleCloseSnackBar}
            severity={isSuccess ? 'success' : 'error'}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
    </>
  );
}
