import React, { useState } from 'react';
import { usePartnerUpSertMfaPhoneNumber } from 'hooks/requests';
import { Form, Formik } from 'formik';
import { PhoneTextField } from 'components/PhoneTextField';
import { SubmitButton } from 'components/Buttons';

export interface UpdatePhoneFormProps {
  initialValues?: { phoneNumber: string };
  snackbarSuccess: (msg: string) => void;
}

export const UpdatePhoneForm: React.FC<UpdatePhoneFormProps> = ({
  initialValues = {
    phoneNumber: ''
  },
  snackbarSuccess
}) => {
  const { mutateAsync: upsertPhoneMutation } = usePartnerUpSertMfaPhoneNumber();
  const [errorMsg, setErrorMsg] = useState('');
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, actions) => {
        const cleanPhoneNumber = values.phoneNumber.replace(/-/g, '');
        setErrorMsg('');
        try {
          await upsertPhoneMutation({
            phoneNumber: cleanPhoneNumber
          });
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              ...JSON.parse(sessionStorage.getItem('user') || '{}'),
              phoneNumber: cleanPhoneNumber
            })
          );
          snackbarSuccess('Phone Updated!');
          actions.resetForm();
        } catch (err) {
          setErrorMsg(
            `Failed to update phone number. ${
              (err as { message: string }).message
            }`
          );
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValidating }) => {
        return (
          <Form
            style={{
              display: 'flex',
              flexFlow: 'column',
              marginTop: '15px'
            }}
          >
            <h2
              style={{
                textAlign: 'center',
                margin: '.83em 0 .2em 0'
              }}
            >
              Update Mobile Phone
            </h2>
            <span
              style={{
                margin: '4px 0',
                color: 'grey'
              }}
            >
              {`(XXX-XXX-${JSON.parse(
                sessionStorage.getItem('user') || '{}'
              ).phoneNumber.slice(6, 10)})`}
            </span>
            <PhoneTextField
              isRequired={true}
              autoFocus={true}
              label={'Phone'}
              name="phoneNumber"
              error={Boolean(errorMsg)}
              helperText={errorMsg}
              onFieldValueChange={() => {
                setErrorMsg('');
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                width: '100%',
                padding: '15px 0'
              }}
            >
              <SubmitButton
                loading={isSubmitting || isValidating}
                disabled={isSubmitting || isValidating}
                style={{
                  margin: '12px 0'
                }}
              >
                Submit
              </SubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
