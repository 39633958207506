import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MyEncountersSearchContext from './MyEncountersSearchContext';
import InputMask from 'react-input-mask';
import { Help } from '@material-ui/icons';
import { Tooltip } from 'components/Tooltip';
import { BrandColor } from 'constants/brandColor';
import { WarningButton } from 'components/Buttons';

const MyEncountersSearchForm = () => {
  const [disabled, setDisabled] = React.useState(false);
  const MES = useContext(MyEncountersSearchContext);

  useEffect(() => {
    setDisabled(checkIsDisabled(MES));
  }, [MES]);

  const handleEnterKeyPress = ev => {
    if (!disabled && ev.key === 'Enter') {
      MES.setIsLoading(true);
      MES.setSearch(true);
      ev.preventDefault();
    }
  };

  const handleFacilityChange = e => {
    MES.setFacility(e.target.value);
    if (!e.target.value.includes('Select all')) return;

    MES.setFacility(
      MES.facility.length === MES.facilityOptions.length
        ? []
        : MES.facilityOptions
    );
  };

  const clearValues = () => {
    MES.setValue1('');
    MES.setValue2('');
  };

  const handleSearchTypeChange = e => {
    if (e === '') {
      clearValues();
    }
    MES.setAdvancedFilter(e.target.value);
    if (e.target.value === 'Date Of Service Range') {
      MES.setValue1(moment().subtract(3, 'months').format('MM/DD/YYYY'));
      MES.setValue2(moment().format('MM/DD/YYYY'));
    } else {
      clearValues();
    }
  };

  const handleSearchClick = () => {
    MES.setIsLoading(true);
    MES.setSearch(true);
  };

  const handleClearClick = () => {
    MES.setFacility([]);
    MES.setStatus('On Hold');
    MES.setAdvancedFilter('-- Select --');
    clearValues();
    MES.setNotSearched(true);
  };

  /* See Task #18700 for why this is commented */
  // const handleActionOwnerChange = e => {
  //   MES.setActionOwner(e.target.value);
  // };

  const LabelWithTooltip = ({ id, label, toolTipText }) => (
    <StyledInputLabel id={id}>
      {label}
      <Tooltip
        arrow
        title={toolTipText}
        placement="top"
        style={{ paddingLeft: '4px', order: 999 }}
      >
        <Help fontSize="small" />
      </Tooltip>
    </StyledInputLabel>
  );

  return (
    <div onKeyPress={handleEnterKeyPress}>
      <div style={styles.container}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {/* FACILITIES DROPDOWN HERE ---------- */}
          <FacilitiesField required variant="outlined">
            <LabelWithTooltip
              id="facilities"
              label={`Facilities (${MES.facility.length}/${MES.facilityOptions.length})`}
              toolTipText="Facilities assigned to a user"
            />
            <Select
              id="myEncounters-facilities"
              style={{ backgroundColor: 'white' }}
              MenuProps={{ autoFocus: false }}
              data-cy="facilities"
              labelId="facilities"
              multiple
              value={MES.facility}
              onChange={handleFacilityChange}
              input={<OutlinedInput />}
              renderValue={selected =>
                selected.map(el => `${el.value} ${el.label}`).join(', ')
              }
            >
              <MenuItem value="Select all">
                <StyledCheckbox
                  checked={MES.facility.length === MES.facilityOptions.length}
                />
                <ListItemText primary={'Select All'} />
              </MenuItem>

              {MES.facilityOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option}
                  data-cy="facilities-options"
                >
                  <StyledCheckbox
                    checked={
                      MES.facility.map(el => el.value).indexOf(option.value) >
                      -1
                    }
                  />
                  <ListItemText primary={`${option.value} ${option.label}`} />
                </MenuItem>
              ))}
            </Select>
          </FacilitiesField>

          {/* STATUS DROPDOWN HERE -------------- */}
          <FormControl
            required
            variant="outlined"
            style={styles.statusFormControl}
          >
            <LabelWithTooltip
              id="status"
              label="Status"
              toolTipText="Account status"
            />

            <Select
              id="myEncounters-status"
              style={{ backgroundColor: 'white' }}
              data-cy="status"
              labelId="status"
              value={MES.status}
              onChange={e => MES.setStatus(e.target.value)}
              input={<OutlinedInput />}
            >
              {MES.statusList.map(value => (
                <MenuItem key={value} value={value} data-cy="status-options">
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* ACTION OWNER DROPDOWN HERE ------------ */}
          {/* See Task #18700 for why this is commented */}
          {/* <FormControl
            required
            variant="outlined"
            style={styles.searchFormControl}
          >
            <LabelWithTooltip
              id="action-owner"
              label="Action Owner"
              toolTipText="Owns next action to be taken on account"
            />

            <Select
              id="myEncounters-actionowner"
              style={{ backgroundColor: 'white' }}
              data-cy="action-owner"
              labelId="action-owner"
              value={MES.actionOwner}
              onChange={handleActionOwnerChange}
              input={<OutlinedInput />}
              defaultValue={accountOwnerOptions[1]}
            >
              {accountOwnerOptions.map(value => (
                <MenuItem
                  key={value}
                  value={value}
                  style={{ height: '36px' }}
                  data-cy="action-owner-options"
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* SEARCH TYPE DROPSOWN HERE ------------ */}
          <FormControl
            required
            variant="outlined"
            style={styles.searchFormControl}
          >
            <LabelWithTooltip
              id="advanced-filter"
              label="Search Type"
              toolTipText="Ways a user can search"
            />
            <Select
              id="myEncounters-searchType"
              style={{ backgroundColor: 'white' }}
              data-cy="advanced-filter"
              labelId="advanced-filter"
              value={MES.advancedFilter}
              onChange={handleSearchTypeChange}
              input={<OutlinedInput />}
            >
              {advancedFilterOptions.map(value => (
                <MenuItem
                  key={value}
                  value={value}
                  style={{ height: '36px' }}
                  data-cy="advanced-filter-options"
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {myEncounterValueEnabled(MES) && (
            <TextField
              id="myEncoutners-value"
              variant="outlined"
              style={styles.myEncounterValue}
              data-cy="value1"
              required
              label="Value"
              value={MES.value1}
              onChange={e => MES.setValue1(e.target.value)}
            />
          )}

          {myEncounterStartEnabled(MES) && (
            <>
              <InputMask
                id="myEncounters-startDate"
                alwaysShowMask
                mask="99/99/9999"
                value={MES.value1}
                onChange={e => MES.setValue1(e.target.value)}
              >
                <TextField
                  variant="outlined"
                  style={{ ...styles.myEncounterValue, width: '120px' }}
                  required
                  label="Start"
                  inputlabelprops={{
                    shrink: true
                  }}
                />
              </InputMask>
              <InputMask
                id="myEncounters-endDate"
                alwaysShowMask
                mask="99/99/9999"
                value={MES.value2}
                onChange={e => MES.setValue2(e.target.value)}
              >
                <TextField
                  variant="outlined"
                  style={{ ...styles.myEncounterValue, width: '120px' }}
                  required
                  label="End"
                  inputlabelprops={{
                    shrink: true
                  }}
                />
              </InputMask>
            </>
          )}
        </div>

        {/* BUTTONS START HERE -------------- */}
        <div style={{ display: 'flex' }}>
          <Button
            id="myEncounters-search"
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', width: '100px' }}
            disabled={disabled}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <WarningButton
            id="myEncounters-clear"
            variant="contained"
            style={{
              marginLeft: '10px',
              marginTop: '10px',
              width: '90px'
            }}
            disabled={MES.isLoading}
            onClick={handleClearClick}
          >
            Clear
          </WarningButton>
        </div>
      </div>
    </div>
  );
};

// UTILS FUNCTIONS -------------------

const checkIsDisabled = MESContext => {
  return (
    MESContext.isLoading ||
    MESHasFacility(MESContext.facility) ||
    MESFilterIsSelect(MESContext.advancedFilter) ||
    MESContext.value1 === '' ||
    MesDosRangeInvalid(MESContext)
  );
};

const MESHasFacility = MESFacility => {
  return MESFacility && MESFacility.length === 0;
};

const MESFilterIsSelect = MESFilter =>
  MESFilter.advancedFilter === '-- Select --';

const MesDosRangeInvalid = MESContext => {
  const { advancedFilter, value1, value2 } = MESContext;
  return (
    advancedFilter === 'Date Of Service Range' &&
    (moment(value1).format('MM-DD-YYYY') === 'Invalid date' ||
      moment(value2).format('MM-DD-YYYY') === 'Invalid date' ||
      moment(value1).isSameOrAfter(moment(value2)))
  );
};

const myEncounterValueEnabled = MESContext => {
  return (
    !!MESContext.advancedFilter &&
    MESContext.advancedFilter !== '-- Select --' &&
    MESContext.advancedFilter !== 'Date Of Service Range' &&
    MESContext.advancedFilter !== 'Date Of Birth'
  );
};

const myEncounterStartEnabled = MESContext => {
  return (
    !!MESContext.advancedFilter &&
    MESContext.advancedFilter === 'Date Of Service Range'
  );
};

// STYLED COMPONENTS -----------------
const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${BrandColor.ACCENT} !important;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  pointer-events: auto;
  &.MuiInputLabel-outlined {
    transform: translate(14px, 15px) scale(1);
  }
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: linear-gradient(#f5f5f5 50%, white 50%);
    padding: 0 5px;
    display: flex;
    align-items: center;
    transform: translate(14px, -8px) scale(0.75);
  }
`;
const FacilitiesField = styled(FormControl)`
  width: 150px;
  @media only screen and (min-width: 1200px) {
    width: 285px;
  }
`;

// CONSTANTS --------------
const advancedFilterOptions = [
  '-- Select --',
  'Account Number',
  /*'Date Of Birth',*/ 'Date Of Service Range',
  'Patient Last Name' /*'Responsible Provider'*/
];

// const accountOwnerOptions = ['All', 'Client', 'Ventra'];

// STYLES -------------
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '5px'
  },
  statusFormControl: { width: '145px' },
  searchFormControl: { width: '220px' },
  myEncounterValue: {
    backgroundColor: 'white'
  }
};

export default MyEncountersSearchForm;
