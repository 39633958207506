import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { BrandColor } from 'constants/brandColor';

const OrangeCheckbox = withStyles({
  root: {
    color: BrandColor.ACCENT,
    '&$checked': {
      color: BrandColor.ACCENT
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function MultiSelect() {
  const [personName, setPersonName] = React.useState([]);
  const names = ['adsf', 'zxcv', 'qwer'];
  return (
    <FormControl>
      <InputLabel id="demo-mutiple-checkbox-label">Tag</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={personName}
        onChange={e => setPersonName(e.target.value)}
        input={<Input />}
        renderValue={selected => selected.join(', ')}
      >
        {names.map(name => (
          <MenuItem key={name} value={name}>
            <OrangeCheckbox checked={personName.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
