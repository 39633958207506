import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { BaseButton, SubmitButton, WarningButton } from 'components/Buttons';
import { useExceptionsDialogContext } from '../lib/exceptionsDialogContext';

export interface ExceptionsDialogActionsProps {
  allDisabled?: boolean;
  loading?: boolean;
  save?: () => void;
  authorize?: () => void;
  authorizedDisabled?: boolean;
  saveDisabled?: boolean;
}

export const ExceptionsDialogActions: React.FC<ExceptionsDialogActionsProps> =
  ({
    allDisabled,
    authorizedDisabled,
    saveDisabled,
    loading,
    save = () => {},
    authorize = () => {}
  }) => {
    const { handleClose } = useExceptionsDialogContext();
    return (
      <DialogActions>
        <SubmitButton
          loading={loading}
          disabled={allDisabled || saveDisabled}
          onClick={() => save()}
        >
          Save
        </SubmitButton>
        <WarningButton
          onClick={() => authorize()}
          loading={loading}
          disabled={allDisabled || authorizedDisabled}
        >
          Authorize for coding
        </WarningButton>
        <BaseButton
          type="button"
          variant="contained"
          onClick={() => handleClose()}
        >
          Close
        </BaseButton>
      </DialogActions>
    );
  };
