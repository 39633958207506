import jwtDecode from 'jwt-decode';
import { Jwt } from 'types/auth';
import { LOGINTYPES, SSO_OVERRIDE_COMPANIES } from 'constants/login';
import { NavigateFunction } from 'react-router';

const setToken = (token: string) => {
  sessionStorage.setItem('usertoken', token);
};
const getUserInfo = () => {
  const user = sessionStorage.getItem('user');
  return user ? JSON.parse(user) : {};
};
const setUserInfo = (data: string) => {
  sessionStorage.setItem('user', data);
};
const getToken = () => sessionStorage.getItem('usertoken');
const getDecodeToken = () => {
  const token = getToken();
  // ideally we would throw, but for now we will return {} to avoid breaking anything
  if (!token) return {} as Jwt;
  return jwtDecode<Jwt>(token);
};
const logOut = () => {
  localStorage.removeItem('usertoken');
};
const isTokenExpired = (token: string) => {
  try {
    const decoded = jwtDecode<Jwt>(token);
    return decoded.exp < Date.now() / 1000;
  } catch (err) {
    return false;
  }
};
const isLoggedIn = () => {
  const token = getToken();
  return !!token && !isTokenExpired(token);
};
// Used to hide the Submit button for SSO users except
// for companies in the SSO_OVERRIDE_COMPANIES list
const isLoggedInSSO = () => {
  let user = {
    externalUserTypeID: 0,
    companyID: 0
  };
  user = getUserInfo();
  return (
    Number(user?.externalUserTypeID) === LOGINTYPES.SSO &&
    SSO_OVERRIDE_COMPANIES.indexOf(user?.companyID) < 0
  );
};

const logoutNavigate = (navigate: NavigateFunction) => {
  const isProd = process.env.REACT_APP_NODE_ENV === 'PROD';
  const userTypeId = Number(getUserInfo()?.externalUserTypeID);
  const environment = isProd
    ? ''
    : `-${process.env.REACT_APP_NODE_ENV?.toLowerCase()}`;
  sessionStorage.clear();

  if (userTypeId !== LOGINTYPES.SSO) {
    return navigate('/');
  }

  window.location.replace(
    `https://horizon${environment}.corp.ventrahealth.com/partner-portal-auto-login?hide=true`
  );
};

export const useAuthentication = () => ({
  setToken,
  getToken,
  logOut,
  isLoggedIn,
  isLoggedInSSO,
  getDecodeToken,
  setUserInfo,
  getUserInfo,
  logoutNavigate
});
