import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { ExceptionsDialogActions } from '../../Actions/ExceptionsDialogActions';
import { BaseAlert } from 'components/BaseAlert/BaseAlert';

export const ExceptionsDialogError: React.FC = ({ children }) => (
  <>
    <DialogContent>
      <BaseAlert>{children}</BaseAlert>
    </DialogContent>
    <ExceptionsDialogActions allDisabled />
  </>
);
