export enum ROUTES {
  PartnerGetExternalUser = 'PartnerGetExternalUser',
  PartnerGetMyEncounterMasterView = 'PartnerGetMyEncounterMasterView',
  PartnerGetMyEncounterComments = 'PartnerGetMyEncounterComments',
  PartnerUpSertEncounterComment = 'PartnerUpSertEncounterComment',
  PartnerGetStatusList = 'PartnerGetStatusList',
  PartnerGetMyEncounterCommentTypes = 'PartnerGetMyEncounterCommentTypes',
  PartnerGetEncounterHeaderVoidReasonList = 'PartnerGetEncounterHeaderVoidReasonList',
  PartnerUpSertEncounterHeaderStatusVoidUpdate = 'PartnerUpSertEncounterHeaderStatusVoidUpdate',
  UpSertEncounterHeaderStatusVoidUpdateMultiple = 'UpSertEncounterHeaderStatusVoidUpdateMultiple',
  PartnerGetMyEncounterCommentPresetComments = 'PartnerGetMyEncounterCommentPresetComments',
  GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID = 'GetExceptionEncounterHeadersActiveAndInactiveByEncounterHeaderID',
  GetPartnerPortalProvidersByFacilityId = 'GetPartnerPortalProvidersByFacilityId',
  UpdateExceptionEncounterHeaders = 'UpdateExceptionEncounterHeaders',
  ClearAllExceptionEncounterHeaderExceptions = 'ClearAllExceptionEncounterHeaderExceptions',
  GetDocumentHeaders = 'GetDocumentHeaders',
  GetDocumentDetails = 'GetDocumentDetails',
  GetDocumentEdmById = 'GetDocumentEdmById',
  PartnerGetMaintenanceMode = 'PartnerGetMaintenanceMode',
  PartnerGetMfaAuthentication = 'PartnerGetMfaAuthentication',
  PartnerGetPatientInsurance = 'PartnerGetPatientInsurance',
  PartnerGetRefreshToken = 'PartnerGetRefreshToken',
  PartnerSendMfaTextToPhoneNumber = 'PartnerSendMfaTextToPhoneNumber',
  PartnerUpdateRequestInsuranceInsert = 'PartnerUpdateRequestInsuranceInsert',
  PartnerUpSertMfaPhoneNumber = 'PartnerUpSertMfaPhoneNumber',
  SendMfaTextToEmail = 'SendMfaTextToEmail',
  ClearAllExceptionEncounterHeaderExceptionsMulti = 'ClearAllExceptionEncounterHeaderExceptionsMulti',
  PartnerGetProviderList = 'PartnerGetProviderList'
}
