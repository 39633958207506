import styled from 'styled-components';

export const LoginContainer = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  @media screen and (min-width: 960px) {
    background: url(${props => props.src}) no-repeat center center fixed;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-size: cover;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-background-size: cover;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -o-background-size: cover;
    background-size: cover;
  }
  .working,
  .working::before,
  .working::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .working {
    width: 500px;
    height: 500px;
    margin: auto 0 auto 350px;
    border-radius: 10px;
  }

  .working::before,
  .working::after {
    color: rgb(80, 104, 120);
    opacity: 75%;
    content: '';
    z-index: 2;
    margin: -1%;
    border-radius: 1000px;
    box-shadow: inset 0 0 0 4px;
    animation: clipMe 20s linear infinite;
  }

  .working::before {
    animation-delay: -10s;
  }

  @keyframes clipMe {
    0%,
    100% {
      clip: rect(0px, 510px, 6px, 0px);
    }

    25% {
      clip: rect(0px, 6px, 510px, 0px);
    }

    50% {
      clip: rect(506px, 510px, 510px, 0px);
    }

    75% {
      clip: rect(0px, 510px, 510px, 506px);
    }
  }

  .ReverseWorking,
  .ReverseWorking::before,
  .ReverseWorking::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ReverseWorking {
    width: 600px;
    height: 600px;
    margin: auto 0 auto 300px;
  }

  .ReverseWorking::before,
  .ReverseWorking::after {
    color: #3f51b5;
    opacity: 75%;
    content: '';
    z-index: 2;
    margin: -1%;
    border-radius: 1000px;
    box-shadow: inset 0 0 0 4px;
    animation: clipMe2 50s linear infinite;
  }

  .ReverseWorking::before {
    animation-delay: -25s;
  }

  @keyframes clipMe2 {
    0%,
    100% {
      clip: rect(0px, 4px, 720px, 0px);
    }

    25% {
      clip: rect(0px, 720px, 4px, 0px);
    }

    50% {
      clip: rect(0px, 720px, 720px, 506px);
    }

    75% {
      clip: rect(506px, 720px, 720px, 0px);
    }
  }
`;

export const LoginPanel = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0 auto 375px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 450px;
  height: 450px;
  max-width: 500px;
  max-height: 500px;
  border-radius: 1000px;
  background: white;
  opacity: 95%;
`;
