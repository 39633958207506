import { useMutation } from 'react-query';
import { ErrorResponse } from 'types/network';
import { useAuthenticatedAxios } from 'hooks/useAuthenticatedAxios';
import { ROUTES } from 'constants/routes';

export const usePartnerGetMfaAuthentication = () => {
  const Axios = useAuthenticatedAxios();
  return useMutation<string, ErrorResponse, { mfaToken: string }>(
    async ({ mfaToken }) => {
      const { data } = await Axios({
        method: 'post',
        url: `/${mfaToken}?route=${ROUTES.PartnerGetMfaAuthentication}`
      });
      return data;
    }
  );
};
