import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { usePartnerGetRefreshToken } from 'hooks/requests';
import { useAuthentication } from 'hooks/useAuthentication';
import moment from 'moment';
import { Jwt } from 'types/auth';

export interface CountdownProps {
  initialSeconds: number;
  onCountdownEnd: () => void;
}

export const Countdown: React.FC<CountdownProps> = ({
  initialSeconds = 0,
  onCountdownEnd
}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const isCountdownFinished = seconds <= 0;

  useEffect(() => {
    if (isCountdownFinished) return;
    const countdown = setInterval(() => {
      setSeconds(prevState => prevState - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [isCountdownFinished]);

  useEffect(() => {
    if (isCountdownFinished) {
      onCountdownEnd();
    }
  }, [isCountdownFinished, onCountdownEnd]);
  return <span>{String(seconds)}</span>;
};

function SessionTimeout() {
  const { data: refreshToken = '', refetch } = usePartnerGetRefreshToken();
  const { getToken, getDecodeToken, logoutNavigate } = useAuthentication();
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (refreshToken) {
      sessionStorage.setItem('usertoken', refreshToken);
    }
  }, [refreshToken]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const warning = setTimeout(() => {
      setOpen(true);
    }, Math.floor(moment((getDecodeToken() as unknown as Jwt).warn).diff(moment())));
    return () => {
      clearTimeout(warning);
    };
  }, [refreshToken, getDecodeToken]);

  const logout = useCallback(() => {
    setOpen(false);
    logoutNavigate(navigate);
  }, [logoutNavigate, navigate]);

  useEffect(() => {
    if (!token) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const resetTimeout = () => {
    setOpen(false);
    refetch();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        You will be logged out in{' '}
        {open ? (
          <Countdown
            initialSeconds={Number(
              Math.floor(
                moment((getDecodeToken() as unknown as Jwt).exp * 1000).diff(
                  moment()
                ) / 1000
              )
            )}
            onCountdownEnd={logout}
          />
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session is set to expire due to inactivity.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={logout} color="primary">
          Logout
        </Button>
        <Button onClick={resetTimeout} color="primary" autoFocus>
          Stay Logged In
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionTimeout;
