import GlobalFilter from 'components/GlobalFilter';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const TableActions = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledToolbar = styled(Toolbar)`
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TableToolbar = ({
  numSelected,
  setGlobalFilter,
  globalFilter,
  total,
  SelectedActions,
  notSearched,
  gotoPage
}) => (
  <StyledToolbar>
    {notSearched ? (
      <Typography>
        Enter your search parameters and click Search to return data by default.
      </Typography>
    ) : numSelected > 0 ? (
      <Typography>Selected {numSelected}</Typography>
    ) : (
      <Typography>{total} records found from your search criteria.</Typography>
    )}
    <TableActions>
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        gotoPage={gotoPage}
      />

      {SelectedActions}
    </TableActions>
  </StyledToolbar>
);

export default TableToolbar;
