import { createContext } from 'react';

const MyEncoutnersSearchContext = createContext({
  facilityOptions: [],
  setFacilityOptions: () => {},
  facility: [],
  setFacility: () => {},
  status: '',
  setStatus: () => {},
  statusList: [],
  advancedFilter: '',
  setAdvancedFilter: () => {},
  value1: '',
  setValue1: () => {},
  value2: undefined,
  setValue2: () => {},
  search: false,
  setSearch: () => {},
  isLoading: false,
  setIsLoading: () => {},
  refetch: false,
  setRefetch: () => {},
  setNotSearched: () => {},
  setEncounters: encounters => encounters,
  actionOwner: '',
  setActionOwner: () => {}
});

export default MyEncoutnersSearchContext;
