import React from 'react';
import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { useField } from 'formik';
import { ArrowDropDown } from '@material-ui/icons';

const options = [
  {
    id: 'AL',
    value: 'Alabama'
  },
  {
    id: 'AK',
    value: 'Alaska'
  },
  {
    id: 'AS',
    value: 'American Samoa'
  },
  {
    id: 'AZ',
    value: 'Arizona'
  },
  {
    id: 'AR',
    value: 'Arkansas'
  },
  {
    id: 'CA',
    value: 'California'
  },
  {
    id: 'CO',
    value: 'Colorado'
  },
  {
    id: 'CT',
    value: 'Connecticut'
  },
  {
    id: 'DE',
    value: 'Delaware'
  },
  {
    id: 'DC',
    value: 'District Of Columbia'
  },
  {
    id: 'FM',
    value: 'Federated States Of Micronesia'
  },
  {
    id: 'FL',
    value: 'Florida'
  },
  {
    id: 'GA',
    value: 'Georgia'
  },
  {
    id: 'GU',
    value: 'Guam'
  },
  {
    id: 'HI',
    value: 'Hawaii'
  },
  {
    id: 'ID',
    value: 'Idaho'
  },
  {
    id: 'IL',
    value: 'Illinois'
  },
  {
    id: 'IN',
    value: 'Indiana'
  },
  {
    id: 'IA',
    value: 'Iowa'
  },
  {
    id: 'KS',
    value: 'Kansas'
  },
  {
    id: 'KY',
    value: 'Kentucky'
  },
  {
    id: 'LA',
    value: 'Louisiana'
  },
  {
    id: 'ME',
    value: 'Maine'
  },
  {
    id: 'MH',
    value: 'Marshall Islands'
  },
  {
    id: 'MD',
    value: 'Maryland'
  },
  {
    id: 'MA',
    value: 'Massachusetts'
  },
  {
    id: 'MI',
    value: 'Michigan'
  },
  {
    id: 'MN',
    value: 'Minnesota'
  },
  {
    id: 'MS',
    value: 'Mississippi'
  },
  {
    id: 'MO',
    value: 'Missouri'
  },
  {
    id: 'MT',
    value: 'Montana'
  },
  {
    id: 'NE',
    value: 'Nebraska'
  },
  {
    id: 'NV',
    value: 'Nevada'
  },
  {
    id: 'NH',
    value: 'New Hampshire'
  },
  {
    id: 'NJ',
    value: 'New Jersey'
  },
  {
    id: 'NM',
    value: 'New Mexico'
  },
  {
    id: 'NY',
    value: 'New York'
  },
  {
    id: 'NC',
    value: 'North Carolina'
  },
  {
    id: 'ND',
    value: 'North Dakota'
  },
  {
    id: 'MP',
    value: 'Northern Mariana Islands'
  },
  {
    id: 'OH',
    value: 'Ohio'
  },
  {
    id: 'OK',
    value: 'Oklahoma'
  },
  {
    id: 'OR',
    value: 'Oregon'
  },
  {
    id: 'PW',
    value: 'Palau'
  },
  {
    id: 'PA',
    value: 'Pennsylvania'
  },
  {
    id: 'PR',
    value: 'Puerto Rico'
  },
  {
    id: 'RI',
    value: 'Rhode Island'
  },
  {
    id: 'SC',
    value: 'South Carolina'
  },
  {
    id: 'SD',
    value: 'South Dakota'
  },
  {
    id: 'TN',
    value: 'Tennessee'
  },
  {
    id: 'TX',
    value: 'Texas'
  },
  {
    id: 'UT',
    value: 'Utah'
  },
  {
    id: 'VT',
    value: 'Vermont'
  },
  {
    id: 'VI',
    value: 'Virgin Islands'
  },
  {
    id: 'VA',
    value: 'Virginia'
  },
  {
    id: 'WA',
    value: 'Washington'
  },
  {
    id: 'WV',
    value: 'West Virginia'
  },
  {
    id: 'WI',
    value: 'Wisconsin'
  },
  {
    id: 'WY',
    value: 'Wyoming'
  }
];

const DropdownIcon = props => {
  return (
    <ArrowDropDown
      {...props}
      style={{
        right: -4,
        paddingRight: 10
      }}
    />
  );
};

export const StateSelect = ({ style, name, label, ...props }) => {
  const [field] = useField({
    name
  });

  return (
    <FormControl
      variant="outlined"
      style={{
        ...style,
        backgroundColor: 'white'
      }}
    >
      <InputLabel
        htmlFor="form-select"
        style={{
          background: 'white',
          padding: '0',
          fontSize: '15px'
        }}
      >
        {label}
      </InputLabel>
      <Select
        id="patient-state"
        data-cy="patient-state"
        labelId="patient-state"
        style={{
          width: '214px',
          height: '50px'
        }}
        inputProps={{
          style: {
            padding: '22px 16px 8px'
          }
        }}
        IconComponent={props => <DropdownIcon {...props} />}
        input={<OutlinedInput />}
        {...field}
        {...props}
      >
        <MenuItem
          value={''}
          style={{ height: '36px' }}
          data-cy={'option-id-0'}
        ></MenuItem>
        {options.map(option => {
          return (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{ height: '36px' }}
              data-cy={`option-id-${option.id}`}
            >
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
