import React from 'react';
import { useField } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

// TODO: styles
function Select({ required, label, options, ...props }) {
  const [field] = useField(props);
  return (
    <FormControl required={required}>
      <InputLabel id={props.id}>{label}</InputLabel>
      <MuiSelect {...field} {...props} labelId={props.id} input={<Input />}>
        {options.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
