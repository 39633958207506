import { useContext } from 'react';

import MyEncountersSearchContext from 'views/MyEncounters/MyEncountersSearchContext';

export interface UseEncounterSearchContextUtilsResult {
  removeEncounterHeaderFromSearch: (encounterHeaderId: number) => void;
  removeEncounterHeadersFromSearch: (encounterHeaderIds: number[]) => void;
}

export const useEncounterSearchContextUtils =
  (): UseEncounterSearchContextUtilsResult => {
    const { setEncounters } = useContext(MyEncountersSearchContext);

    return {
      removeEncounterHeaderFromSearch: (encounterHeaderId: number) =>
        setEncounters((x: { encounterHeaderID: number }[]) =>
          x.filter(
            encoutner => encoutner.encounterHeaderID !== encounterHeaderId
          )
        ),
      removeEncounterHeadersFromSearch: (encounterHeaderIds: number[]) =>
        setEncounters((x: { encounterHeaderID: number }[]) =>
          x.filter(
            encoutner =>
              !encounterHeaderIds.includes(encoutner.encounterHeaderID)
          )
        )
    };
  };
