import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TableToolbar from './TableToolbar';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { compareAsc, format } from 'date-fns';
import MultiComment from '../MultiComment';
import VoidDialog from '../VoidDialog';
import { ROUTES } from 'constants/routes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MyEncoutnersSearchContext from '../MyEncountersSearchContext';
import { exportToExcelSheet } from 'lib/excelExport';
import { BatchAuthorizeDialog } from 'components/BatchAuthorizeDialog/BatchAuthorizeDialog';
import { BrandColor } from 'constants/brandColor';
import { theme } from 'lib/theme';
import { WarningButton } from 'components/Buttons';

function Alert(props) {
  return (
    <MuiAlert
      style={{ zIndex: '1500' }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

// HACK: USE `withStyles` HOC LATER
const StyledCheckbox = styled(Checkbox)`
  z-index: 2;
  &.Mui-checked {
    color: ${BrandColor.ACCENT} !important;
  }
`;

const MultiSelectActions = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledTable = styled(MaUTable)`
  border-collapse: separate;

  th,
  td {
    border: 1px solid #0003;
  }

  .MuiTableCell-root {
    padding: 5px;
  }
  .MuiTableCell-head {
    line-height: 1rem;
  }
  th {
    background-color: ${theme.palette.primary.dark};
    color: white;
    font-weight: bold;
    font-size: 11px;
  }
  td {
    font-size: 11px;
  }
  tr:nth-child(odd) {
    td {
      background-color: #ddd;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #f5f5f5;
    }
  }
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
  border-radius: 5px;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    // TODO: Extract checkbox with custom checkmark color
    return (
      <>
        <div style={{ height: '42px' }}>
          <StyledCheckbox ref={resolvedRef} {...rest} />
          <div
            style={{
              display: rest.checked ? 'block' : 'none',
              backgroundColor: 'black',
              position: 'relative',
              top: '-28px',
              left: '14px',
              zIndex: '1px',
              height: '15px',
              width: '15px'
            }}
          />
        </div>
      </>
    );
  }
);

const SelectedActions = ({
  ids,
  disableActions,
  disableExport,
  refetch,
  selectedFlatRows,
  data,
  user,
  columns
}) => {
  const [openComment, setOpenComment] = React.useState(false);
  const [multiCommentIDs, setMultiCommentIDs] = React.useState([]);
  const [openVoidDialog, setOpenVoidDialog] = useState(false);
  const [multiVoidIDs, setMultiVoidIDs] = useState([]);
  const [isMulti, setIsMulti] = useState(null);
  const [optionalComment, setOptionalComment] = useState('');
  const [selectedVoidReason, setSelectedVoidReason] = useState({});
  const [multiVoid, setMultiVoid] = useState(false);
  const [openMultiSnackbar, setOpenMultiSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [batchAuthOpen, setBatchAuthOpen] = useState(false);
  const { status } = useContext(MyEncoutnersSearchContext);

  const handleMulti = (ids, type) => {
    if (!type) return;

    const headerIDs = ids.map(i => ({
      encounterHeaderId: data[i].encounterHeaderID,
      applicationID: data[i].applicationID
    }));
    if (type === 'comment') {
      setMultiCommentIDs(headerIDs);
      setOpenComment(true);
    }
    if (type === 'void') {
      setMultiVoidIDs(headerIDs);
      setOpenVoidDialog(true);
    }
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMultiSnackbar(false);
  };

  useEffect(() => {
    if (multiVoid) {
      const multiVoidRecord = async () => {
        const payload = {
          encounterHeaderIdentifier: multiVoidIDs,
          encounterHeaderVoidReasonID: selectedVoidReason
            ? selectedVoidReason.encounterHeaderVoidReasonID
            : '',
          comment: optionalComment
        };
        try {
          await axios.post(
            `${process.env.REACT_APP_URL}/?route=${ROUTES.UpSertEncounterHeaderStatusVoidUpdateMultiple}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('usertoken')}`
              }
            }
          );
          setIsSuccess(true);
          setMessage('Record voided.');
          setOptionalComment('');
          setSelectedVoidReason(null);
          setOpenVoidDialog(null);
          refetch(true);
        } catch (error) {
          const errMsg = error?.response?.data?.Error;
          setIsSuccess(false);
          setMessage(errMsg ?? 'There is an error.');
        } finally {
          setMultiVoid(false);
          setOpenMultiSnackbar(true);
        }
      };

      multiVoidRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiVoid]);

  return (
    <MultiSelectActions>
      <Button
        disabled={disableActions || user.readOnly}
        variant="contained"
        color="primary"
        onClick={() => handleMulti(ids, 'comment')}
      >
        Comment
      </Button>
      <MultiComment
        open={openComment}
        total={ids.length}
        headerIDs={multiCommentIDs}
        handleClose={() => setOpenComment(false)}
        refetch={refetch}
      />
      <WarningButton
        disabled={disableActions || user.readOnly}
        variant="contained"
        onClick={() => {
          setIsMulti(true);
          handleMulti(ids, 'void');
        }}
      >
        Void
      </WarningButton>
      {isMulti && (
        <VoidDialog
          show={openVoidDialog}
          isMulti={isMulti}
          headerIDs={multiVoidIDs}
          applicationID={openVoidDialog?.applicationID}
          isIBIS={!openVoidDialog || openVoidDialog.applicationID === 4}
          selectedVoidReason={selectedVoidReason}
          handleVoidReasonChange={e => {
            setSelectedVoidReason(e.target.value);
          }}
          optionalComment={optionalComment}
          handleOptionalCommentChange={e => {
            setOptionalComment(e.target.value);
          }}
          onSave={() => setMultiVoid(true)}
          onClose={() => {
            setIsMulti(false);
            setOpenVoidDialog(null);
            setOptionalComment('');
            setSelectedVoidReason(null);
          }}
        />
      )}
      {status === 'Pre-Coding Authorization' && (
        <>
          <Button
            disabled={disableActions || user.readOnly}
            variant="contained"
            color="secondary"
            onClick={() => setBatchAuthOpen(true)}
          >
            Authorize for coding
          </Button>
          <BatchAuthorizeDialog
            open={batchAuthOpen}
            handleClose={() => setBatchAuthOpen(false)}
            encounterHeaderIds={selectedFlatRows.map(
              x => x.original.encounterHeaderID
            )}
          />
        </>
      )}
      <Snackbar
        open={openMultiSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        {openMultiSnackbar && (
          <Alert
            onClose={handleCloseSnackBar}
            severity={isSuccess ? 'success' : 'error'}
          >
            {message}
          </Alert>
        )}
      </Snackbar>

      <Button
        disabled={disableExport}
        variant="outlined"
        color="primary"
        onClick={() =>
          exportToExcelSheet({
            fileName: `MyEncounters-${format(new Date(), 'dd.MM.yyyy')}.xlsx`,
            sheetName: 'My Encounters',
            columns: columns.slice(1), // Remove actions column
            data: data.map(el => ({
              ...el,
              dateOfService: el.dateOfService ? new Date(el.dateOfService) : '',
              dateHeld: el.dateHeld ? new Date(el.dateHeld) : '',
              voidDate: el.voidDate ? new Date(el.voidDate) : ''
            }))
          })
        }
      >
        Export
      </Button>
    </MultiSelectActions>
  );
};

const EnhancedTable = ({
  columns,
  data = [],
  openRecord,
  notSearched,
  refetch
}) => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const memoizedColumns = useMemo(() => {
    return columns.map(column => {
      return {
        ...column,
        sortType: column.sortType
          ? dateKeywordsRegex.test(column.accessor || column.id)
            ? sortDate
            : 'alphanumeric'
          : 'alphanumeric'
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, data]);

  const sortTypes = useMemo(() => createSortTypes(), []);
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter }
  } = useTable(
    {
      columns: memoizedColumns,
      data: data || [],
      initialState: { pageSize: 25 },
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      autoResetPage: false,
      sortTypes: sortTypes
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              {!user.readOnly ? (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ) : null}
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {!user.readOnly ? (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ) : null}
            </div>
          )
        },
        ...columns
      ]);
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  return (
    <TableContainer>
      <TableToolbar
        notSearched={notSearched}
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        gotoPage={gotoPage}
        globalFilter={globalFilter}
        total={data.length}
        SelectedActions={
          <SelectedActions
            ids={Object.keys(selectedRowIds)}
            selectedFlatRows={selectedFlatRows}
            refetch={refetch}
            user={user}
            columns={columns}
            data={data}
            disableActions={Object.keys(selectedRowIds).length < 1}
            disableExport={data.length < 1}
          />
        }
      />
      <TableWrapper>
        <StyledTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...(column.id === 'selection' ||
                    column.id === 'encounterHeaderID'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    title=""
                  >
                    {column.id !== 'selection' &&
                    column.id !== 'encounterHeaderID' ? (
                      // TODO: Extract to a new file
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <span>{column.render('Header')}</span>
                        <span
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '30px'
                          }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              fontSize: '30px',
                              position: 'relative',
                              top: '-5px',
                              color:
                                column.isSorted && column.isSortedDesc
                                  ? BrandColor.ACCENT
                                  : 'white'
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              fontSize: '30px',
                              position: 'relative',
                              top: '-25px',
                              color:
                                column.isSorted && !column.isSortedDesc
                                  ? BrandColor.ACCENT
                                  : 'white'
                            }}
                          />
                        </span>
                      </div>
                    ) : (
                      column.render('Header')
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {(data.length === 0 || page.length === 0) && (
              <tr>
                <td>
                  {data.length === 0
                    ? 'Please Search for Records'
                    : 'Please refine your filter'}
                </td>
              </tr>
            )}
            {page.map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        style={{
                          ...(row.original.encounterHeaderID ===
                          (openRecord && openRecord.encounterHeaderId)
                            ? { backgroundColor: '#95bcd7' }
                            : {})
                        }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, { label: 'All', value: data.length }]}
            colSpan={16}
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

const createSortTypes = () => ({
  dateString: (a, b, id) =>
    compareAsc(new Date(a.original[id]), new Date(b.original[id]))
});

const dateKeywordsRegex = /(date|dos|dob|ados|expirationDate)/gi;

function sortDate(rowA, rowB, id) {
  if (!rowA?.original) return 0;
  return compareAsc(new Date(rowA.original[id]), new Date(rowB.original[id]));
}

export default EnhancedTable;
