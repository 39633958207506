import React from 'react';
import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { useField } from 'formik';
import { ArrowDropDown } from '@material-ui/icons';

// Self = 1,
// Spouse = 2,
// Child = 3,
// Other = 4
const options = [
  {
    id: 1,
    value: 'Self'
  },
  {
    id: 2,
    value: 'Spouse'
  },
  {
    id: 3,
    value: 'Child'
  },
  {
    id: 4,
    value: 'Other'
  }
];
const DropdownIcon = props => {
  return (
    <ArrowDropDown
      {...props}
      style={{
        right: -4
      }}
    />
  );
};
export const InsuredRelationshipToPatientField = ({
  style,
  name,
  ...props
}) => {
  const [field] = useField({
    name
  });

  return (
    <FormControl
      variant="outlined"
      style={{
        ...style,
        maxWidth: '400px',
        backgroundColor: 'white'
      }}
    >
      <InputLabel
        htmlFor="form-select"
        style={{
          background: 'white',
          padding: '0',
          fontSize: '15px'
        }}
      >
        Insured Relation to Patient
      </InputLabel>
      <Select
        id="insured-relationship-to-insurance"
        data-cy="insured-relationship-to-insurance"
        labelId="insured-relationship-to-insurance"
        style={{
          width: '206px',
          height: '50px'
        }}
        inputProps={{
          style: {
            padding: '22px 16px 8px'
          }
        }}
        IconComponent={props => <DropdownIcon {...props} />}
        input={<OutlinedInput />}
        {...field}
        {...props}
      >
        <MenuItem
          value={''}
          style={{ height: '36px' }}
          data-cy={'option-id-0'}
        ></MenuItem>
        {options.map(option => {
          return (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{ height: '36px' }}
              data-cy={`option-id-${option.id}`}
            >
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
